<template>
    <button
        class="simple-button"
        type="button"
        :class="[cssClasses, classNames]"
        :disabled="loading || disabled"
        @click="handleClickInner"
    >
        <template v-if="icon && !iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="mr-2" />
        </template>
        <template v-if="loading">
            <loading-beacon />
        </template>
        <template v-else>
            <slot />
        </template>
        <template v-if="icon && iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="ml-2" />
        </template>
    </button>
</template>

<script>
/**
 * @group ATOMS
 * Component to render a nice button
 */
export default {
    $inheritAttrs: true,
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        gray: {
            type: Boolean,
            default: false,
        },
        // Adds an icon to the button from FAS. Icon must be loaded manually in the fontAwesome.js
        icon: {
            type: String,
            default: null,
        },
        // Per default, the icon is on the left. Set this to true if you want the icon on the right.
        iconRight: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
        classNames: {
            type: String,
            default: '',
        },
        handleClick: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        cssClasses() {
            let cssArray = [this.color]
            if (this.loading) {
                cssArray.push('loading')
            }
            if (this.disabled) {
                cssArray.push('disabled')
            }
            if (this.gray) {
                cssArray.push('gray')
            }
            return cssArray
        },
    },
    methods: {
        handleClickInner() {
            this.handleClick()
        },
    },
}
</script>

<style lang="scss">
.simple-button {
    @apply bg-primary-500 shadow text-white h-12 leading-none font-bold text-lg py-4 px-10 rounded-lg transition duration-300 ease-in-out w-auto min-w-40 text-center;

    &:hover {
        @apply bg-primary-700 shadow-lg  outline-none;
    }

    &:focus {
        @apply shadow-md bg-primary-600 outline-none;
    }

    &:active {
        @apply bg-primary-600 scale-95  outline-none;
    }

    &.narrow {
        @apply px-2 min-w-0;
    }
    &.red {
        @apply bg-red-600 text-red-100;

        &:hover {
            @apply bg-red-700;
        }
    }

    &.gray {
        @apply bg-gray-600 text-gray-300;

        &:hover {
            @apply bg-gray-700;
        }
    }

    &.disabled {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.disabled:hover {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.loading {
        @apply opacity-75;
    }

    &.loading:hover {
        @apply cursor-not-allowed;
    }
}
</style>
