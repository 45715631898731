<template>
    <div>
        <div class="bg-gray-100 p-5 rounded-lg shadow">
            <p
                class="text-center text-gray-300 my-5"
                :class="{
                    'text-green-600': rawFile,
                }"
            >
                <font-awesome-icon :icon="['fas', ionIcon]" size="6x" />
            </p>

            <data-upload-input
                :label="__(`forms.data.select.${iontype}`)"
                :help="__('forms.data.select')"
                :success-text="__('forms.data.select.successText')"
                :iontype="iontype"
                :visit="visit"
            />

            <div v-if="addCo2">
                <data-upload-input
                    :label="__(`forms.data.select.${iontype}_co2`)"
                    :help="__('forms.data.select_co2')"
                    :success-text="__('forms.data.select_co2.successText')"
                    :iontype="iontype"
                    :visit="visit"
                    filetype="co2"
                />
            </div>

            <div
                v-if="rawFile || co2File"
                class="bg-gray-200 text-gray-700 -mx-5  my-5 border-t border-b border-gray-400"
            >
                <a
                    v-if="rawFile"
                    class="files flex no-underline"
                    :href="rawFile"
                    target="_blank"
                >
                    <p class="block w-2/3  py-5 pl-5">
                        <font-awesome-icon
                            :icon="['fas', 'file-archive']"
                            class="mr-1"
                        />visit_{{ visit }}_{{ iontype }}.raw
                    </p>
                    <span class="files__item">{{
                        __('forms.data.download')
                    }}</span>
                </a>

                <a
                    v-if="co2File"
                    class="files flex no-underline"
                    :href="co2File"
                    target="_blank"
                >
                    <p class="block w-2/3  py-5 pl-5">
                        <font-awesome-icon
                            :icon="['fas', 'file']"
                            class="mr-1"
                        />visit_{{ visit }}_{{ iontype }}.txt
                    </p>
                    <span class="files__item">{{
                        __('forms.data.download')
                    }}</span>
                </a>
            </div>
        </div>
        <basic-button
            v-show="!addCo2"
            data-cypress="upload.button.addCO2"
            color="lightgray"
            icon="plus-square"
            size="sm"
            class="mt-4"
            @click="addCo2 = !addCo2"
            >{{ __('forms.data.addCo2') }}
        </basic-button>
    </div>
</template>

<script>
export default {
    props: {
        // Visit Number of the Form
        visit: {
            // Number with either '1' or '2'
            type: Number,
            required: true,
        },

        // Ioninzation Type of the data
        iontype: {
            type: String,
            required: true,
            validator: value => {
                // The filetype must a specific type
                return ['pos', 'neg'].indexOf(value) !== -1
            },
        },
    },
    data() {
        return {
            addCo2: false,
        }
    },
    computed: {
        ionIcon() {
            return this.iontype === 'neg' ? 'minus-circle' : 'plus-circle'
        },

        co2File() {
            return this.getFileByVisit(this.visit, this.iontype, 'co2')
        },

        rawFile() {
            return this.getFileByVisit(this.visit, this.iontype, 'raw')
        },

        ...vuex.mapState('patient', {
            upload: state => state.upload,
        }),

        ...vuex.mapGetters('patient', [
            'patient',
            'loadingPatient',
            'hasFilebyVisit',
            'getFileByVisit',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.files {
    @apply transition-colors duration-500 ease-in-out;

    .files__item {
        @apply opacity-0 transition-opacity duration-500 ease-in-out w-1/3 py-5 px-5 text-center  border-gray-400 font-bold no-underline;
    }

    &:hover {
        @apply bg-gray-300;

        .files__item {
            @apply opacity-100;
        }
    }
}
</style>
