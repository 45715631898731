<template>
    <div>
        temporary chapter
        <headline tag="h4">params</headline>
        <pre>{{ $route.params }}</pre>
    </div>
</template>

<script>
export default {}
</script>
