<template>
    <dark-pattern-template>
        <div class="flex justify-center items-center min-h-screen ">
            <div
                class="login-box max-w-md w-full mx-4 bg-secondary-100 p-8 px-12 shadow-lg rounded-lg"
            >
                <logo-unicolor class="mx-6 mb-8 " />
                <login-form />
            </div>
        </div>
    </dark-pattern-template>
</template>

<script>
export default {}
</script>
