import formObjectWatch from 'helpers/watchers/formObjectWatch'
export default {
    data() {
        return {
            form: {},
            validation: {},
            copiedData: [],
        }
    },
    computed: {
        visitNumber() {
            const { visitNumber } = this.$route.params
            return (visitNumber && visitNumber) || 1
        },
        screeningDocId() {
            let { visitNumber } = this.$route.params
            let docId =
                (visitNumber && `screening${visitNumber}`) || 'screening'
            return this.forms && this.forms[docId]
        },
        visitDocId() {
            let { visitNumber } = this.$router.history.current.params
            let docId = (visitNumber && `visit${visitNumber}`) || 'visit'
            return this.forms && this.forms[docId]
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
            activeProject: 'projects/activeProject',
        }),
    },
    watch: {
        screeningDocId: {
            handler: formObjectWatch,
            immediate: true,
        },
        visitDocId: {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        // Fetches form field two levels deep from forms object
        getFormsObjectField(field, chapter = '', index = 0) {
            if (!this.visitDocId) {
                return false
            }
            if (
                !this.visitDocId[chapter] ||
                this.visitDocId[chapter].length === 0
            ) {
                return this.visitDocId[field] || false
            }
            const chapterObj = this.visitDocId[chapter][index]
            return (chapterObj && chapterObj[field]) || false
        },

        // Fetches form field two levels deep from 'current' form object
        getFieldFromCurrentForm(field, chapter = '', index = 0) {
            if (!this.form[chapter]) {
                return (this.form[field] && this.form[field]) || false
            }
            const chapterObj = this.form[chapter][index]
            return (chapterObj && chapterObj[field]) || false
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
