<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.leftColumn"
                    />
                    <div class="my-4 h-10">
                        <font-awesome-icon
                            class="text-gray-600"
                            :icon="['fa', 'chevron-square-right']"
                        />
                        {{
                            __('forms.bloodPressure.average', [
                                averageBloodPressure,
                            ])
                        }}:
                    </div>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.leftColumnBottom"
                    />
                </div>
                <div class="flex flex-col flex-1 pl-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.rightColumn"
                    />
                    <div class="my-4 h-10">
                        <font-awesome-icon
                            class="text-gray-600"
                            :icon="['fa', 'chevron-square-right']"
                        />
                        {{ __('forms.heartRate.average', [averageHeartRate]) }}
                    </div>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.rightColumnBottom"
                    />
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'VitalSignsShared',
    data() {
        return {
            form: {},
            validation: { hasErrors: false },
            schema: {
                leftColumn: [
                    {
                        name: 'bloodPressure.1',
                        label: `${this.__('forms.bloodPressure')} 1 (${this.__(
                            'forms.mmHg'
                        )})`,
                        placeholder: '--/--',
                        validation: 'isBloodPressure',
                        type: 'text',
                    },
                    {
                        name: 'bloodPressure.2',
                        label: `${this.__('forms.bloodPressure')} 2 (${this.__(
                            'forms.mmHg'
                        )})`,
                        placeholder: '--/--',
                        validation: 'isBloodPressure',
                        type: 'text',
                    },
                    {
                        name: 'bloodPressure.3',
                        label: `${this.__('forms.bloodPressure')} 3 (${this.__(
                            'forms.mmHg'
                        )})`,
                        placeholder: '--/--',
                        validation: 'isBloodPressure',
                        type: 'text',
                    },
                ],
                leftColumnBottom: [
                    {
                        name: 'temperature',
                        label: `${this.__('forms.temperature')} (${this.__(
                            'forms.celsius'
                        )})`,
                        placeholder: this.__('forms.temperature'),
                        validation: 'isNumber',
                        type: 'text',
                    },
                ],
                rightColumn: [
                    {
                        name: 'heartRate.1',
                        label: `${this.__('forms.heartRate')} 1`,
                        placeholder: '--/min',
                        validation: 'isNumber',
                        type: 'text',
                    },
                    {
                        name: 'heartRate.2',
                        label: `${this.__('forms.heartRate')} 2`,
                        placeholder: '--/min',
                        validation: 'isNumber',
                        type: 'text',
                    },
                    {
                        name: 'heartRate.3',
                        label: `${this.__('forms.heartRate')} 3`,
                        placeholder: '--/min',
                        validation: 'isNumber',
                        type: 'text',
                    },
                ],
                rightColumnBottom: [
                    {
                        name: 'sp02',
                        label: `${this.__('forms.sp02')} (%)`,
                        placeholder: this.__('forms.sp02'),
                        validation: 'isNumber',
                        type: 'text',
                    },
                ],
            },
        }
    },
    computed: {
        averageBloodPressure() {
            const defaultVal = '-- / --'
            const { hasErrors, name } = this.validation

            if (hasErrors && name.includes('bloodPressure')) {
                return defaultVal
            }

            const variables = [
                'bloodPressure.1',
                'bloodPressure.2',
                'bloodPressure.3',
            ]

            const result = {
                left: 0,
                right: 0,
            }
            let howManyVariablesInResult = 0

            variables.forEach(node => {
                if (this.form[node]) {
                    const [left, right] = this.form[node].split('/')
                    howManyVariablesInResult += 1
                    result.left += parseInt(left, 10)
                    result.right += parseInt(right, 10)
                }
            })

            const finalHowManyVariales =
                howManyVariablesInResult === 0 ? 1 : howManyVariablesInResult

            const outcome = {
                left: Math.round(result.left / finalHowManyVariales),
                right: Math.round(result.right / finalHowManyVariales),
            }
            if (isNaN(outcome.left) || isNaN(outcome.right)) {
                return defaultVal
            }

            return `${outcome.left} / ${outcome.right}`
        },
        averageHeartRate() {
            const defaultVal = '--'
            const { hasErrors, name } = this.validation

            if (hasErrors && name.includes('heartRate')) {
                return defaultVal
            }

            const variables = ['heartRate.1', 'heartRate.2', 'heartRate.3']
            let outcome = 0
            let howManyVariablesInResult = 0

            variables.forEach(node => {
                if (this.form[node]) {
                    outcome += parseInt(this.form[node], 10)
                    howManyVariablesInResult += 1
                }
            })
            if (isNaN(outcome)) {
                return defaultVal
            }
            const finalHowManyVariales =
                howManyVariablesInResult === 0 ? 1 : howManyVariablesInResult

            return Math.round(outcome / finalHowManyVariales)
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
