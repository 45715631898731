/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import { router, appStartRoute } from 'router'
import { fb, analytics } from 'helpers/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import { status } from 'helpers/constants'

const mutations = {
    SET_LOADING_STATUS: 'SET_LOADING',
    SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
    SET_ERROR: 'SET_ERROR',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
}
const auth = {
    namespaced: true,
    state: {
        error: [],
        loggedIn: false,
        loadingStatus: status.idle,
        user: null,
    },
    mutations: {
        [mutations.SET_CURRENT_USER](state, user) {
            state.user = user
        },
        [mutations.SET_LOADING_STATUS](state, newStatus) {
            if (Object.keys(status).includes(newStatus)) {
                state.loadingStatus = newStatus
            } else {
                throw 'Invalid Loading State'
            }
        },
        // Save Auth Status
        [mutations.SET_LOGIN_STATUS](state, authStatus) {
            state.loggedIn = authStatus
        },
        // Set the error message
        // Description: Timestamp is important to distinguish new error with same text
        [mutations.SET_ERROR](state, err) {
            state.error = err
            state.error.timestamp = Date.now()
        },
    },
    actions: {
        init({ commit }) {
            // Reset the process
            commit(mutations.SET_LOADING_STATUS, status.idle)
            commit(mutations.SET_ERROR, [])
            commit(mutations.SET_LOGIN_STATUS, false)
            commit(mutations.SET_CURRENT_USER, null)
        },
        authStateChanged({ commit }, user) {
            const isLoggedIn = user !== null
            commit(mutations.SET_LOGIN_STATUS, isLoggedIn)
            if (isLoggedIn) {
                commit(mutations.SET_CURRENT_USER, user)
            }
        },
        async login({ commit }, credentials) {
            commit(mutations.SET_LOADING_STATUS, status.loading)
            try {
                // https://firebase.google.com/docs/auth/web/auth-state-persistence
                await fb.auth().setPersistence('session')
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, status.error)
            }
            try {
                const user = await fb
                    .auth()
                    .signInWithEmailAndPassword(
                        credentials.email,
                        credentials.password
                    )
                commit(mutations.SET_LOGIN_STATUS, true)
                commit(mutations.SET_LOADING_STATUS, status.complete)
                commit(mutations.SET_CURRENT_USER, user)
                analytics.logEvent('login')

                router.push({ name: appStartRoute })
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, status.error)
                analytics.logEvent('login_attempt_failed')
            }
        },
        async logout({ commit }) {
            commit(mutations.SET_LOADING_STATUS, status.loading)
            try {
                await fb.auth().signOut()
                commit(mutations.SET_LOGIN_STATUS, false)
                commit(mutations.SET_LOADING_STATUS, status.complete)
                commit(mutations.SET_CURRENT_USER, null)
                analytics.logEvent('logout')
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, status.error)
            }
        },
        async register({ commit, dispatch }, credentials) {
            commit(mutations.SET_LOADING_STATUS, status.loading)
            try {
                const user = await fb
                    .auth()
                    .createUserWithEmailAndPassword(
                        credentials.email,
                        credentials.password
                    )
                commit(mutations.SET_LOGIN_STATUS, true)
                commit(mutations.SET_LOADING_STATUS, status.complete)
                commit(mutations.SET_CURRENT_USER, user)
                dispatch('sendVerificationEmail')
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, status.error)
            }
        },
        async sendVerificationEmail() {
            const currentUser = await fb.auth().currentUser
            if (!currentUser) {
                return
            }
            try {
                currentUser.sendEmailVerification()
            } catch (err) {
                throw new Error('FAIL: sendVerificationEmail - no user sign in')
            }
        },
        async passwordReset({ commit }, email) {
            commit(mutations.SET_LOADING_STATUS, status.loading)

            try {
                await fb.auth().sendPasswordResetEmail(email)
                commit(mutations.SET_CURRENT_USER, null)
                commit(mutations.SET_LOGIN_STATUS, false)
                commit(mutations.SET_LOADING_STATUS, status.complete)
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, status.error)
            }
        },
    },
    getters: {
        isLoggedIn: state => state.loggedIn,
        isLoading: ({ loadingStatus }) => loadingStatus === status.loading,
        status: ({ loadingStatus }) => loadingStatus,
        user: ({ user }) => user,
        error: state => state.error,
    },
}

listAllActionsOfStore(auth, 'auth')
listAllGettersOfStore(auth, 'auth')

export default auth
