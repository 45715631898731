<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-1 flex-wrap">
                <formulate-schema v-model="form" :schema="schema.top" />
            </div>
            <div class="flex flex-1 flex-wrap">
                <div class="flex w-full md:w-1/2  flex-grow flex-col">
                    <headline tag="h4">{{
                        __('forms.breathAnalysis.didYouPastHour')
                    }}</headline>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.selectables.left"
                    />
                </div>
                <div class="flex w-full md:w-1/2  flex-grow flex-col">
                    <headline tag="h4">{{
                        __('forms.breathAnalysis.didYou')
                    }}</headline>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.selectables.right"
                    />
                </div>
            </div>
            <div class="flex flex-1 flex-wrap">
                <formulate-schema v-model="form" :schema="schema.middle" />
            </div>
            <div class="flex flex-1 flex-wrap">
                <headline tag="h4" class-name="w-full">{{
                    __('forms.breathAnalysis.posMode')
                }}</headline>
                <formulate-schema
                    v-model="form"
                    :schema="schema.positiveMode"
                />
            </div>
            <div class="flex flex-1 flex-wrap" class-name="w-full">
                <headline tag="h4" class-name="w-full">{{
                    __('forms.breathAnalysis.negMode')
                }}</headline>
                <formulate-schema
                    v-model="form"
                    :schema="schema.negativeMode"
                />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const commonClassesLeft = 'w-1/2 pr-8'
const commonClassesRight = 'w-1/2 pl-8'

export default {
    name: 'BreathAnalysisShared',
    data() {
        return {
            form: {},
            validation: {},
            schema: {
                top: [
                    {
                        name: 'breathAnalysis.date',
                        label: this.__('forms.breathAnalysis.date'),
                        type: 'datetime-local',
                        class: commonClassesLeft,
                    },
                ],
                selectables: {
                    left: [
                        {
                            name: 'breathAnalysis.consumeAlcohol',
                            label: this.__(
                                'forms.breathAnalysis.consumeAlcohol'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.hadDrinkNoWater',
                            label: this.__(
                                'forms.breathAnalysis.hadDrinkNoWater'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.smokeConsumeTobacco',
                            label: this.__(
                                'forms.breathAnalysis.smokeConsumeTobacco'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.chewGumCandy',
                            label: this.__('forms.breathAnalysis.chewGumCandy'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.brushTeeth',
                            label: this.__('forms.breathAnalysis.brushTeeth'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                    right: [
                        {
                            name: 'breathAnalysis.consumeCaffeinatedDrink',
                            label: this.__(
                                'forms.breathAnalysis.consumeCaffeinatedDrink'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.useInhalants',
                            label: this.__('forms.breathAnalysis.useInhalants'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.acuteCold6Weeks',
                            label: this.__(
                                'forms.breathAnalysis.acuteCold6Weeks'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.cosmeticFace',
                            label: this.__('forms.breathAnalysis.cosmeticFace'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                },
                middle: [
                    {
                        name: 'breathAnalysis.explanation',
                        label: this.__('forms.breathAnalysis.explanation'),
                        type: 'text',
                        class: 'w-full',
                    },
                    {
                        name: 'breathAnalysis.mouthRinsed',
                        label: this.__('forms.breathAnalysis.mouthRinsed'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                        class: commonClassesLeft,
                    },
                    {
                        name: 'breathAnalysis.capilliarySupplier',
                        label: this.__(
                            'forms.breathAnalysis.capilliarySupplier'
                        ),
                        placeholder: this.__(
                            'forms.breathAnalysis.capilliarySupplier'
                        ),
                        type: 'text',
                        class: commonClassesRight,
                    },
                ],
                positiveMode: [
                    {
                        name: 'breathAnalysis.positiveMode.capPosition',
                        label: this.__('forms.breathAnalysis.capPosition'),
                        type: 'text',
                        class: commonClassesLeft,
                    },
                    {
                        name: 'breathAnalysis.positiveMode.sprayBaseline',
                        label: this.__('forms.breathAnalysis.sprayBaseline'),
                        type: 'text',
                        class: commonClassesRight,
                    },
                    {
                        name: 'breathAnalysis.positiveMode.sprayMeasurement',
                        label: this.__('forms.breathAnalysis.sprayMeasurement'),
                        type: 'text',
                        class: commonClassesLeft,
                    },
                    {
                        name: 'breathAnalysis.positiveMode.exhalationQuality',
                        label: this.__(
                            'forms.breathAnalysis.exhalationQuality'
                        ),
                        type: 'select',
                        class: commonClassesRight,
                        options: {
                            '': '',
                            good: this.__('good'),
                            ok: this.__('ok'),
                            bad: this.__('bad'),
                        },
                    },
                    {
                        name:
                            'breathAnalysis.positiveMode.exhalationDescription',
                        label: this.__(
                            'forms.breathAnalysis.exhalationDescription'
                        ),
                        type: 'text',
                        class: 'w-full',
                    },
                ],
                negativeMode: [
                    {
                        name: 'breathAnalysis.negativeMode.capPosition',
                        label: this.__('forms.breathAnalysis.capPosition'),
                        type: 'text',
                        class: commonClassesLeft,
                    },
                    {
                        name: 'breathAnalysis.negativeMode.sprayBaseline',
                        label: this.__('forms.breathAnalysis.sprayBaseline'),
                        type: 'text',
                        class: commonClassesRight,
                    },
                    {
                        name: 'breathAnalysis.negativeMode.sprayMeasurement',
                        label: this.__('forms.breathAnalysis.sprayMeasurement'),
                        type: 'text',
                        class: commonClassesLeft,
                    },
                    {
                        name: 'breathAnalysis.negativeMode.exhalationQuality',
                        label: this.__(
                            'forms.breathAnalysis.exhalationQuality'
                        ),
                        type: 'select',
                        class: commonClassesRight,
                        options: {
                            '': '',
                            good: this.__('good'),
                            ok: this.__('ok'),
                            bad: this.__('bad'),
                        },
                    },
                    {
                        name:
                            'breathAnalysis.negativeMode.exhalationDescription',
                        label: this.__(
                            'forms.breathAnalysis.exhalationDescription'
                        ),
                        type: 'text',
                        class: 'w-full',
                    },
                ],
            },
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
