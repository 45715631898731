const status = {
    idle: 'idle',
    loading: 'loading',
    complete: 'complete',
    incomplete: 'incomplete',
    fail: 'fail',
    error: 'error',
}

const formsStatus = {
    notStarted: 'notStarted',
    inProgress: 'inProgress',
    finished: 'finished',
    withErrors: 'withErrors',
}

// in miliseconds
const debounceTimers = {
    default: 300,
    maxWait: 1000,
}

// Empty Upload Object

const emptyUploadObject = {
    status: formsStatus.notStarted,
    error: false,
    progress: 0,
    completed: false,
}

const emptyUpload = function() {
    return {
        pos: {
            raw: {
                ...emptyUploadObject,
            },
            co2: {
                ...emptyUploadObject,
            },
        },
        neg: {
            raw: {
                ...emptyUploadObject,
            },
            co2: {
                ...emptyUploadObject,
            },
        },
    }
}

export { status, debounceTimers, formsStatus, emptyUpload }
