<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col">
                <div class="flex flex-col pr-8 w-full sm:w-1/2">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.firstRow"
                    />
                </div>
                <div v-if="schema.secondRow" class="flex flex-1">
                    <div class="flex flex-col flex-grow pr-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.secondRow.leftColumn"
                        />
                    </div>
                    <div class="flex flex-col flex-grow pl-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.secondRow.rightColumn"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <headline tag="h3">{{
                    __('forms.smoking.changeInSmoking')
                }}</headline>
                <div class="flex flex-col pr-8 w-full sm:w-1/2">
                    <formulate-schema
                        v-model="form"
                        :schema="schemaV2.firstRow"
                    />
                </div>
                <div v-if="schemaV2.secondRow" class="flex flex-1">
                    <div class="flex flex-col flex-grow pr-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schemaV2.secondRow.leftColumn"
                        />
                    </div>
                    <div class="flex flex-col flex-grow pl-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schemaV2.secondRow.rightColumn"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import copySomeFromVisit1 from 'helpers/watchers/copySomeFromVisit1'
import submitFormPartial from 'helpers/forms/submitFormPartial'
import flatten from 'flat'

const smokerTypes = {
    neverSmoker: 'neverSmoker',
    smoker: 'smoker',
    exSmoker: 'exSmoker',
}

export default {
    name: 'SmokingHistorySharedV2',
    data() {
        return {
            form: {},
            validation: {},
            validation2: {},
        }
    },
    computed: {
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'smoking.habits',
                        label: this.__('forms.smoking.habits'),
                        type: 'select',
                        options: {
                            '': '',
                            [smokerTypes.neverSmoker]: this.__(
                                'forms.smoking.neverSmoker'
                            ),
                            [smokerTypes.smoker]: this.__(
                                'forms.smoking.smoker'
                            ),
                            [smokerTypes.exSmoker]: this.__(
                                'forms.smoking.exSmoker'
                            ),
                        },
                    },
                ],
            }

            const habit = this.form['smoking.habits']

            if ([smokerTypes.smoker, smokerTypes.exSmoker].includes(habit)) {
                schema.secondRow = {
                    leftColumn: {},
                    rightcolumn: {},
                }
            }

            const howManyYearsClasses = 'text-gray-600 py-10 text-lg'

            if (habit === smokerTypes.smoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smoking.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-1',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-1',
                                children: this.YearsSmoking('smoking'),
                            },
                        ],
                    },
                    {
                        name: 'smoking.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]

                schema.secondRow.rightColumn = [
                    {
                        name: 'smoking.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                ]
            }
            if (habit === smokerTypes.exSmoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smoking.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        name: 'smoking.ended',
                        label: this.__('forms.smoking.ended'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        name: 'smoking.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]
                schema.secondRow.rightColumn = [
                    {
                        name: 'smoking.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-1',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-1',
                                children: this.YearsSmoking('smoking'),
                            },
                        ],
                    },
                ]
            }

            return schema
        },
        schemaV2() {
            const schema = {
                firstRow: [
                    {
                        name: 'smokingV2.changeInSmokingHabits',
                        label: this.__('forms.smoking.changeInSmoking'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                    },
                ],
            }

            if (this.form['smokingV2.changeInSmokingHabits'] === 'yes') {
                schema.firstRow.push({
                    name: 'smokingV2.habits',
                    label: this.__('forms.smoking.habits'),
                    type: 'select',
                    options: {
                        '': '',
                        [smokerTypes.neverSmoker]: this.__(
                            'forms.smoking.neverSmoker'
                        ),
                        [smokerTypes.smoker]: this.__('forms.smoking.smoker'),
                        [smokerTypes.exSmoker]: this.__(
                            'forms.smoking.exSmoker'
                        ),
                    },
                })
            }

            const habit = this.form['smokingV2.habits']

            if ([smokerTypes.smoker, smokerTypes.exSmoker].includes(habit)) {
                schema.secondRow = {
                    leftColumn: {},
                    rightcolumn: {},
                }
            }

            const howManyYearsClasses = 'text-gray-600 py-10 text-lg'

            if (habit === smokerTypes.smoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smokingV2.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-1',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-1',
                                children: this.YearsSmoking('smokingV2'),
                            },
                        ],
                    },
                    {
                        name: 'smokingV2.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]

                schema.secondRow.rightColumn = [
                    {
                        name: 'smokingV2.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                ]
            }
            if (habit === smokerTypes.exSmoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smokingV2.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        name: 'smokingV2.ended',
                        label: this.__('forms.smoking.ended'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        name: 'smokingV2.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]
                schema.secondRow.rightColumn = [
                    {
                        name: 'smokingV2.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-1',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-1',
                                children: this.YearsSmoking('smokingV2'),
                            },
                        ],
                    },
                ]
            }

            return schema
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        forms: {
            handler: function(newVal) {
                return copySomeFromVisit1(newVal, this, ['smoking'])
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        submit() {
            const { smokingV2 } = flatten.unflatten(this.form)

            submitFormPartial(this, {
                docId: this.form.docId,
                smokingV2: { ...smokingV2 },
            })
        },
        YearsSmoking(whichVisit) {
            const text = this.__('forms.smoking.smokingYears')
            let finalText = `${text} --`
            const started = this.form[`${whichVisit}.started`]
            const ended = this.form[`${whichVisit}.ended`]
            const date = new Date()
            const currentYear = date.getFullYear()
            let parsedStart, parsedEnd

            if (started) {
                parsedStart = parseInt(started, 10)
            }
            if (ended) {
                parsedEnd = parseInt(ended, 10)
            }

            if (started && !ended && started.length === 4) {
                finalText = `${text} ${currentYear - parsedStart}`
            }
            if (
                started &&
                ended &&
                started.length === 4 &&
                ended.length === 4
            ) {
                finalText = `${text} ${parsedEnd - parsedStart}`
            }

            return finalText
        },

        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
