var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('formulate-form',{attrs:{"error-behavior":"live"},on:{"validation":function($event){_vm.validation = $event},"submit":_vm.submit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('div',{staticClass:"flex flex-wrap"},[_c('formulate-schema',{attrs:{"schema":_vm.schema.firstRow},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',[_c('formulate-input',{attrs:{"type":"group","name":"microbiology","repeatable":true,"add-label":_vm.__('forms.laboratory.addMicrobiologyReport')},scopedSlots:_vm._u([{key:"default",fn:function(groupProps){return [_c('div',{staticClass:"grid grid-cols-2 gap-12 mx-auto mb-0"},[_c('div',[_c('formulate-input',{attrs:{"name":"report","type":"select","label":_vm.__(
                                        'forms.laboratory.microbiologyReport'
                                    ),"options":{
                                    '': '',
                                    negative: _vm.__('negative'),
                                    positive: _vm.__('positive'),
                                }}})],1),_c('div',[_c('formulate-input',{attrs:{"name":"date","type":"date","label":_vm.__('forms.laboratory.date'),"placeholder":_vm.__('forms.laboratory.date')}})],1)]),(
                            Array.isArray(groupProps.model) &&
                                groupProps.model[0].report === 'positive'
                        )?_c('div',{staticClass:"grid grid-cols-2 gap-12 mx-auto mb-0"},[_c('div'),_c('formulate-input',{staticClass:"w-3/4",attrs:{"type":"group","name":"bacteria","repeatable":true,"add-label":_vm.__(
                                    'forms.laboratory.addBacteriaDescription'
                                )}},[[_c('formulate-input',{attrs:{"name":"bacteria","type":"text","label":_vm.__(
                                            'forms.laboratory.bacteriaDescription'
                                        ),"placeholder":_vm.__(
                                            'forms.laboratory.bacteriaDescription'
                                        )}})]],2)],1):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-10"},[_c('formulate-input',{attrs:{"type":"group","name":"virology","repeatable":true,"add-label":_vm.__('forms.laboratory.addVirologyReport')},scopedSlots:_vm._u([{key:"default",fn:function(groupProps){return [_c('div',{staticClass:"grid grid-cols-2 gap-12 mx-auto mb-0"},[_c('div',[_c('formulate-input',{attrs:{"name":"report","type":"select","label":_vm.__('forms.laboratory.VirologyReport'),"options":{
                                    '': '',
                                    negative: _vm.__('negative'),
                                    positive: _vm.__('positive'),
                                }}})],1),_c('div',[_c('formulate-input',{attrs:{"name":"date","type":"date","label":_vm.__('forms.laboratory.date'),"placeholder":_vm.__('forms.laboratory.date')}})],1)]),(
                            Array.isArray(groupProps.model) &&
                                groupProps.model[0].report === 'positive'
                        )?_c('div',{staticClass:"grid grid-cols-2 gap-12 mx-auto mb-10"},[_c('div'),_c('formulate-input',{staticClass:"w-3/4",attrs:{"type":"group","name":"virus","repeatable":true,"add-label":_vm.__('forms.laboratory.addVirusDescription')}},[[_c('formulate-input',{attrs:{"name":"virus","type":"text","label":_vm.__(
                                            'forms.laboratory.virusDescription'
                                        ),"placeholder":_vm.__(
                                            'forms.laboratory.virusDescription'
                                        )}})]],2)],1):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-8 flex justify-end"},[_c('formulate-input',{attrs:{"type":"submit","label":_vm.__('save'),"data-cypress":"form.next","disabled":_vm.validation.hasErrors}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }