<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div>
                <div class="w-full grid grid-cols-2 gap-x-12 md:gap-x-24">
                    <formulate-input
                        type="group"
                        name="morbidities"
                        validation-name="morbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                            />
                        </template>
                    </formulate-input>
                    <formulate-input
                        type="group"
                        name="comorbidities"
                        :repeatable="true"
                        :add-label="__('diagnosis.add')"
                        validation-name="comorbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="comorbidity"
                                type="icd"
                                :label="__('forms.comorbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.comorbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
                <formulate-input
                    name="comments"
                    type="text"
                    :label="__('forms.morbidity.comments')"
                    :placeholder="__('forms.morbidity.comments')"
                    data-cypress="form.input.comment"
                    class=" w-full my-0"
                />
            </div>

            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'MorbiditiesBex',
    mixins: [visitData],
    methods: {
        submit() {
            submitForm(this)
        },
    },
}
</script>
