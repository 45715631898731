<template>
    <div>
        screening from shared forms
    </div>
</template>

<script>
export default {
    name: 'ScreeningShared',
    data() {
        return {}
    },
    methods: {},
}
</script>
<style lang="scss" scoped></style>
