import store from 'store'
import authStore from 'store/auth'

export default () =>
    new Promise((resolve, reject) => {
        if (store.getters[authStore.getterTypes.isLoggedIn] === true) {
            return resolve()
        } else {
            reject({ name: 'Login' })
        }
    })
