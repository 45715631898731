<template>
    <div
        v-if="form.status"
        v-scroll-reveal.reset
        class="patientForm-card"
        :data-cypress="form.docId"
        :class="{
            'patientForm-card--disabled': allFormsCompleted,
        }"
        @click="handleClickInner"
    >
        <div class="patientForm-card--body">
            <div
                class="patientForm-card--subtitle"
                :class="{
                    'patientForm-card--subtitle--disabled': allFormsCompleted,
                }"
                data-cypress="patientFormCard.subtitle"
            >
                {{ form.subtitle }}
            </div>
            <div
                class="patientForm-card--title"
                :class="{
                    'patientForm-card--title--disabled': allFormsCompleted,
                }"
                data-cypress="patientFormCard.title"
            >
                {{ form.title }}
            </div>
        </div>
        <div class="patientForm-card--footer">
            <div
                class="patientForm-card--status"
                :class="{
                    'patientForm-card--status--in-progress':
                        form.status === status.inProgress,
                    'patientForm-card--status--finished':
                        form.status === status.finished,
                    'patientForm-card--status--disabled': allFormsCompleted,
                }"
                :data-cypress="`patientForm-card.status`"
                :disabled="!form.active"
            >
                {{ __(`patient.status.${form.status}`) }}
            </div>
        </div>
    </div>
</template>

<script>
import { formsStatus } from 'helpers/constants'
/**
 * @group ATOMS
 * Simple input box with possible icon
 */
export default {
    $inheritAttrs: true,
    props: {
        handleClick: {
            type: Function,
            required: true,
        },
        allFormsCompleted: Boolean,
        form: {
            type: Object,
            default: () => ({ active: false }),
        },
    },
    data() {
        return {
            status: { ...formsStatus },
        }
    },
    methods: {
        handleClickInner() {
            this.handleClick(this.form)
        },
    },
}
</script>

<style lang="scss" scoped>
.patientForm-card {
    @apply flex flex-wrap h-auto min-w-20 p-4 rounded-lg shadow-md border border-gray-400 mb-6 transition-all duration-300 cursor-pointer;

    &--disabled {
        @apply bg-gray-400 border-transparent;
    }

    &:hover {
        @apply shadow-sm;
    }

    &--body {
        @apply text-primary-700 w-full justify-center text-center md:justify-start md:text-left;

        &--disabled {
            @apply text-gray-500;
        }
    }

    &--title {
        @apply text-xl font-bold;

        &--disabled {
            @apply text-gray-400;
        }
    }

    &--subtitle {
        @apply text-base;

        &--disabled {
            @apply text-gray-400;
        }
    }

    &--footer {
        @apply flex justify-center w-full my-4 md:justify-start;
    }

    &--status {
        @apply flex justify-center self-center align-middle text-white bg-gray-400 px-2 py-1 rounded-xl font-bold capitalize;

        &--in-progress {
            @apply bg-primary-700 text-white;
        }
        &--finished {
            @apply bg-primary-700 text-white;
        }
        &--disabled {
            @apply bg-gray-500 text-gray-400;
        }
    }
}
</style>
