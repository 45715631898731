<template>
    <div class="bg-white h-screen">
        <header>
            <app-navbar />
        </header>
        <div class="container my-12 mx-auto pb-12 px-4">
            <!-- Slot for all contents -->
            <slot></slot>
        </div>
        <footer>
            <!-- Optional slot for all footer contents -->
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
/**
 * @group TEMPLATES
 * Default template with app nav bar
 */
export default {
    components: {},
    props: {},
}
</script>
