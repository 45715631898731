import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const fb = firebase.initializeApp(config)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const currentUser = firebase.auth().currentUser
const functions = fb.functions(process.env.VUE_APP_FIREBASE_FUNCTIONS_REGION)
const analytics = firebase.analytics()

export default fb
export {
    config,
    firebase,
    fb,
    storage,
    db,
    functions,
    analytics,
    auth,
    currentUser,
}
