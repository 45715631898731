<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-input
                        type="group"
                        name="morbidities"
                        validation-name="morbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
                <div class="flex flex-col flex-1 pl-7">
                    <formulate-input
                        type="group"
                        name="comorbidities"
                        :repeatable="true"
                        :add-label="__('diagnosis.add')"
                        validation-name="comorbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="comorbidity"
                                type="icd"
                                :label="__('forms.comorbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.comorbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
            </div>
            <div class="flex flex-1">
                <div class="w-full md:w-1/2">
                    <formulate-schema v-model="form" :schema="schema" />
                </div>
            </div>
            <div v-if="showMorbidityChangesForm" class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-input
                        type="group"
                        name="morbiditiesV2"
                        validation-name="morbiditiesV2"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
                <div class="flex flex-col flex-1 pl-7">
                    <formulate-input
                        type="group"
                        name="comorbiditiesV2"
                        :repeatable="true"
                        :add-label="__('diagnosis.add')"
                        validation-name="comorbiditiesV2"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="comorbidity"
                                type="icd"
                                :label="__('forms.comorbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.comorbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import copySomeFromVisit1 from 'helpers/watchers/copySomeFromVisit1'
import submitFormPartial from 'helpers/forms/submitFormPartial'
import flatten from 'flat'

export default {
    name: 'MorbiditiesSharedV2',
    data() {
        return {
            form: {},
            validation: {},
            schema: [
                {
                    name: 'morbidity.changeInMorbidities',
                    label: this.__('forms.morbidity.change'),
                    type: 'select',
                    options: {
                        '': '',
                        yes: this.__('yes'),
                        no: this.__('no'),
                    },
                },
            ],
        }
    },
    computed: {
        showMorbidityChangesForm() {
            const field = 'morbidity.changeInMorbidities'
            if (this.form[field]) {
                return this.form[field] === 'yes'
            }
            return false
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        forms: {
            handler: function(newVal) {
                return copySomeFromVisit1(newVal, this, [
                    'morbidities',
                    'comorbidities',
                ])
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        submit() {
            const {
                morbiditiesV2,
                comorbiditiesV2,
                morbidity,
            } = flatten.unflatten(this.form)

            submitFormPartial(this, {
                docId: this.form.docId,
                morbiditiesV2,
                comorbiditiesV2,
                morbidity,
            })
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
