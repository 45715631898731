<template>
    <div>
        <label
            v-if="label"
            class="block text-secondary-700 text-sm font-bold mb-2 "
            for="name"
        >
            {{ label }}
        </label>
        <div class="input-box" :class="{ 'icon-left': icon, 'no-icon': !icon }">
            <span v-if="icon" class="icon-container">
                <font-awesome-icon :icon="['fas', icon]" />
            </span>
            <input
                v-model="computedValue"
                name="name"
                class="focus:outline-none"
                :type="type"
                :placeholder="getPlaceholder"
            />
        </div>
    </div>
</template>

<script>
import FormFieldMixin from 'helpers/mixins/FormFieldMixin'

/**
 * @group ATOMS
 * Simple input box with possible icon
 */
export default {
    $inheritAttrs: true,
    // This mixin enables v-model
    mixins: [FormFieldMixin],
    props: {
        // Name-Tag of the input
        name: {
            type: String,
            required: true,
        },

        // Type-Tag of the input
        type: {
            type: String,
            default: 'text',
        },

        // Optional icon of the input from FAS
        icon: {
            type: String,
            default: null,
        },

        // A label to display with the input
        label: {
            type: String,
            default: null,
        },

        // The Placeholder-Text shown in the input
        placeholder: {
            type: String,
            default: null,
        },
    },
    computed: {
        getPlaceholder() {
            return this.type === 'password' ? '•••••••••••' : this.placeholder
        },
    },
}
</script>

<style lang="scss" scoped>
.input-box {
    input {
        @apply w-full px-3 py-4 leading-none relative text-secondary-700 font-bold bg-gray-200 border-gray-200 border-2 rounded-lg outline-none appearance-none;
    }

    &.icon-left {
        @apply relative flex w-full flex-wrap items-stretch mb-3;

        .icon-container {
            @apply z-10 h-full leading-none text-secondary-700 font-normal absolute text-center rounded text-base items-center justify-center w-8 pl-3 py-5;
        }

        input {
            @apply pl-10;

            &:focus {
                @apply border-secondary-500 outline-none;
            }
        }
    }
}
</style>
