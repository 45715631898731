<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col">
                <div class="flex flex-wrap w-full">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.firstRow"
                    />
                </div>
                <headline v-if="form['dsi.emphysema'] === 'yes'" tag="h3">
                    {{ __('forms.dsi.emphysemaHeadline') }}
                </headline>

                <div class="flex flex-wrap w-full">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.secondRow"
                    />
                </div>
                <div class="flex flex-wrap w-full">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.thirdRow"
                    />
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const commonClasses = {
    left: 'w-1/2 pr-6',
    right: 'w-1/2 pl-6',
}

export default {
    name: 'DsiTrx',
    data() {
        return {
            form: {},
            validation: {},
            commonOptions: {
                '': '',
                yes: this.__('yes'),
                no: this.__('no'),
            },
        }
    },
    computed: {
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'dsi.ctThorax',
                        label: this.__('forms.dsi.ctThorax'),
                        type: 'select',
                        class: commonClasses.left,
                        options: this.commonOptions,
                    },
                    {
                        name: 'dsi.ctThoraxDate',
                        label: this.__('date'),
                        type: 'datetime-local',
                        class: commonClasses.right,
                    },
                    {
                        name: 'dsi.emphysema',
                        label: this.__('forms.dsi.emphysema'),
                        type: 'select',
                        class: commonClasses.left,
                        options: this.commonOptions,
                    },
                    {
                        name: 'dsi.emphysemaDate',
                        label: this.__('date'),
                        type: 'datetime-local',
                        class: commonClasses.right,
                    },
                ],
                secondRow: [],
                thirdRow: [
                    {
                        name: 'dsi.bronchiectasis',
                        label: this.__('forms.dsi.bronchiectasis'),
                        type: 'select',
                        class: commonClasses.left,
                        options: this.commonOptions,
                    },
                    {
                        name: 'dsi.bronchiectasisWallThick',
                        label: this.__('forms.dsi.bronchiectasisWallThick'),
                        type: 'select',
                        class: commonClasses.right,
                        options: this.commonOptions,
                    },
                ],
            }

            if (
                this.form['dsi.emphysema'] &&
                this.form['dsi.emphysema'] === 'yes'
            ) {
                const secondRow = [
                    {
                        name: 'dsi.lobe.rightUpper',
                        label: this.__('forms.dsi.lobe.rightUpper'),
                        type: 'text',
                        class: commonClasses.left,
                    },
                    {
                        name: 'dsi.lobe.rightUpperAndMiddle',
                        label: this.__('forms.dsi.lobe.rightUpperAndMiddle'),
                        type: 'text',
                        class: commonClasses.right,
                    },
                    {
                        name: 'dsi.lobe.rightMiddle',
                        label: this.__('forms.dsi.lobe.rightMiddle'),
                        type: 'text',
                        class: commonClasses.left,
                    },
                    {
                        name: 'dsi.lobe.rightLower',
                        label: this.__('forms.dsi.lobe.rightLower'),
                        type: 'text',
                        class: commonClasses.right,
                    },
                    {
                        name: 'dsi.lobe.leftUpper',
                        label: this.__('forms.dsi.lobe.leftUpper'),
                        type: 'text',
                        class: commonClasses.left,
                    },
                    {
                        name: 'dsi.lobe.leftLower',
                        label: this.__('forms.dsi.lobe.leftLower'),
                        type: 'text',
                        class: commonClasses.right,
                    },
                ]

                schema.secondRow = secondRow
            } else {
                schema.secondRow = []
            }

            return schema
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
