import flatten from 'flat'
import { toJson } from 'helpers/common'

export default function(val, oldVal) {
    if (val) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
            this.form = {
                ...flatten(toJson(val), {
                    safe: true,
                }),
            }
        }
    }
}
