var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-2 border-0 rounded-full leading-none text-xs uppercase tracking-wider",class:[
        { 'text-red-600 bg-red-200': _vm.color === 'red' },
        { 'text-orange-600 bg-orange-200': _vm.color === 'orange' },
        { 'text-green-600 bg-green-200': _vm.color === 'green' },
        { 'text-gray-600 bg-white bg-opacity-75 ': _vm.color === 'white' },
        {
            'text-gray-600 bg-gray-300':
                _vm.color === 'gray' || _vm.color === 'info',
        } ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }