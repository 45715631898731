<template>
    <div>
        <headline>{{ __('forms.chapters.visit1') }}</headline>
        <chapter-picker
            :chapters="chapters"
            :active-chapter-index="activeChapterIndex"
            :on-click="handlePickerClick"
        />
        <headline>{{ chapters[activeChapterIndex].name }}</headline>
        <component :is="renderChapter" />
    </div>
</template>

<script>
export default {
    name: 'Visit1Bro',
    data() {
        return {
            chapters: [
                {
                    name: this.__('forms.chapters.anthropometrics'),
                    component: 'anthropometrics-shared',
                },
                {
                    name: this.__('forms.chapters.vitalSigns'),
                    component: 'vital-signs-shared',
                },
                {
                    name: this.__('forms.chapters.eatingDrinkingHabits'),
                    component: 'eating-drinking-shared',
                },
                {
                    name: this.__('forms.chapters.smokingHistory'),
                    component: 'smoking-history-shared',
                },
                {
                    name: this.__('forms.chapters.morbidities'),
                    component: 'morbidities-shared',
                },
                {
                    name: this.__('forms.chapters.medication'),
                    component: 'medication-shared',
                },
                {
                    name: this.__('forms.chapters.questionnaire'),
                    component: 'questionnaire-bro',
                },
                {
                    name: this.__('forms.chapters.lungFunction'),
                    component: 'lung-function-shared',
                },
                {
                    name: this.__(
                        'forms.chapters.osaSpecificsRespiratoryPolygraphy'
                    ),
                    component: 'osa-specifics-bro',
                },
                {
                    name: this.__('forms.chapters.breathAnalysis'),
                    component: 'breath-analysis-shared',
                },
                {
                    name: this.__('forms.chapters.visit1Completion'),
                    component: 'visit-1-finish-shared',
                },
            ],
        }
    },
    computed: {
        renderChapter() {
            const { chapter } = this.$route.params
            if (!chapter) {
                return this.chapters[0].component
            }
            if (this.chapters[chapter]) {
                return this.chapters[chapter].component
            }
            console.error('Visit1.vue problem with chapter naming')
            return this.chapters[0].component
        },
        activeChapterIndex() {
            const { chapter } = this.$route.params
            return chapter || '0'
        },
    },
    methods: {
        handlePickerClick(e) {
            const { index } = e.currentTarget.dataset
            if (!index) {
                console.error(
                    'Visit1.vue error: missing `index` from `handlePickerClick`'
                )
                return
            }
            this.$router.push({
                name: 'Visit',
                params: {
                    chapter: index,
                },
            })
        },
    },
}
</script>
