import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { fb } from 'helpers/firebase'
import registerComponents from './registerComponents.js'
import VueToast from 'vue-toast-notification'
import VTooltip from 'v-tooltip'
import VueScrollReveal from 'vue-scroll-reveal'
import VueCoreVideoPlayer from 'vue-core-video-player'
import translate from 'plugins/translate'

import './assets/sass/style.scss'

Vue.config.productionTip = false

Vue.use(VueCoreVideoPlayer)
Vue.use(VueToast)
Vue.use(VueScrollReveal, {
    scale: 0.9,
    duration: 500,
    distance: '10px',
})
Vue.use(VTooltip)
Vue.use(translate)

registerComponents(Vue)

if (module.hot) {
    module.hot.addStatusHandler(() => {
        location.reload()
    })
}

// The Vue App Instance
// It's a variable since we will integrate it with firebase's authChanged listener
window.VueApp = null
// Before we mount the app, we have to register the Event Listener
// for any future Auth-Changes
fb.auth().onAuthStateChanged(user => {
    store.dispatch('auth/authStateChanged', user)
    if (!window.VueApp) {
        window.VueApp = new Vue({
            router,
            store,
            render: h => h(App),
        })
        // to have vue toast globally available in actions
        // https://github.com/vuejs/vuex/issues/1645#issuecomment-566220583
        store.$app = window.VueApp
        window.VueApp.$mount('#app')
    }
})
