/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import { db, firebase, functions, analytics } from 'helpers/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import { formsStatus, emptyUpload } from 'helpers/constants'

const patientEmpty = null
const formsEmpty = null
const projectsCollection = db.collection('projects')

const mutations = {
    UPDATE_PATIENT: 'UPDATE_PATIENT',
    UPDATE_FORMS: 'UPDATE_FORMS',
    SET_LOADING: 'SET_LOADING',
    SET_LOADING_FORMS: 'SET_LOADING_FORMS',
    SET_FORMS_SUBSCRIBER: 'SET_FORMS_SUBSCRIBER',
    SET_PATIENT_SUBSCRIBER: 'SET_PATIENT_SUBSCRIBER',
    SET_PATIENT_COLLECTION: 'SET_PATIENT_COLLECTION',
    SET_FORMS_COLLECTION: 'SET_FORMS_COLLECTION',
    SET_APP: 'SET_APP',
    START_LISTENER: 'START_LISTENER',
    DESTROY_LISTENER: 'DESTROY_LISTENER',
    INIT_UPLOAD: 'INIT_UPLOAD',
    UPDATE_UPLOAD_PROGRESS: 'UPDATE_UPLOAD_PROGRESS',
    SET_UPLOAD_SUCCESSFUL: 'SET_UPLOAD_SUCCESSFUL',
}

const patient = {
    namespaced: true,
    state: {
        is: {
            loading: true,
            loadingForms: true,
        },
        patient: patientEmpty,
        patientCollection: null,
        patientSubscriber: null,
        forms: formsEmpty,
        formsCollection: null,
        formsSubscriber: null,
        upload: {},
    },
    mutations: {
        [mutations.UPDATE_PATIENT](state, patient) {
            state.patient = patient
        },
        [mutations.UPDATE_FORMS](state, forms) {
            state.forms = forms
        },
        [mutations.SET_LOADING](state, loading) {
            state.is.loading = loading
        },
        [mutations.SET_LOADING_FORMS](state, loading) {
            state.is.loadingForms = loading
        },
        [mutations.SET_PATIENT_SUBSCRIBER](state, subscriber) {
            state.patientSubscriber = subscriber
        },
        [mutations.SET_FORMS_SUBSCRIBER](state, subscriber) {
            state.formsSubscriber = subscriber
        },
        [mutations.SET_PATIENT_COLLECTION](state, collection) {
            state.patientCollection = collection
        },
        [mutations.SET_FORMS_COLLECTION](state, collection) {
            state.formsCollection = collection
        },
        // Feature: Data Upload
        [mutations.INIT_UPLOAD](state, payload) {
            state.upload = payload
        },
        [mutations.UPDATE_UPLOAD_PROGRESS](state, payload) {
            state.upload[payload.iontype][payload.filetype] =
                payload.uploadProgress
        },

        [mutations.SET_UPLOAD_SUCCESSFUL](state, payload) {
            state.upload[payload.iontype][payload.filetype].completed = true
        },
    },
    actions: {
        init({ commit, rootGetters, dispatch }, patientId) {
            return new Promise((resolve, reject) => {
                const activeProject = rootGetters['projects/activeProject']

                if (activeProject === null) {
                    console.error(
                        `store patient: init error: projectSlug empty`
                    )
                    return reject()
                }

                commit(mutations.SET_LOADING, true)
                const checkIn = functions.httpsCallable('checkIn')
                checkIn({ patientId })
                    .then(result => {
                        commit(mutations.UPDATE_PATIENT, result.data)
                        dispatch('formsSubscribe')
                        dispatch('patientSubscribe')
                        commit(mutations.SET_LOADING, false)
                        analytics.logEvent('patient_check_in', patientId)
                        resolve()
                    })
                    .catch(e => {
                        console.log('store patient: init error:', e)
                        reject()
                    })
            })
        },
        updatePatient({ commit }, patient) {
            commit(mutations.UPDATE_PATIENT, patient)
            analytics.logEvent('patient_updated')
        },
        resetPatient({ commit, dispatch }) {
            dispatch('patientUnsubscribe')
            dispatch('formsUnsubscribe')
            commit(mutations.UPDATE_PATIENT, patientEmpty)
            commit(mutations.UPDATE_FORMS, formsEmpty)
        },
        patientSubscribe({ commit, rootGetters, state }) {
            const activeProject = rootGetters['projects/activeProject']
            const { patientId } = state.patient

            const collection = projectsCollection
                .doc(activeProject.slug)
                .collection('patients')
                .doc(patientId)

            const subscribe = collection.onSnapshot(snapshot => {
                if (snapshot.exists) {
                    commit(mutations.UPDATE_PATIENT, snapshot.data())
                    commit(mutations.SET_LOADING, false)
                }
            })

            commit(mutations.SET_PATIENT_SUBSCRIBER, subscribe)
            commit(mutations.SET_PATIENT_COLLECTION, collection)
        },
        patientUnsubscribe({ commit, state }) {
            if (state.patientSubscriber !== null) {
                state.patientSubscriber()
            }
            commit(mutations.SET_PATIENT_SUBSCRIBER, null)
        },
        formsSubscribe({ commit, rootGetters, state }) {
            const activeProject = rootGetters['projects/activeProject']
            const { patientId } = state.patient

            const collection = projectsCollection
                .doc(activeProject.slug)
                .collection('patients')
                .doc(patientId)
                .collection('forms')

            const subscribe = collection.onSnapshot(snapshot => {
                const forms = {}
                snapshot.forEach(doc => {
                    forms[doc.id] = {
                        docId: doc.id,
                        ...doc.data(),
                    }
                })

                commit(mutations.UPDATE_FORMS, forms)
                commit(mutations.SET_LOADING_FORMS, false)
            })
            commit(mutations.SET_FORMS_SUBSCRIBER, subscribe)
            commit(mutations.SET_FORMS_COLLECTION, collection)
        },
        formsUnsubscribe({ commit, state }) {
            if (state.formsSubscriber !== null) {
                state.formsSubscriber()
            }
            commit(mutations.SET_FORMS_SUBSCRIBER, null)
        },
        setStudycomplete({ state, dispatch }, completed) {
            const { patientCollection, formsCollection } = state
            const { patientId } = state.patient

            const batch = db.batch()

            batch.update(patientCollection, { studyCompleted: completed })

            formsCollection.get().then(collection => {
                collection.forEach(form => {
                    const doc = formsCollection.doc(form.id)
                    batch.update(doc, {
                        status: completed
                            ? formsStatus.finished
                            : formsStatus.inProgress,
                    })
                })

                const msg = {
                    type: 'success',
                    message: this.$app.__('patient.StudyDataCompletedPopup'),
                }

                batch
                    .commit()
                    .catch(e => {
                        msg.type = 'error'
                        msg.message = this.$app.__(
                            'patient.StudyDataCompletedPopupError'
                        )
                        console.error(
                            `store patient: setStudycomplete error: projectSlug empty`,
                            e
                        )
                    })
                    .finally(dispatch('ui/showToast', msg, { root: true }))

                analytics.logEvent('study_completed', patientId)
            })
        },

        enterForm({ state }, formId) {
            const { forms, formsCollection } = state
            return new Promise((resolve, reject) => {
                if (forms[formId].status === formsStatus.notStarted) {
                    formsCollection
                        .doc(formId)
                        .update({ status: formsStatus.inProgress })
                        .then(() => resolve())
                        .catch(e => {
                            console.error(`store patient: enterForm error`, e)
                            reject(e)
                        })
                } else {
                    resolve()
                }
            })
        },

        /**
         * Upload measurement files to cloud
         * @description: Two files (pos, neg) can be uploaded per visit.
         */
        uploadFile({ commit, state, dispatch, rootGetters }, payload) {
            const activeProject = rootGetters['projects/activeProject']
            const { patientId } = state.patient

            const { visit, iontype, filetype, file } = payload
            const validIonTypes = ['pos', 'neg']
            // Check Ion Type first
            if (!iontype && !validIonTypes.includes(iontype)) {
                throw new Error('ION TYPE IS MALICIOUS. UPLOAD DENIED.')
            }

            const validFileTypes = ['raw', 'co2']
            // Check File Type
            if (!iontype && !validFileTypes.includes(filetype)) {
                throw new Error('File TYPE IS MALICIOUS. UPLOAD DENIED.')
            }

            // Prepare task-object, filename and folder according to definition in Architecture Doc
            let uploadTask = {}
            let fileExtension = 'raw'

            // CO2-Files have specific file extension
            if (filetype === 'co2') {
                fileExtension = 'txt'
            }

            // Example filename: visit_1_pos.raw
            const filename = `visit_${visit}_${iontype}.${fileExtension}`

            // Storage Objects can contain metadata
            // Docs: https://firebase.google.com/docs/storage/web/file-metadata
            let metadata = {
                orginalFilename: file.name,
            }
            // Upload File to Firebase:
            // Example: /bro/CH-BRO-001/visit_1_pos.raw
            uploadTask = firebase
                .storage()
                .ref(`${activeProject.slug}/${patientId}/${filename}`)
                .put(file, metadata)

            // Now observe the progress by registering a listener
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                'state_changed', // Firebase Storage State Change
                ({ bytesTransferred, totalBytes, state }) => {
                    const uploadProgress = {
                        error: false,
                        completed: false,
                        status: state,
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        progress: Math.round(
                            (bytesTransferred / totalBytes) * 100
                        ),
                    }
                    const payload = {
                        iontype,
                        filetype,
                        uploadProgress,
                    }

                    commit(mutations.UPDATE_UPLOAD_PROGRESS, payload)
                },
                error => {
                    // ERROR
                    const payload = {
                        iontype,
                        filetype,
                        uploadProgress: {
                            error: true,
                            status: error.code,
                            completed: true,
                            progress: 0,
                        },
                    }

                    commit(mutations.UPDATE_UPLOAD_PROGRESS, payload)
                },
                () => {
                    // SUCCESS
                    // Get the URL and save it to the active document
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        // The Files-URLs are added to files-array on the form collection in Firebase
                        dispatch('addFileUrl', {
                            url,
                            visit,
                            iontype,
                            filetype,
                        })
                    })
                    // Update the Store
                    commit(mutations.SET_UPLOAD_SUCCESSFUL, {
                        iontype,
                        filetype,
                    })
                }
            )
        },

        /**
         * addFilesUrl
         *
         * @description: Sets the http-link to the files object (e.g. `files.pos`) in {formId}-Collection in Firestore.
         *               Also changes the state to `finished` if both files are present.
         * @return Promise
         *
         */
        addFileUrl({ state }, payload) {
            const { forms, formsCollection } = state
            const { iontype, filetype, url, visit } = payload
            const formId = `visit${visit}-data`
            const form = forms[formId]

            // Add the link to the file to the form
            _.set(form, `files.${iontype}.${filetype}`, url)

            // Set Form to finished, if both links are saved
            if (_.has(form, 'files.pos.raw') && _.has(form, 'files.neg.raw')) {
                // SET FORM COMPLETED
                form.status = formsStatus.finished
            }

            return new Promise((resolve, reject) => {
                formsCollection
                    .doc(formId)
                    .update({ ...form })
                    .then(() => resolve())
                    .catch(e => {
                        console.error(`store patient: enterForm error`, e)
                        reject(e)
                    })
            })
        },
        /**
         * resetUpload
         *
         * @description: Resets the local upload in case of errors
         * @return VOID
         *
         */
        resetUpload({ commit }) {
            let payload = emptyUpload()

            commit(mutations.INIT_UPLOAD, payload)
        },

        updateForm({ state }, form) {
            const { formsCollection } = state
            return new Promise((resolve, reject) => {
                if (!form.docId) {
                    const error = `store patient: updateForm error: lack of docId in form`
                    console.error(error)
                    return reject(error)
                }

                form.updatedAt = firebase.firestore.FieldValue.serverTimestamp()

                const notNeeded = [
                    'createdAt',
                    'createdAt.nanoseconds',
                    'createdAt.seconds',
                    'title',
                    'subtitle',
                ]

                notNeeded.forEach(node => {
                    delete form[node]
                })

                const { docId, ...rest } = form
                formsCollection
                    .doc(docId)
                    .update(rest)
                    .then(() => resolve())
                    .catch(e => reject(e))
            })
        },
    },
    getters: {
        patient: ({ patient }) => patient,
        loadingPatient: ({ is }) => is.loading,
        patientForms: ({ forms }) => forms,
        loadingForms: ({ is }) => is.loadingForms,

        hasFilebyVisit: state => (visit, iontype, filetype) =>
            _.has(
                state.forms,
                `visit${visit}-data.files.${iontype}.${filetype}`
            ),
        getFileByVisit: state => (visit, iontype, filetype) =>
            _.get(
                state.forms,
                `visit${visit}-data.files.${iontype}.${filetype}`
            ),
    },
}

listAllActionsOfStore(patient, 'patient')
listAllGettersOfStore(patient, 'patient')

export default patient
