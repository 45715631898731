<template>
    <default-app-template>
        <div
            class="md:mx-auto md:w-3/4 w-full flex flex-col"
            data-cypress="exportData"
        >
            <div class="flex justify-center">
                <headline tag="h1">{{ __('exportData') }}</headline>
            </div>
            <div class="flex justify-center my-8">
                {{ __('export.description') }}
            </div>
            <div class="sm:pb-10">
                <div class="my-2 mx-2 md:mx-10 lg:mx-24 xl:mx-48 px-4">
                    <formulate-form
                        v-if="showProjectSelect"
                        v-model="form"
                        error-behavior="live"
                        @validation="validation = $event"
                        @submit="submit"
                    >
                        <formulate-input
                            name="project"
                            type="select"
                            class="w-full"
                            :label="__('project')"
                            :options="projectsSelect"
                        />
                        <div class="mt-8 flex justify-end">
                            <formulate-input
                                type="submit"
                                :label="__('export')"
                                :disabled="form.project === ''"
                                data-cypress="exportData.button.export"
                            />
                        </div>
                    </formulate-form>
                    <div
                        v-if="loading === true"
                        class="mt-8 flex justify-center"
                    >
                        <loading />
                    </div>

                    <div v-if="downloadLink !== ''">
                        <basic-alert
                            :bold-text="__('export.ready')"
                            :is-closable="false"
                        >
                            {{ __('export.readyDescription') }}
                            <br />
                            {{ __('export.24h') }}
                        </basic-alert>
                        <div class="mt-8 flex justify-end">
                            <a
                                class="simple-button no-underline uppercase"
                                :href="downloadLink"
                                download
                            >
                                {{ __('download') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-app-template>
</template>

<script>
import { functions, storage } from 'helpers/firebase'
import Loading from '../atoms/Loading.vue'
/**
 * @group MOLECULES
 * Checkin form to search for a patient based on PatientID and check-in
 */
export default {
    components: { Loading },
    data() {
        return {
            downloadLink: '',
            loading: false,
            showProjectSelect: true,
            form: {},
            validation: {},
        }
    },
    computed: {
        projectsSelect() {
            const projects = {
                '': '',
            }

            this.projects.forEach(node => {
                projects[node.slug] = node.title
            })

            return projects
        },
        ...vuex.mapGetters({
            isLoading: 'projects/isLoading',
            projects: 'projects/projects',
        }),
    },
    mounted() {
        this.projectInit()
    },
    methods: {
        submit() {
            this.loading = true
            this.showProjectSelect = false
            const exportData = functions.httpsCallable('exportData')

            exportData({ projectSlug: this.form.project })
                .then(result => {
                    const { data } = result
                    const file = storage.ref(data)

                    file.getDownloadURL()
                        .then(url => {
                            this.downloadLink = url
                            this.loading = false
                        })
                        .catch(e => {
                            this.showProjectSelect = true
                            this.loading = false
                            this.downloadLink = ''

                            console.error('ExportData.vue error:', e)
                        })
                })
                .catch(e => {
                    console.error('ExportData.vue error:', e)
                    this.showToast({
                        type: 'error',
                        message: this.__('export.error'),
                    })
                })
        },
        ...vuex.mapActions({
            projectInit: 'projects/init',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
