<template>
    <div>
        <headline>{{ __('forms.chapters.visit2') }}</headline>
        <chapter-picker
            :chapters="chapters"
            :active-chapter-index="activeChapterIndex"
            :on-click="handlePickerClick"
        />
        <headline>{{
            chapters[activeChapterIndex].title ||
                chapters[activeChapterIndex].name
        }}</headline>
        <component :is="renderChapter" />
    </div>
</template>

<script>
export default {
    name: 'Visit2Trx',
    data() {
        return {
            chapters: [
                {
                    name: this.__('forms.chapters.anthropometrics'),
                    component: 'anthropometrics-shared-v2',
                },
                {
                    name: this.__('forms.chapters.vitalSigns'),
                    component: 'vital-signs-shared-v2',
                },
                {
                    name: this.__('forms.chapters.eatingDrinkingHabits'),
                    title: this.__('forms.chapters.eatingDrinkingHabits.of24h'),
                    component: 'eating-drinking-shared-v2',
                },
                {
                    name: this.__('forms.chapters.smokingHistory'),
                    title: this.__('forms.smoking.ofVisit1'),
                    component: 'smoking-history-shared-v2',
                },
                {
                    name: this.__('forms.chapters.morbidities'),
                    title: this.__('forms.morbidity.ofVisit1'),
                    component: 'morbidities-shared-v2',
                },
                {
                    name: this.__('forms.chapters.medication'),
                    title: this.__('forms.medication.ofVisit1'),
                    component: 'medication-shared-v2',
                },
                {
                    name: this.__('forms.chapters.trxSpecifics'),
                    component: 'trx-specifics-v2',
                },
                {
                    name: this.__('forms.chapters.laboratory'),
                    component: 'laboratory-trx-v2',
                },
                {
                    name: this.__('forms.chapters.CopdSpecificQuestionnaires'),
                    component: 'copd-trx-v2',
                },
                {
                    name: this.__('forms.chapters.lungFunction'),
                    component: 'lung-function-shared-v2',
                },
                {
                    name: this.__('forms.chapters.breathAnalysis'),
                    component: 'breath-analysis-shared-v2',
                },
                {
                    name: this.__('forms.chapters.visit2Completion'),
                    component: 'visit2-finish-shared',
                },
            ],
        }
    },
    computed: {
        renderChapter() {
            const { chapter } = this.$route.params
            if (!chapter) {
                return this.chapters[0].component
            }
            if (this.chapters[chapter]) {
                return this.chapters[chapter].component
            }
            console.error('Visit1.vue problem with chapter naming')
            return this.chapters[0].component
        },
        activeChapterIndex() {
            const { chapter } = this.$route.params
            return chapter || '0'
        },
    },
    methods: {
        handlePickerClick(e) {
            const { index } = e.currentTarget.dataset
            if (!index) {
                console.error(
                    'Visit1.vue error: missing `index` from `handlePickerClick`'
                )
                return
            }
            this.$router.push({
                name: 'Visit',
                params: {
                    chapter: index,
                },
            })
        },
    },
}
</script>
