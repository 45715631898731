<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col">
                <div class="flex">
                    <headline tag="h1">{{
                        __('forms.title.screening')
                    }}</headline>
                </div>
                <div class="flex">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.diagnosisDate"
                    />
                </div>
            </div>
            <div class="flex">
                <div class="flex flex-col flex-1">
                    <div class="flex">
                        <headline tag="h3">{{
                            __('forms.title.inclusionCriteria')
                        }}</headline>
                    </div>
                    <div class="flex flex-col">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.inclusions"
                        />
                    </div>
                </div>
                <div class="flex flex-col flex-1">
                    <div class="flex">
                        <headline tag="h3">{{
                            __('forms.title.exclusionCriteria')
                        }}</headline>
                    </div>
                    <div>
                        <formulate-schema
                            v-model="form"
                            :schema="schema.exclusions"
                        />
                    </div>
                </div>
            </div>
            <div>
                <headline tag="h3">{{
                    __('forms.title.informedConsent')
                }}</headline>
            </div>
            <div class="flex items-center">
                <div class="flex flex-col flex-1">
                    <div class="flex">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.informedConstent"
                        />
                    </div>
                </div>
                <div class="flex flex-1">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.informedConstentDate"
                    />
                </div>
            </div>
            <div class="flex">
                <div class="flex flex-grow flex-col">
                    <div>
                        <headline tag="h3">{{
                            __('forms.title.studyInclusion')
                        }}</headline>
                    </div>
                    <div class="flex">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.studyInclusion"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import { formsStatus } from 'helpers/constants'

export default {
    name: 'ScreeningTrx',
    data() {
        return {
            form: {},
            validation: {},
            schema: {
                diagnosisDate: [
                    {
                        name: 'screeningDate',
                        label: '',
                        type: 'date',
                        class: 'w-2/5',
                        required: true,
                    },
                ],
                inclusions: [
                    {
                        name: 'inclusions.informedConsent',
                        label: this.__(
                            'forms.screening.inclusions.informedConsent'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.over18YearsOld',
                        label: this.__(
                            'forms.screening.inclusions.over18YearsOld'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.goldStage2-4',
                        label: this.__(
                            'forms.screening.inclusions.goldStage2-4'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.copdHospitalization',
                        label: this.__(
                            'forms.screening.inclusions.copdHospitalization'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.goldGuidelines',
                        label: this.__(
                            'forms.screening.inclusions.goldGuidelines'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.tripleInhalationalTherapy',
                        label: this.__(
                            'forms.screening.inclusions.tripleInhalationalTherapy'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.suitableFollowup',
                        label: this.__(
                            'forms.screening.inclusions.suitableFollowup'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'inclusions.lessThan48hInitiation',
                        label: this.__(
                            'forms.screening.inclusions.lessThan48hInitiation'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                ],
                exclusions: [
                    {
                        name: 'exclusions.physicalIntelectualImpairment',
                        label: this.__(
                            'forms.screening.exclusions.physicalIntelectualImpairment'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'exclusions.pregnancy',
                        label: this.__('forms.screening.exclusions.pregnancy'),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'exclusions.uncotrolledDiabetes',
                        label: this.__(
                            'forms.screening.exclusions.uncotrolledDiabetes'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'exclusions.congenitalDefects',
                        label: this.__(
                            'forms.screening.exclusions.congenitalDefects'
                        ),
                        type: 'checkbox',
                        checked: false,
                        class: 'formulate-input-wrapper--start',
                    },
                    {
                        name: 'exclusions.acuteOtherThanCopd',
                        label: this.__(
                            'forms.screening.exclusions.acuteOtherThanCopd'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                    {
                        name: 'exclusions.renalFailureOrReplacement',
                        label: this.__(
                            'forms.screening.exclusions.renalFailureOrReplacement'
                        ),
                        type: 'checkbox',
                        checked: false,
                    },
                ],
                informedConstent: [
                    {
                        name: 'informedConsent.checked',
                        label: this.__('forms.screening.informedConsent'),
                        type: 'checkbox',
                        checked: false,
                    },
                ],
                informedConstentDate: [
                    {
                        name: 'informedConsent.date',
                        label: '',
                        type: 'date',
                        class: 'w-full',
                    },
                ],
                studyInclusion: [
                    {
                        name: 'studyInclusion',
                        label: '',
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                        class: 'w-full md:w-1/2',
                    },
                ],
            },
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.screening': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            const toast = {
                type: 'success',
                message: this.__('toast.saveSuccessful'),
            }

            this.updateForm({ ...this.form, status: formsStatus.finished })
                .then(() => {
                    this.$router.push({
                        name: 'Dashboard',
                    })
                })
                .catch(() => {
                    toast.type = 'error'
                    toast.message = this.__('toast.saveProblem')
                })
                .finally(() => {
                    this.showToast(toast)
                })
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
