<template>
    <default-app-template>
        <component :is="visitComponent" />
    </default-app-template>
</template>

<script>
export default {
    computed: {
        visitComponent() {
            const {
                projectSlug,
                visitNumber,
            } = this.$router.history.current.params

            let individualVisit = null
            switch (projectSlug) {
                case 'bex':
                    individualVisit = `visit-${projectSlug}`
                    break

                default:
                    individualVisit = `visit-${visitNumber}-${projectSlug}`
                    break
            }

            return (
                window.componentsList[individualVisit] ||
                `visit-${visitNumber}-shared`
            )
        },
    },
}
</script>
