<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.leftColumn"
                    />
                </div>
                <div class="flex flex-col flex-1 self-center pl-8">
                    <div class="my-4 h-10">
                        {{ __('forms.age') }}: {{ age }} {{ __('forms.years') }}
                    </div>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.rightColumn"
                    />
                    <div class="my-4 h-10">
                        {{ __('forms.bmi') }}: {{ bmi }}
                    </div>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import copySomeFromVisit1 from 'helpers/watchers/copySomeFromVisit1'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'AnthropometricsSharedV2',
    data() {
        return {
            form: {},
            validation: {},
            schema: {
                leftColumn: [
                    {
                        name: 'dateOfVisit',
                        label: this.__('forms.dov'),
                        type: 'date',
                    },
                    {
                        name: 'dateOfBirth',
                        label: this.__('forms.dob'),
                        type: 'date',
                    },
                    {
                        name: 'weight',
                        label: this.__('forms.weightKg'),
                        placeholder: this.__('forms.weightKg'),
                        type: 'text',
                        validation: 'isNumber',
                    },
                ],
                rightColumn: [
                    {
                        name: 'height',
                        label: this.__('forms.heightCm'),
                        placeholder: this.__('forms.heightCm'),
                        type: 'text',
                        validation: 'isNumber',
                    },
                ],
            },
        }
    },
    computed: {
        age() {
            if (this.form.dateOfBirth) {
                const birthday = new Date(this.form.dateOfBirth)
                const ageDifMs = Date.now() - birthday.getTime()
                const ageDate = new Date(ageDifMs) // miliseconds from epoch
                return Math.abs(ageDate.getUTCFullYear() - 1970)
            }
            return '--'
        },
        bmi() {
            const { weight, height } = this.form
            if (isNaN(weight) || isNaN(height)) {
                return '--'
            }
            return (weight / (((height / 100) * height) / 100)).toFixed(2)
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        forms: {
            handler: function(newVal) {
                return copySomeFromVisit1(newVal, this, [
                    'height',
                    'weight',
                    'dateOfBirth',
                ])
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
