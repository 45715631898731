<template>
    <div>
        <formulate-form
            v-model="computedForm"
            class="checkin-form"
            @validation="validation = $event"
            @submit="submit"
        >
            <formulate-input
                v-model="computedForm.patientId"
                tabindex="1"
                type="autocomplete"
                label="Patient ID"
                autocomplete="no"
                name="patientId"
                :placeholder="__('patient.searchId')"
                bail
                allow-1password="no"
                validation="bail|required|max:13|min:13|validPatientId"
                :validation-messages="{
                    validPatientId: __('patient.notValidId'),
                }"
                data-cypress="patient.checkIn.input"
                @enter="submit()"
            />
            <div v-if="!validation.hasErrors" class="text-green-600 font-bold">
                Selected Patient: {{ form.patientId }}
            </div>

            <div class="flex justify-end">
                <simple-button
                    type="submit"
                    tabindex="2"
                    class-names="uppercase"
                    :disabled="validation.hasErrors || false"
                    :loading="loading"
                    data-cypress="patient.checkIn.submit"
                >
                    {{ __('checkIn') }}
                </simple-button>
            </div>
        </formulate-form>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * Checkin form to search for a patient based on PatientID and check-in
 */
export default {
    data() {
        return { form: { patientId: null }, validation: {}, loading: false }
    },
    computed: {
        computedForm: {
            get: function() {
                return this.form
            },
            set: function(val) {
                const { patientId } = val
                if (patientId) {
                    this.form = { patientId: patientId.toUpperCase() }
                }
            },
        },
        ...vuex.mapGetters({
            activeProject: 'projects/activeProject',
        }),
    },
    mounted() {
        const { formPrefill } = this.activeProject
        this.form.patientId = formPrefill
    },
    methods: {
        submit() {
            this.loading = true
            const patientId = this.form.patientId
            this.initPatient(patientId)
                .then(() => {
                    this.$router.push({
                        name: 'Dashboard',
                        params: { patientId },
                    })
                })
                .catch(() => {
                    this.showToast({
                        type: 'error',
                        message: this.__('error.noPatientFound', [patientId]),
                    })
                })
        },
        ...vuex.mapActions({
            initPatient: 'patient/init',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
