<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <headline tag="h4">
                <div
                    v-if="
                        getFormsObjectField('copiedMorbidities') ||
                            getFormsObjectField('copiedComorbidities')
                    "
                    id="copiedInfoText"
                    data-cypress="copied-info-text"
                    class="flex px-4 pb-2 text-gray-600 text-sm"
                >
                    <form-info-icon class="mt-1" />
                    {{ __('forms.morbidity.morbidityCopiedInfo') }}
                </div>
            </headline>

            <div class="flex flex-col flex-1">
                <formulate-input
                    type="group"
                    name="morbidities"
                    validation-name="morbidities"
                    validation=""
                    error-behavior="live"
                >
                    <template v-slot:default="groupProps">
                        <div
                            class="w-full grid grid-cols-2 gap-x-12 md:gap-x-24"
                        >
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity')"
                                :group-index="groupProps.index"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                                :disabled="
                                    getFormsObjectField(
                                        'morbidity',
                                        'morbidities'
                                    )
                                "
                            />
                            <formulate-input
                                v-if="showStoppedDate"
                                name="stoppedDate"
                                :label="__('forms.morbidity.morbidityStopped')"
                                type="date"
                                placeholder="date"
                                :validation="'before:' + todaysDate"
                                :validation-messages="{
                                    before: __('error.invalidDate.beforeToday'),
                                }"
                            />
                        </div>
                        <div
                            v-if="
                                getFieldFromCurrentForm(
                                    'stoppedDate',
                                    'morbidities'
                                )
                            "
                            class="w-full md:w-1/2"
                        >
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity.addNewMorbidity')"
                                :help="__('forms.morbidity.help.leaveBlank')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                            />
                        </div>
                    </template>
                </formulate-input>

                <div class="w-full md:w-1/2">
                    <formulate-schema
                        v-if="getFormsObjectField('morbidity', 'morbidities')"
                        v-model="form"
                        :schema="changeInMorbiditySchema"
                    />
                    <headline tag="h4">
                        {{ __('forms.comorbidity.comorbidities') }}
                    </headline>
                    <div>
                        <formulate-input
                            type="group"
                            name="comorbidities"
                            :repeatable="true"
                            :add-label="__('forms.comorbidity.add')"
                            validation-name="comorbidities"
                            validation=""
                            error-behavior="live"
                        >
                            <template v-slot:default="groupProps">
                                <formulate-input
                                    name="comorbidity"
                                    type="icd"
                                    :group-index="groupProps.index"
                                    :label="__('forms.comorbidity')"
                                    :placeholder="__('forms.typeInICDCode')"
                                    data-cypress="form.input.comorbidities"
                                />
                            </template>
                        </formulate-input>
                    </div>
                </div>
                <formulate-input
                    name="comments"
                    type="text"
                    :label="__('forms.morbidity.comments')"
                    :placeholder="__('forms.morbidity.comments')"
                    data-cypress="form.input.comment"
                    class=" w-full my-0"
                />
            </div>

            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'MorbiditiesXBex',
    mixins: [visitData],
    computed: {
        changeInMorbiditySchema() {
            const changeInMorbiditySchema = [
                {
                    name: 'changeInMorbidities',
                    label: this.__('forms.morbidity.change'),
                    type: 'select',
                    options: [
                        { value: this.__('no'), label: 'no', selected: true },
                        { value: this.__('yes'), label: 'yes' },
                    ],
                },
            ]
            return changeInMorbiditySchema
        },
        showStoppedDate() {
            const field = 'changeInMorbidities'
            if (this.form[field]) {
                return this.form[field] === 'yes'
            }
            return false
        },
        todaysDate() {
            return new Date()
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
    },
}
</script>
