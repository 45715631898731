<template>
    <default-app-template>
        <div class="md:mx-auto md:w-3/4 w-full" data-cypress="patient.checkIn">
            <div class="home__box sm:pb-10">
                <div class="home__title" data-cypress="patient.checkIn.title">
                    {{ __('home.patientCheckIn') }}
                </div>
                <div class="my-2 mx-2 md:mx-10 lg:mx-24 xl:mx-48 px-4">
                    <patient-id-checkin />
                </div>
            </div>
        </div>
    </default-app-template>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {}
    },
    methods: {
        toast: function() {
            this.showToast({
                type: 'success',
                message: 'toast is working',
            })
        },
        ...mapActions({
            showToast: 'ui/showToast',
        }),
    },
}
</script>
<style lang="scss" scoped>
.home {
    &__box {
        @apply flex flex-col justify-center shadow-lg border-2 rounded-lg border-gray-300 pb-4 mx-2 min-h-32;
    }

    &__title {
        @apply text-2xl font-bold text-center my-6 text-secondary-700 mb-10;
    }
}

.tab {
    @apply w-1/2 text-secondary-700 p-4 text-center no-underline bg-gray-200;
}

.tab:hover {
    cursor: pointer;
}

.tab.active {
    @apply bg-white font-bold;
}
</style>
