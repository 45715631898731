import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import FormulateAutocomplete from 'plugins/forms/FormulateAutocomplete'
import FormulateICDAutocomplete from 'plugins/forms/FormulateICDAutocomplete'
import FormulateDrugAutocomplete from 'plugins/forms/FormulateDrugAutocomplete'

import {
    validPatientId,
    isNumber,
    isBloodPressure,
} from 'plugins/forms/validationRules'

Vue.component('FormulateAutocomplete', FormulateAutocomplete)
Vue.component('FormulateICDAutocomplete', FormulateICDAutocomplete)
Vue.component('FormulateDrugAutocomplete', FormulateDrugAutocomplete)

Vue.use(VueFormulate, {
    rules: {
        validPatientId: validPatientId,
        isNumber,
        isBloodPressure,
    },
    library: {
        autocomplete: {
            classification: 'text',
            component: 'FormulateAutocomplete',
        },
        icd: {
            classification: 'text',
            component: 'FormulateICDAutocomplete',
        },
        drug: {
            classification: 'text',
            component: 'FormulateDrugAutocomplete',
            slotProps: {
                // Prop to decide which key should be used to display data in the dropdown
                component: ['displayKey', 'groupIndex'],
            },
        },
    },
})
