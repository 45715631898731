/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import { db, analytics } from 'helpers/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import { sortProjects } from 'helpers/stores/projectsStore'

const listEmpty = []
const projectDefault = null
const projectsCollection = db.collection('projects')

const mutations = {
    UPDATE_LIST: 'UPDATE_LIST',
    SET_LOADING: 'SET_LOADING',
    SET_SUBSCRIBER: 'SET_SUBSCRIBER',
    SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
}

const projects = {
    namespaced: true,
    state: {
        is: {
            loading: true,
        },
        projects: listEmpty,
        activeProject: projectDefault,
        subscriber: null,
    },
    mutations: {
        [mutations.UPDATE_LIST](state, list) {
            state.projects = list
        },
        [mutations.SET_LOADING](state, status) {
            if (typeof status !== 'boolean') {
                throw new Error('Store projects: SET_LOADING type mismatch')
            }
            state.is.loading = status
        },
        [mutations.SET_SUBSCRIBER](state, subscriber) {
            state.subscriber = subscriber
        },
        [mutations.SET_ACTIVE_PROJECT](state, project) {
            state.activeProject = project
        },
    },
    actions: {
        init({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                projectsCollection
                    .get()
                    .then(result => {
                        let list = []
                        result.forEach(node => {
                            list.push(node.data())
                        })
                        list.sort(sortProjects)
                        commit(mutations.UPDATE_LIST, list)
                        commit(mutations.SET_LOADING, false)
                        dispatch('projectsSubscribe')
                        resolve()
                    })
                    .catch(err => {
                        console.log('project Store: init error', err)
                        reject()
                    })
            })
        },
        updateProjectList({ commit }, list) {
            commit(mutations.UPDATE_LIST, list)
        },
        resetProjectList({ commit }) {
            commit(mutations.UPDATE_LIST, listEmpty)
        },
        setActiveProject({ commit }, project) {
            return new Promise((resolve, reject) => {
                try {
                    commit(mutations.SET_ACTIVE_PROJECT, project)
                    analytics.logEvent('set_active_project', project)
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        },
        resetActiveProject({ commit }) {
            commit(mutations.SET_ACTIVE_PROJECT, projectDefault)
        },
        projectsSubscribe({ commit }) {
            const subscribe = projectsCollection.onSnapshot(result => {
                let list = []
                result.forEach(node => {
                    list.push(node.data())
                })
                list.sort(sortProjects)
                commit(mutations.UPDATE_LIST, list)
            })
            commit(mutations.SET_SUBSCRIBER, subscribe)
        },
        projectsUnsubscribe({ commit, state }) {
            state.subscriber()
            commit(mutations.SET_SUBSCRIBER, null)
        },
    },
    getters: {
        isLoading: ({ is }) => is.loading,
        projects: ({ projects }) => projects,
        activeProject: ({ activeProject }) => activeProject,
    },
}

listAllActionsOfStore(projects, 'projects')
listAllGettersOfStore(projects, 'projects')

export default projects
