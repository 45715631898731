<template>
    <div
        v-if="alertOpen"
        class="alert px-6 py-4 border-0 rounded relative mb-4  border-l-4"
        :class="style"
    >
        <span v-if="icon.length" class="text-l inline-block mr-5 align-middle">
            <font-awesome-icon :icon="['fas', icon]" />
        </span>
        <div class="text-sm align-middle mr-8">
            <b class="uppercase">{{ boldText }}&nbsp;</b>
        </div>
        <div class="text-sm align-middle mr-8">
            <slot />
        </div>
        <button
            v-if="isClosable"
            class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            @click="closeAlert()"
        >
            <span>×</span>
        </button>
    </div>
</template>

<script>
import gsap from 'gsap'
/**
 * @group ATOMS
 * Renders a simple animated alert  to inform the user
 */
export default {
    name: 'BasicAlert',
    inheritAttrs: true,
    props: {
        isClosable: {
            type: Boolean,
            default: true,
        },
        // Bold Text at the beginning of the alert message to catch attention. like "Heads up"
        boldText: {
            type: String,
            default: '',
        },
        // Add a Icon from Font-Awesome to the alert
        icon: {
            type: String,
            default: '',
        },
        // The type of the alert: success, warning, error, info
        type: {
            default: 'default',
            // `'success'` / `'warning'`/ `'error'`/ `'info'`
            type: String,
            validator: function(value) {
                // The value must match one of these strings
                return (
                    ['success', 'warning', 'error', 'info', 'default'].indexOf(
                        value
                    ) !== -1
                )
            },
        },
    },
    data() {
        return {
            alertOpen: true,
        }
    },
    computed: {
        style() {
            /* eslint-disable no-unreachable */
            return this.type
        },
    },

    mounted() {
        gsap.fromTo(
            '.alert',
            { y: -50, opacity: 0 },
            { y: 0, opacity: 1, ease: 'elastic', duration: 1, delay: 0.1 }
        )
    },
    methods: {
        closeAlert: function() {
            this.alertOpen = false
        },
    },
}
</script>

<style lang="scss" scoped>
.default {
    @apply text-gray-800 bg-gray-200 border-gray-800;
}

.error {
    @apply text-red-800 bg-red-200 border-red-800;
}

.success {
    @apply text-green-800 bg-green-200 border-green-800;
}

.info {
}

.warning {
    @apply text-yellow-800 bg-yellow-200 border-yellow-800;
}
</style>
