import { toJson } from 'helpers/common'
/**
 * common submit action for all forms in visits
 *
 * this method assumes, that `updateForm` from vuex actions was imported into component
 *
 * @param {object} $this - takes the `this` context of given part of form
 */
export default $this => {
    const toast = {
        type: 'success',
        message: $this.__('toast.saveSuccessful'),
    }

    const { visitNumber, chapter } = $this.$router.history.current.params
    const visitNumberInt = parseInt(visitNumber, 10)
    const chapterInt = chapter === undefined ? 0 : parseInt(chapter, 10)

    $this
        .updateForm(toJson($this.form))
        .then(() => {
            $this.$router.push({
                name: 'Visit',
                params: {
                    visitNumber: visitNumberInt,
                    chapter: chapterInt + 1,
                },
            })
        })
        .catch(e => {
            console.error(`${$this.constructor.name}`, e)
            toast.type = 'error'
            toast.message = $this.__('toast.saveProblem')
        })
        .finally(() => {
            $this.showToast(toast)
        })
}
