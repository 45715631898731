var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form.status)?_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",modifiers:{"reset":true}}],staticClass:"patientForm-card",class:{
        'patientForm-card--disabled': _vm.allFormsCompleted,
    },attrs:{"data-cypress":_vm.form.docId},on:{"click":_vm.handleClickInner}},[_c('div',{staticClass:"patientForm-card--body"},[_c('div',{staticClass:"patientForm-card--subtitle",class:{
                'patientForm-card--subtitle--disabled': _vm.allFormsCompleted,
            },attrs:{"data-cypress":"patientFormCard.subtitle"}},[_vm._v(" "+_vm._s(_vm.form.subtitle)+" ")]),_c('div',{staticClass:"patientForm-card--title",class:{
                'patientForm-card--title--disabled': _vm.allFormsCompleted,
            },attrs:{"data-cypress":"patientFormCard.title"}},[_vm._v(" "+_vm._s(_vm.form.title)+" ")])]),_c('div',{staticClass:"patientForm-card--footer"},[_c('div',{staticClass:"patientForm-card--status",class:{
                'patientForm-card--status--in-progress':
                    _vm.form.status === _vm.status.inProgress,
                'patientForm-card--status--finished':
                    _vm.form.status === _vm.status.finished,
                'patientForm-card--status--disabled': _vm.allFormsCompleted,
            },attrs:{"data-cypress":"patientForm-card.status","disabled":!_vm.form.active}},[_vm._v(" "+_vm._s(_vm.__(("patient.status." + (_vm.form.status))))+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }