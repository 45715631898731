var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('formulate-form',{attrs:{"error-behavior":"live"},on:{"validation":function($event){_vm.validation = $event},"submit":_vm.submit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('headline',{attrs:{"tag":"h4"}},[(
                    _vm.getFormsObjectField('copiedMorbidities') ||
                        _vm.getFormsObjectField('copiedComorbidities')
                )?_c('div',{staticClass:"flex px-4 pb-2 text-gray-600 text-sm",attrs:{"id":"copiedInfoText","data-cypress":"copied-info-text"}},[_c('form-info-icon',{staticClass:"mt-1"}),_vm._v(" "+_vm._s(_vm.__('forms.morbidity.morbidityCopiedInfo'))+" ")],1):_vm._e()]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('formulate-input',{attrs:{"type":"group","name":"morbidities","validation-name":"morbidities","validation":"","error-behavior":"live"},scopedSlots:_vm._u([{key:"default",fn:function(groupProps){return [_c('div',{staticClass:"w-full grid grid-cols-2 gap-x-12 md:gap-x-24"},[_c('formulate-input',{attrs:{"name":"morbidity","type":"icd","label":_vm.__('forms.morbidity'),"group-index":groupProps.index,"placeholder":_vm.__('forms.typeInICDCode'),"data-cypress":"form.input.morbidities","disabled":_vm.getFormsObjectField(
                                    'morbidity',
                                    'morbidities'
                                )}}),(_vm.showStoppedDate)?_c('formulate-input',{attrs:{"name":"stoppedDate","label":_vm.__('forms.morbidity.morbidityStopped'),"type":"date","placeholder":"date","validation":'before:' + _vm.todaysDate,"validation-messages":{
                                before: _vm.__('error.invalidDate.beforeToday'),
                            }}}):_vm._e()],1),(
                            _vm.getFieldFromCurrentForm(
                                'stoppedDate',
                                'morbidities'
                            )
                        )?_c('div',{staticClass:"w-full md:w-1/2"},[_c('formulate-input',{attrs:{"name":"morbidity","type":"icd","label":_vm.__('forms.morbidity.addNewMorbidity'),"help":_vm.__('forms.morbidity.help.leaveBlank'),"placeholder":_vm.__('forms.typeInICDCode'),"data-cypress":"form.input.morbidities"}})],1):_vm._e()]}}])}),_c('div',{staticClass:"w-full md:w-1/2"},[(_vm.getFormsObjectField('morbidity', 'morbidities'))?_c('formulate-schema',{attrs:{"schema":_vm.changeInMorbiditySchema},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e(),_c('headline',{attrs:{"tag":"h4"}},[_vm._v(" "+_vm._s(_vm.__('forms.comorbidity.comorbidities'))+" ")]),_c('div',[_c('formulate-input',{attrs:{"type":"group","name":"comorbidities","repeatable":true,"add-label":_vm.__('forms.comorbidity.add'),"validation-name":"comorbidities","validation":"","error-behavior":"live"},scopedSlots:_vm._u([{key:"default",fn:function(groupProps){return [_c('formulate-input',{attrs:{"name":"comorbidity","type":"icd","group-index":groupProps.index,"label":_vm.__('forms.comorbidity'),"placeholder":_vm.__('forms.typeInICDCode'),"data-cypress":"form.input.comorbidities"}})]}}])})],1)],1),_c('formulate-input',{staticClass:" w-full my-0",attrs:{"name":"comments","type":"text","label":_vm.__('forms.morbidity.comments'),"placeholder":_vm.__('forms.morbidity.comments'),"data-cypress":"form.input.comment"}})],1),_c('div',{staticClass:"mt-8 flex justify-end"},[_c('formulate-input',{attrs:{"type":"submit","label":_vm.__('save'),"data-cypress":"form.next","disabled":_vm.validation.hasErrors}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }