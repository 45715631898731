import Vue from 'vue'
import VueRouter from 'vue-router'

import authenticated from 'router/middleware/authenticated'
import projectSlug from 'router/middleware/projectSlug'
import patient from 'router/middleware/patient'

import Home from 'organisms/Home'
import Dashboard from 'organisms/Dashboard'
import ExportData from 'organisms/ExportData'
import Visit from 'organisms/Visit'
import DataUpload from 'organisms/DataUpload'
import Screening from 'organisms/Screening'
import NotFound from 'organisms/NotFound'
import CheckIn from 'organisms/CheckIn'
import Support from 'organisms/Support'
import Login from 'organisms/auth/Login'
import Logout from 'organisms/auth/Logout'

Vue.use(VueRouter)

const appStartRoute = 'Home'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/exportData',
        name: 'ExportData',
        component: ExportData,
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/:projectSlug/checkin',
        name: 'CheckIn',
        component: CheckIn,
        meta: {
            middleware: [authenticated, projectSlug],
        },
    },
    {
        path: '/:projectSlug/checkin/:patientId',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            middleware: [authenticated, projectSlug, patient],
        },
    },
    {
        path: '/:projectSlug/checkin/:patientId/screening/:visitNumber?',
        name: 'Screening',
        component: Screening,
        meta: {
            middleware: [authenticated, projectSlug, patient],
        },
    },
    {
        path: '/:projectSlug/checkin/:patientId/visit/:visitNumber/:chapter?',
        name: 'Visit',
        component: Visit,
        meta: {
            middleware: [authenticated, projectSlug, patient],
        },
    },
    {
        path: '/:projectSlug/checkin/:patientId/upload/:visitNumber',
        name: 'DataUpload',
        component: DataUpload,
        meta: {
            middleware: [authenticated, projectSlug, patient],
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

// each middleware needs to be a promise, and if not fullfilled
// should throw a reject with a name of router path to redirect
// !! middleware order is important
router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.middleware) {
        for (const middleware of to.meta.middleware) {
            try {
                await middleware(to)
            } catch (e) {
                next(e)
                break
            }
        }
        next()
    } else {
        next()
    }
})

export { router, appStartRoute }
export default router
