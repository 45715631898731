<template>
    <form @submit.prevent="submit">
        <basic-alert
            v-if="error.message"
            :key="error.timestamp"
            bold-text="Sign in failed."
            type="error"
            class="mt-5"
            data-cypress="login.alert"
        >
            {{ error.message }}
        </basic-alert>
        <div class="my-5">
            <basic-input
                v-model="form.email"
                icon="envelope"
                type="email"
                name="email"
                :label="__('email')"
                data-cypress="login.email"
                placeholder="Email Address"
            />
        </div>
        <div class="my-5">
            <basic-input
                v-model="form.password"
                icon="lock"
                type="password"
                name="password"
                :label="__('password')"
                data-cypress="login.password"
            />
        </div>
        <div class="my-5 flex justify-end">
            <basic-button
                type="submit"
                :loading="loading"
                data-cypress="login.submit"
            >
                {{ __('signIn') }}
            </basic-button>
        </div>
    </form>
</template>

<script>
/**
 * @group MOLECULES
 * ...
 */
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
        }
    },
    computed: {
        ...vuex.mapGetters({
            loading: 'auth/isLoading',
            error: 'auth/error',
        }),
    },
    mounted() {
        this.init()
    },
    methods: {
        submit() {
            this.login(this.form)
        },
        ...vuex.mapActions({
            init: 'auth/init',
            login: 'auth/login',
        }),
    },
}
</script>
