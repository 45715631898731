<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-wrap">
                <formulate-schema v-model="form" :schema="schema.firstRow" />
            </div>
            <div>
                <formulate-input
                    type="group"
                    name="microbiology"
                    :repeatable="true"
                    :add-label="__('forms.laboratory.addMicrobiologyReport')"
                >
                    <template v-slot:default="groupProps">
                        <div class="grid grid-cols-2 gap-12 mx-auto mb-0">
                            <div>
                                <formulate-input
                                    name="report"
                                    type="select"
                                    :label="
                                        __(
                                            'forms.laboratory.microbiologyReport'
                                        )
                                    "
                                    :options="{
                                        '': '',
                                        negative: __('negative'),
                                        positive: __('positive'),
                                    }"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="date"
                                    type="date"
                                    :label="__('forms.laboratory.date')"
                                    :placeholder="__('forms.laboratory.date')"
                                />
                            </div>
                        </div>
                        <div
                            v-if="
                                Array.isArray(groupProps.model) &&
                                    groupProps.model[0].report === 'positive'
                            "
                            class="grid grid-cols-2 gap-12 mx-auto mb-0"
                        >
                            <div />
                            <formulate-input
                                type="group"
                                name="bacteria"
                                class="w-3/4"
                                :repeatable="true"
                                :add-label="
                                    __(
                                        'forms.laboratory.addBacteriaDescription'
                                    )
                                "
                            >
                                <template>
                                    <formulate-input
                                        name="bacteria"
                                        type="text"
                                        :label="
                                            __(
                                                'forms.laboratory.bacteriaDescription'
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'forms.laboratory.bacteriaDescription'
                                            )
                                        "
                                    />
                                </template>
                            </formulate-input>
                        </div>
                    </template>
                </formulate-input>
            </div>
            <div class="mt-10">
                <formulate-input
                    type="group"
                    name="virology"
                    :repeatable="true"
                    :add-label="__('forms.laboratory.addVirologyReport')"
                >
                    <template v-slot:default="groupProps">
                        <div class="grid grid-cols-2 gap-12 mx-auto mb-0">
                            <div>
                                <formulate-input
                                    name="report"
                                    type="select"
                                    :label="
                                        __('forms.laboratory.VirologyReport')
                                    "
                                    :options="{
                                        '': '',
                                        negative: __('negative'),
                                        positive: __('positive'),
                                    }"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="date"
                                    type="date"
                                    :label="__('forms.laboratory.date')"
                                    :placeholder="__('forms.laboratory.date')"
                                />
                            </div>
                        </div>
                        <div
                            v-if="
                                Array.isArray(groupProps.model) &&
                                    groupProps.model[0].report === 'positive'
                            "
                            class="grid grid-cols-2 gap-12 mx-auto mb-10"
                        >
                            <div />
                            <formulate-input
                                type="group"
                                name="virus"
                                class="w-3/4"
                                :repeatable="true"
                                :add-label="
                                    __('forms.laboratory.addVirusDescription')
                                "
                            >
                                <template>
                                    <formulate-input
                                        name="virus"
                                        type="text"
                                        :label="
                                            __(
                                                'forms.laboratory.virusDescription'
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'forms.laboratory.virusDescription'
                                            )
                                        "
                                    />
                                </template>
                            </formulate-input>
                        </div>
                    </template>
                </formulate-input>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const classes = {
    left: 'w-1/2 pr-10',
    right: 'w-1/2 pl-1 pr-10',
}
export default {
    name: 'LaboratoryTrx',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'laboratory.crp',
                        label: this.__('forms.laboratory.crp'),
                        placeholder: this.__('forms.laboratory.crp'),
                        type: 'text',
                        class: classes.left,
                    },
                    {
                        name: 'laboratory.crpDate',
                        label: this.__('forms.laboratory.date'),
                        type: 'date',
                        class: classes.right,
                    },
                    {
                        name: 'laboratory.eosinophils',
                        label: this.__('forms.laboratory.eosinophils'),
                        placeholder: this.__('forms.laboratory.eosinophils'),
                        type: 'text',
                        class: classes.left,
                    },
                    {
                        name: 'laboratory.eosinophilsDate',
                        label: this.__('forms.laboratory.date'),
                        type: 'date',
                        class: classes.right,
                    },
                ],
            }

            return schema
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
<style lang="scss">
.bacteria {
    .formulate-input-group-repeatable {
        display: flex;
    }
}
</style>
