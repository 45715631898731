<template>
    <div>
        <headline>{{ __('forms.chapters.visitN', [visitNumber]) }}</headline>
        <chapter-picker
            :chapters="chapters"
            :active-chapter-index="activeChapterIndex"
            :on-click="handlePickerClick"
        />
        <headline>{{ chapters[activeChapterIndex].name }}</headline>
        <component :is="renderChapter" />
    </div>
</template>

<script>
export default {
    name: `VisitBex`,
    data() {
        return {
            chapters: [
                {
                    name: this.__('forms.chapters.anthropometrics'),
                    component: 'anthropometrics-bex',
                },
                {
                    name: this.__('forms.chapters.eatingDrinkingHabits'),
                    component: 'eating-drinking-bex',
                },
                {
                    name: this.__('forms.chapters.smokingHistory'),
                    component: 'smoking-history-bex',
                },
                {
                    name: this.__('forms.chapters.medication'),
                    component:
                        this.$route.params.visitNumber > 1
                            ? 'medication-x-bex'
                            : 'medication-bex',
                },
                {
                    name: this.__('forms.chapters.morbidities'),
                    component:
                        this.$route.params.visitNumber > 1
                            ? 'morbidities-x-bex'
                            : 'morbidities-bex',
                },
                {
                    name: this.__('forms.chapters.questionnaire'),
                    component: 'questionnaire-bex',
                },
                {
                    name: this.__('forms.chapters.lungFunction'),
                    component: 'lung-function-bex',
                },
                {
                    name: this.__('forms.chapters.breathAnalysis'),
                    component: 'breath-analysis-bex',
                },
                {
                    name: this.__('forms.chapters.visitNCompletion', [
                        this.$route.params.visitNumber,
                    ]),
                    component: 'visit-finish-bex',
                },
            ],
        }
    },
    computed: {
        visitNumber() {
            const { visitNumber } = this.$route.params
            return (visitNumber && visitNumber) || 1
        },
        renderChapter() {
            const { chapter } = this.$route.params
            if (!chapter) {
                return this.chapters[0].component
            }
            if (this.chapters[chapter]) {
                return this.chapters[chapter].component
            }
            console.error(
                `Visit${this.visitNumber}.vue problem with chapter naming`
            )
            return this.chapters[0].component
        },
        activeChapterIndex() {
            const { chapter } = this.$route.params
            return chapter || '0'
        },
    },
    methods: {
        handlePickerClick(e) {
            const { index } = e.currentTarget.dataset
            if (!index) {
                console.error(
                    `Visit${this.visitNumber}.vue error: missing 'index' from 'handlePickerClick'`
                )
                return
            }
            this.$router
                .push({
                    name: 'Visit',
                    params: {
                        chapter: index,
                    },
                })
                .catch(() => {})
        },
    },
}
</script>
