<template>
    <div class="picker" data-cypress="picker">
        <div
            v-for="(dot, index) in chapters"
            :key="dot.name"
            v-tooltip="dot.name"
            class="picker__dot"
            data-cypress="picker-dot"
            :class="{
                'picker__dot--active':
                    index.toString() === activeChapterIndex.toString(),
            }"
            :data-index="index"
            @click="onClick"
        >
            <div
                class="picker__name"
                data-cypress="picker-name"
                :class="{
                    'picker__name--active':
                        index.toString() === activeChapterIndex.toString(),
                }"
            >
                {{ dot.name }}
            </div>
        </div>
        <div class="picker__line" />
    </div>
</template>

<script>
export default {
    props: {
        chapters: {
            type: Array,
            required: true,
        },
        activeChapterIndex: {
            type: [Number, String],
            required: true,
        },
        onClick: {
            type: Function,
            required: true,
        },
    },
}
</script>
<style lang="scss" scoped>
$pickerSize: 22px;

.picker {
    @apply flex flex-1 justify-between relative py-10;

    &__dot {
        width: $pickerSize;
        height: $pickerSize;
        border-width: 8px;
        @apply relative cursor-pointer bg-primary-700 z-10 rounded-full border-white box-content;

        &--active {
            @apply bg-primary-500;
        }
    }

    &__line {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;

        @apply z-0 bg-primary-700;
    }

    &__name {
        position: absolute;
        transform: translateX(-50%);
        top: 35px;
        left: 50%;
        width: 200px;

        @apply text-primary-500 text-center invisible;

        &--active {
            @apply visible;
        }
    }
}
</style>
