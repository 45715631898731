<template>
    <div
        class="px-4 py-2 border-0 rounded-full leading-none text-xs uppercase tracking-wider"
        :class="[
            { 'text-red-600 bg-red-200': color === 'red' },
            { 'text-orange-600 bg-orange-200': color === 'orange' },
            { 'text-green-600 bg-green-200': color === 'green' },
            { 'text-gray-600 bg-white bg-opacity-75 ': color === 'white' },
            {
                'text-gray-600 bg-gray-300':
                    color === 'gray' || color === 'info',
            },
        ]"
    >
        <slot></slot>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Renders a simple label rounded div with background color and uppercase text
 */
export default {
    inheritAttrs: true,
    props: {
        // Color of the label
        color: {
            // `'red'` / `'orange'`/ `'green'`/ `'white'`
            type: String,
            default: 'info',
        },
    },
}
</script>
