<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <headline tag="h4">
                <div
                    v-if="getFormsObjectField('copiedMedication')"
                    id="copiedInfoText"
                    data-cypress="copied-info-text"
                    class="flex px-4 pb-2 text-gray-600 text-sm"
                >
                    <form-info-icon class="mt-1" />
                    {{ __('forms.medication.medicationHistoryInfo') }}
                </div>
            </headline>
            <div class="flex flex-col flex-1">
                <formulate-input
                    type="group"
                    name="medication"
                    :repeatable="true"
                    :add-label="__('forms.medication.add')"
                >
                    <template v-slot:default="groupProps">
                        <div
                            class="grid grid-cols-2 gap-12 mx-auto mb-5 pb-5 border-b border-gray-300"
                        >
                            <div>
                                <formulate-input
                                    name="drugName"
                                    type="drug"
                                    :group-index="groupProps.index"
                                    :label="__('forms.medication.drugName')"
                                    :placeholder="
                                        __('forms.medication.drugName')
                                    "
                                    data-cypress="form.input.drugAutocomplete"
                                    @select="addActiveSubstanceToField"
                                />
                                <formulate-input
                                    name="dosagePerIntake"
                                    :label="
                                        __('forms.medication.dosagePerIntake')
                                    "
                                    :placeholder="
                                        __(
                                            'forms.medication.dosagePerIntakePlaceHolder'
                                        )
                                    "
                                    min="0"
                                    step="any"
                                    type="number"
                                />

                                <formulate-input
                                    name="lastIntake"
                                    :label="__('forms.medication.lastIntake')"
                                    :placeholder="
                                        __('forms.medication.lastIntake')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                                <formulate-input
                                    name="startedDate"
                                    :label="
                                        __('forms.medication.medicationStarted')
                                    "
                                    type="date"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="activeSubstance"
                                    :label="
                                        __('forms.medication.activeSubstance')
                                    "
                                    :placeholder="
                                        __('forms.medication.activeSubstance')
                                    "
                                />
                                <div
                                    class="mt-4 flex text-secondary-700 text-center"
                                >
                                    <div class="px-2">
                                        <label
                                            for="intakeMorning"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunrise']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMorning"
                                            type="number"
                                            :help="
                                                __('forms.medication.morning')
                                            "
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeMidday"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sun']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMidday"
                                            :help="
                                                __('forms.medication.midday')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="mx-2">
                                        <label
                                            for="intakeEvening"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunset']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeEvening"
                                            :help="
                                                __('forms.medication.evening')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeNight"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'moon']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeNight"
                                            :help="__('forms.medication.night')"
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                </div>
                                <div class="py-6">
                                    <font-awesome-icon
                                        class="text-gray-600"
                                        :icon="['fa', 'chevron-square-right']"
                                    />
                                    {{
                                        __(
                                            'forms.medication.totalDosagePerDay',
                                            [totalDosageDay(groupProps.index)]
                                        )
                                    }}
                                </div>
                                <div class="sm:mt-4">
                                    <formulate-schema
                                        v-model="form"
                                        :schema="medicationStoppedSchema"
                                    />
                                </div>
                                <div
                                    v-if="
                                        showMedicationStoppedDate(
                                            groupProps.index
                                        )
                                    "
                                >
                                    <formulate-input
                                        name="stoppedDate"
                                        :label="
                                            __(
                                                'forms.medication.medicationStopDate'
                                            )
                                        "
                                        type="date"
                                        :validation="
                                            'after:' +
                                                form.medication[
                                                    groupProps.index
                                                ]['startedDate']
                                        "
                                        :validation-messages="{
                                            after: __(
                                                'error.invalidDate.afterDate'
                                            ),
                                        }"
                                        error-behavior="live"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </formulate-input>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'MedicationXBex',
    mixins: [visitData],
    computed: {
        medicationStoppedSchema() {
            const medicationStoppedSchema = [
                {
                    name: 'medicationStopped',
                    label: this.__('forms.medication.medicationStopped'),
                    type: 'select',
                    options: [
                        { value: this.__('no'), label: 'no' },
                        { value: this.__('yes'), label: 'yes' },
                    ],
                },
            ]
            return medicationStoppedSchema
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },

        showMedicationStoppedDate(index) {
            if (!this.form.medication || index === undefined) {
                return false
            }
            const medication = this.form.medication[index]
            const field = 'medicationStopped'
            return (medication[field] && medication[field] === 'yes') || false
        },

        // Sets the active substance based on the selected search entry
        addActiveSubstanceToField(entry, index) {
            // Sets the value on the medication-item
            this.form.medication[index] = {
                ...this.form.medication[index],
                activeSubstance: entry.activeSubstance,
            }
        },

        totalDosageDay(index) {
            if (!this.form.medication || index === undefined) {
                return
            }
            const intakes = [
                'intakeMorning',
                'intakeMidday',
                'intakeEvening',
                'intakeNight',
            ]

            const block = this.form.medication[index]

            const dosage = parseFloat(block.dosagePerIntake)

            const { total, intakesAvailable } = intakes.reduce(
                (accu, currentval) => {
                    if (block[currentval]) {
                        const int = parseInt(block[currentval])
                        accu.total += int
                        accu.intakesAvailable += 1
                    }

                    return accu
                },
                { total: 0, intakesAvailable: 0 }
            )

            if (intakesAvailable !== 0 && !isNaN(dosage) && !isNaN(total)) {
                return _.round(total * dosage, 3)
            }
            return '--'
        },
    },
}
</script>
