<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-wrap">
                <formulate-schema v-model="form" :schema="schema.firstRow" />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const classes = {
    left: 'w-1/2 pr-10',
    right: 'w-1/2 pl-1 pr-10',
}
export default {
    name: 'LaboratoryTrxV2',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'laboratory.crp',
                        label: this.__('forms.laboratory.crp'),
                        placeholder: this.__('forms.laboratory.crp'),
                        type: 'text',
                        class: classes.left,
                    },
                    {
                        name: 'laboratory.crpDate',
                        label: this.__('forms.laboratory.date'),
                        type: 'date',
                        class: classes.right,
                    },
                    {
                        name: 'laboratory.eosinophils',
                        label: this.__('forms.laboratory.eosinophils'),
                        placeholder: this.__('forms.laboratory.eosinophils'),
                        type: 'text',
                        class: classes.left,
                    },
                    {
                        name: 'laboratory.eosinophilsDate',
                        label: this.__('forms.laboratory.date'),
                        type: 'date',
                        class: classes.right,
                    },
                ],
            }

            return schema
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit2': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
