<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-1 flex-wrap">
                <formulate-schema v-model="form" :schema="schema" />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const commonClasses = 'w-1/2 pr-12'

export default {
    name: 'OsaSpecificsBro',
    data() {
        return {
            form: {},
            validation: {},
            schema: [
                {
                    name: 'osa.circumference.hip',
                    label: `${this.__(
                        'forms.osa.circumference.hip'
                    )} (${this.__('cm')})`,
                    placeholder: this.__('forms.osa.circumference.hip'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.circumference.waist',
                    label: `${this.__(
                        'forms.osa.circumference.waist'
                    )} (${this.__('cm')})`,
                    placeholder: this.__('forms.osa.circumference.waist'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.circumference.neck',
                    label: `${this.__(
                        'forms.osa.circumference.neck'
                    )} (${this.__('cm')})`,
                    placeholder: this.__('forms.osa.circumference.neck'),
                    type: 'number',
                    class: commonClasses,
                },
                { component: 'div', class: 'w-1/2', id: 'spacer1' },
                {
                    name: 'osa.poligraphyDate',
                    label: this.__('forms.osa.poligraphyDate'),
                    type: 'date',
                    class: commonClasses,
                },
                {
                    name: 'osa.ahi',
                    label: this.__('forms.osa.ahi'),
                    placeholder: this.__('forms.osa.ahiPlaceholder'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.apneasObstruction',
                    label: this.__('forms.osa.apneasObstruction'),
                    placeholder: this.__('forms.osa.apneasObstruction'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.apneasObstructionPercent',
                    label: `${this.__(
                        'forms.osa.apneasObstructionPercent'
                    )} (%)`,
                    placeholder: this.__('forms.osa.apneasObstructionPercent'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.apneasCentral',
                    label: this.__('forms.osa.apneasCentral'),
                    placeholder: this.__('forms.osa.apneasCentral'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.apneasCentralPercent',
                    label: `${this.__('forms.osa.apneasCentralPercent')} (%)`,
                    placeholder: this.__('forms.osa.apneasCentralPercent'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.odi',
                    label: this.__('forms.osa.odi'),
                    placeholder: this.__('forms.osa.odi'),
                    type: 'number',
                    class: commonClasses,
                },
                {
                    name: 'osa.saturationTime',
                    label: this.__('forms.osa.saturationTime'),
                    placeholder: this.__('forms.time.formatHours'),
                    type: 'text',
                    class: commonClasses,
                },
                {
                    name: 'osa.cpapTrialAccepted',
                    label: this.__('forms.osa.cpapTrialAccepted'),
                    type: 'select',
                    class: commonClasses,
                    options: {
                        '': '',
                        yes: this.__('yes'),
                        no: this.__('no'),
                    },
                },
            ],
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
