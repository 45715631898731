<template>
    <default-app-template>
        <component :is="screeningComponent" />
    </default-app-template>
</template>

<script>
export default {
    computed: {
        screeningComponent() {
            const { projectSlug } = this.$router.history.current.params
            const individualScreening = `screening-${projectSlug}`
            return (
                window.componentsList[individualScreening] || 'screening-shared'
            )
        },
    },
}
</script>
