<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-input
                        type="group"
                        name="morbidities"
                        validation-name="morbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="morbidity"
                                type="icd"
                                :label="__('forms.morbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.morbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
                <div class="flex flex-col flex-1 pl-7">
                    <formulate-input
                        type="group"
                        name="comorbidities"
                        :repeatable="true"
                        :add-label="__('diagnosis.add')"
                        validation-name="comorbidities"
                        validation=""
                        error-behavior="live"
                    >
                        <template v-slot:default>
                            <formulate-input
                                name="comorbidity"
                                type="icd"
                                :label="__('forms.comorbidity')"
                                :placeholder="__('forms.typeInICDCode')"
                                data-cypress="form.input.comorbidities"
                            />
                        </template>
                    </formulate-input>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'MorbiditiesShared',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
