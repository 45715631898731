<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <formulate-input type="group" name="smoking">
                <div class="w-full flex flex-col">
                    <div class="md:grid md:grid-cols-2 md:gap-x-24">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.firstRow"
                        />
                        <div
                            v-if="
                                visitNumber > 1 &&
                                    getFormsObjectField('copiedSmoking')
                            "
                            id="copiedInfoText"
                            data-cypress="copied-info-text"
                            class="flex px-4 md:mt-5 lg:py-4 text-gray-600 text-sm"
                        >
                            <form-info-icon class="mt-1" />
                            {{ __('forms.smoking.smokingHistoryInfoBox') }}
                        </div>
                    </div>
                    <div
                        v-if="schema.secondRow"
                        class="flex flex-col flex-1 w-full"
                    >
                        <div class="my-1">
                            <headline tag="h3">{{
                                __('forms.smoking.smokeType.tobacco')
                            }}</headline>
                            <formulate-input type="group" name="tobacco">
                                <div class="md:grid md:grid-cols-2 md:gap-x-24">
                                    <formulate-schema
                                        v-model="form"
                                        :schema="schema.secondRow.tobacco"
                                    />
                                </div>
                            </formulate-input>
                        </div>

                        <div class="my-2">
                            <headline tag="h3">{{
                                __('forms.smoking.smokeType.cannabis')
                            }}</headline>
                            <formulate-input type="group" name="cannabis">
                                <div class="md:grid md:grid-cols-2 md:gap-x-24">
                                    <formulate-schema
                                        v-model="form"
                                        :schema="schema.secondRow.cannabis"
                                    />
                                </div>
                            </formulate-input>
                        </div>

                        <div class="my-1">
                            <headline tag="h3">{{
                                __('forms.smoking.smokeType.eCigarette')
                            }}</headline>
                            <formulate-input type="group" name="eCigarette">
                                <div class="md:grid md:grid-cols-2 md:gap-x-24">
                                    <formulate-schema
                                        v-model="form"
                                        :schema="schema.secondRow.eCigarette"
                                    />
                                </div>
                            </formulate-input>
                        </div>
                    </div>
                </div>
            </formulate-input>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

const smokerTypes = {
    neverSmoker: 'neverSmoker',
    smoker: 'smoker',
    exSmoker: 'exSmoker',
}
const cannabisType = {
    marihuana: 'Marihuana',
    hashish: 'Hashish',
}

export default {
    name: 'SmokingHistoryBex',
    mixins: [visitData],
    computed: {
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'habits',
                        label: this.__('forms.smoking.habits'),
                        type: 'select',
                        options: {
                            '': '',
                            [smokerTypes.neverSmoker]: this.__(
                                'forms.smoking.neverSmoker'
                            ),
                            [smokerTypes.smoker]: this.__(
                                'forms.smoking.smoker'
                            ),
                            [smokerTypes.exSmoker]: this.__(
                                'forms.smoking.exSmoker'
                            ),
                        },
                    },
                ],
            }

            const habit = this.getFieldFromCurrentForm('habits', 'smoking')

            if ([smokerTypes.smoker, smokerTypes.exSmoker].includes(habit)) {
                schema.secondRow = {
                    tobacco: this.tobaccoSchema,
                    cannabis: this.cannabisSchema,
                    eCigarette: this.eCigaretteSchema,
                }
            }

            return schema
        },

        tobaccoSchema() {
            return [
                {
                    name: 'cigsPerDay',
                    label: this.__('forms.smoking.cigsPerDay'),
                    type: 'number',
                },
                {
                    name: 'yearStarted',
                    label: this.__('forms.smoking.started'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    name: 'yearEnded',
                    label: this.__('forms.smoking.ended'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    component: 'div',
                    class: 'howManyYearsClasses',
                    children: [
                        {
                            component: 'form-arrow-right',
                            id: 'form-arrow-right',
                            children: 'one',
                        },
                        {
                            component: 'span',
                            id: 'YearsSmoking',
                            children: this.tobaccoYears,
                        },
                    ],
                },
                {
                    name: 'packYears',
                    label: this.__('forms.smoking.packYears'),
                    type: 'number',
                },
            ]
        },

        cannabisSchema() {
            return [
                {
                    name: 'cannabisType',
                    label: this.__('forms.smoking.cannabisType'),
                    type: 'select',
                    options: {
                        '': '',
                        [cannabisType.marihuana]: this.__(
                            'forms.smoking.smokeType.marihuana'
                        ),
                        [cannabisType.mashish]: this.__(
                            'forms.smoking.smokeType.hashish'
                        ),
                    },
                },
                {
                    name: 'amountOfCannabis',
                    label: this.__('forms.smoking.amountOfCannabis'),
                    type: 'number',
                    placeholder: this.__('forms.smoking.amountOfCannabis'),
                },
                {
                    name: 'yearStarted',
                    label: this.__('forms.smoking.started'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    name: 'yearEnded',
                    label: this.__('forms.smoking.ended'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    component: 'div',
                    class: 'howManyYearsClasses',
                    children: [
                        {
                            component: 'form-arrow-right',
                            id: 'form-arrow-right',
                            children: 'one',
                        },
                        {
                            component: 'span',
                            id: 'YearsSmoking',
                            children: this.cannabisYears,
                        },
                    ],
                },
            ]
        },

        eCigaretteSchema() {
            return [
                {
                    name: 'numberOfBottlesPM',
                    label: this.__('forms.smoking.numberOfBottlesPM'),
                    type: 'number',
                },
                {
                    name: 'amountPerBottle',
                    label: this.__('forms.smoking.amountPerBottle'),
                    type: 'number',
                    placeholder: this.__('forms.smoking.amountPerBottle'),
                },
                {
                    name: 'type',
                    label: this.__('forms.smoking.eCigType'),
                    type: 'text',
                },
                {
                    name: 'yearStarted',
                    label: this.__('forms.smoking.started'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    name: 'yearEnded',
                    label: this.__('forms.smoking.ended'),
                    type: 'number',
                    placeholder: 'yyyy',
                },
                {
                    component: 'div',
                    class: 'howManyYearsClasses',
                    children: [
                        {
                            component: 'form-arrow-right',
                            id: 'form-arrow-right',
                            children: 'one',
                        },
                        {
                            component: 'span',
                            id: 'YearsSmoking',
                            children: this.eCigaretteYears,
                        },
                    ],
                },
            ]
        },

        tobaccoYears() {
            const text = this.__('forms.smoking.smokingYears')
            const tobacco = this.getFieldFromCurrentForm('tobacco', 'smoking')
            if (!tobacco) {
                return text
            }
            const started = tobacco[0]
            const ended = tobacco[0]
            return `${text} ${this.YearsSmoking(
                started['yearStarted'],
                ended['yearEnded']
            )}`
        },

        cannabisYears() {
            const text = this.__('forms.smoking.smokingYears')
            const cannabis = this.getFieldFromCurrentForm('cannabis', 'smoking')
            if (!cannabis) {
                return text
            }
            const started = cannabis[0]
            const ended = cannabis[0]
            return `${text} ${this.YearsSmoking(
                started['yearStarted'],
                ended['yearEnded']
            )}`
        },
        eCigaretteYears() {
            const text = this.__('forms.smoking.smokingYears')
            const eCigarette = this.getFieldFromCurrentForm(
                'eCigarette',
                'smoking'
            )
            if (!eCigarette) {
                return text
            }
            const started = eCigarette[0]
            const ended = eCigarette[0]
            return `${text} ${this.YearsSmoking(
                started['yearStarted'],
                ended['yearEnded']
            )}`
        },
    },
    methods: {
        YearsSmoking(started, ended) {
            let finalText = `--`

            const date = new Date()
            const currentYear = date.getFullYear()

            let parsedStart, parsedEnd

            if (started) {
                parsedStart = parseInt(started, 10)
            }
            if (ended) {
                parsedEnd = parseInt(ended, 10)
            }

            if (started && !ended && started.length === 4) {
                finalText = `${currentYear - parsedStart}`
            }
            if (
                started &&
                ended &&
                started.length === 4 &&
                ended.length === 4
            ) {
                finalText = `${parsedEnd - parsedStart}`
            }
            return finalText
        },

        submit() {
            submitForm(this)
        },
    },
}
</script>

<style lang="scss" scoped>
.howManyYearsClasses {
    @apply text-gray-600 py-10 text-lg;
}
</style>
