<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-1 flex-wrap">
                <formulate-schema v-model="form" :schema="schema" />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const commonClasses = 'w-1/2'

export default {
    name: 'CopdTrxV2',
    data() {
        return {
            form: {},
            validation: {},
            schema: [
                {
                    name: 'CATScore',
                    label: this.__('forms.CATScore'),
                    placeholder: this.__('forms.CATScore'),
                    type: 'number',
                    class: `${commonClasses} pr-6`,
                },
                {
                    name: 'mMRCscore',
                    label: this.__('forms.mMRCscore'),
                    placeholder: this.__('forms.mMRCscore'),
                    type: 'number',
                    class: `${commonClasses} pl-6`,
                },
            ],
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit2': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
