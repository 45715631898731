<template>
    <font-awesome-icon
        class="text-gray-600 mr-2"
        :icon="['fa', 'chevron-square-right']"
    />
</template>

<script>
export default {}
</script>
