import store from 'store'

const redirectTo = { name: 'Home' }

export default to =>
    new Promise((resolve, reject) => {
        const { params } = to
        const activeProject = store.getters['projects/activeProject']
        const projects = store.getters['projects/projects']
        const prepareActiveProject = () => {
            const project = store.getters['projects/projects'].filter(
                node => node.slug === params.projectSlug
            )

            if (project.length >= 1) {
                store
                    .dispatch('projects/setActiveProject', project[0])
                    .then(() => resolve())
                    .catch(() => {
                        reject(redirectTo)
                    })
            }
        }

        if (
            activeProject !== null &&
            activeProject.slug === params.projectSlug
        ) {
            resolve()
        } else if (projects.length > 0 && activeProject === null) {
            prepareActiveProject()
        } else {
            store
                .dispatch('projects/init')
                .then(() => {
                    if (params.projectSlug) {
                        prepareActiveProject()
                    } else {
                        reject(redirectTo)
                    }
                })
                .catch(() => reject(redirectTo))
        }
    })
