<template>
    <div
        :class="
            `formulate-input-element formulate-input-element--${context.type} relative`
        "
        :data-type="context.type"
    >
        <input
            v-model="context.model"
            type="text"
            v-bind="context.attributes"
            autocomplete="off"
            @keyup="openSearchbox"
            @keyup.down.prevent="increment()"
            @keyup.up.prevent="decrement()"
            @keydown.enter.prevent="enter()"
            @blur="context.blurHandler"
        />

        <transition name="fade">
            <p
                v-if="isLoading"
                class="absolute right-0"
                style="top: 1.1rem; right: 1rem;"
            >
                <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="animate-spin text-secondary-300 "
                />
            </p>
        </transition>
        <ul
            v-if="searchResults.length"
            class="formulate-input-dropdown p-2 bg-gray-100 shadow-lg absolute w-full rounded-bl-lg rounded-br-lg mb-10 z-1000"
            data-cypress="patient.diagnoses.autocomplete.icd"
        >
            <li
                v-for="(entry, index) in searchResults"
                :key="index"
                class="text-gray-700 cursor-pointer hover:bg-primary-100 hover:font-bold px-2 py-2 rounded-lg my-1"
                :class="{
                    'bg-gray-200': index % 2 == 0,
                    'bg-primary-100 font-bold': selectedIndex == index,
                }"
                @click.stop="selectEntry(index)"
                @mouseover="selectedIndex = index"
            >
                <span>{{ entry[0] }}</span>
                <span> - </span>
                <span>{{ entry[1] }}</span>
            </li>

            <li v-if="isLoading" class="text-gray-500 text-sm py-1 text-center">
                {{ __('fields.autocomplete.searching') }}
            </li>
            <li
                v-else
                class="text-gray-500 text-sm py-1 text-center"
                data-cypress="patient.form.autocomplete.results.found"
            >
                {{ numberOfHits }} {{ __('fields.autocomplete.resultsFound') }}
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import AutocompleteMixin from './AutocompleteMixin'
/**
 * @group VUE-FORMULATE PLUGINS
 * ICD Code Autocomplete Plugin for DBI using the clinicaltables.nlm.nih.gov API. Please check: https://clinicaltables.nlm.nih.gov
 */
export default {
    mixins: [AutocompleteMixin],
    props: {
        // Context Object provided by Vue Formulate
        context: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ICDSearchEndpoint() {
            return process.env.VUE_APP_ICD_SEARCH_ENDPOINT
                ? process.env.VUE_APP_ICD_SEARCH_ENDPOINT
                : Error('No ICD Search Endpoint provided in .env File')
        },
    },

    methods: {
        // @vuese
        // Selects an entry from search results and closes the box
        // @arg index
        selectEntry(index) {
            const entry = this.searchResults[index]
            if (entry) {
                this.context.model = `${entry[0]} - ${entry[1]}`
                this.closeSearchbox()
            }
        },
        // @vuese
        // Calls the API using Axios. Endpoint can be defined in by env: 'VUE_APP_ICD_SEARCH_ENDPOINT'
        // @arg query
        getSearchResult(query) {
            this.isLoading = true

            // Send a GET request
            axios
                .get(this.ICDSearchEndpoint, {
                    // DOCS: https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
                    params: {
                        terms: query,
                        df: 'code,name',
                        sf: 'code,name',
                        maxList: this.maxItems,
                    },
                })
                .then(result => {
                    // Index = 3 contains formatted results
                    this.searchResults = result.data[3]
                    // Index = 0 is the number of hits in the entire ICD database
                    this.numberOfHits = result.data[0]
                    this.isLoading = false
                })
                .catch(error => {
                    // Fire when there is an error in the API Call
                    this.$emit('searcherror', error)
                    this.isLoading = false
                })
        },
    },
}
</script>
