<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.leftColumn"
                    />
                </div>
                <div class="flex flex-col flex-1 pl-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.rightColumn"
                    />
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'QuestionnaireBroV2',
    data() {
        return {
            form: {},
            validation: { hasErrors: false },
            schema: {
                leftColumn: [
                    {
                        name: 'essTotalScore',
                        label: this.__('forms.essTotalScore'),
                        placeholder: this.__('forms.essTotalScore'),
                        type: 'text',
                    },
                ],
                rightColumn: [
                    {
                        name: 'fosqTotalScore',
                        label: this.__('forms.fosqTotalScore'),
                        placeholder: this.__('forms.fosqTotalScore'),
                        type: 'text',
                    },
                ],
            },
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit2': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
