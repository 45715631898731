import translations from 'src/translations.json'

const isArray = data => Array.isArray(data)
const isEmptyArray = data => isArray(data) && data.length < 1

const translate = {}

translate.install = function(Vue) {
    // @vuese
    // translate to given language, depending on `language` from `ui` store
    // `translationId` should be given in `object` format, example: "home.someId"
    // setting up in `localStorage` a variable name `showTranslationsId` with value `1`
    // will show ID's in page instead of translations
    // @arg translationId
    // @arg translationVariables
    Vue.prototype.__ = function(translationId, translationVariables = []) {
        const language = this.$store.getters['ui/lang']

        if (!translations[`${translationId}.${language}`]) {
            console.error(`translation problem, missing id: ${translationId}`)
            return translationId
        }

        if (!translations[`${translationId}.${language}`]) {
            console.error(
                `translation problem, missing language ${language} for id: ${translationId}`
            )
            return translationId
        }

        let translationText = translations[`${translationId}.${language}`]

        if (!isEmptyArray(translationVariables)) {
            for (let i = 0; i < translationVariables.length; i++) {
                const regex = new RegExp(`[{]${i}[}]`, 'g')

                translationText = translationText.replace(
                    regex,
                    translationVariables[i]
                )
            }
        }

        if (typeof window !== 'undefined') {
            const isShowSet = localStorage.getItem('showTranslationsId')
            if (isShowSet !== null && isShowSet === '1') {
                return translationId
            }
        }

        return translationText
    }
}

export default translate
