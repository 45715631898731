<template>
    <default-app-template>
        <div v-if="isLoading">
            <loading></loading>
        </div>
        <div v-else class="flex flex-col">
            <div>
                <h3
                    class="text-xl text-primary-700 mb-2 pb-0 self-end"
                    data-cypress="project.overview"
                >
                    {{ __('home.availableProjects') }}
                </h3>
            </div>
            <div>
                <div
                    class="grid grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-12"
                >
                    <project-card
                        v-for="project in projects"
                        :key="project.slug"
                        :project="project"
                    />
                </div>
            </div>
        </div>
    </default-app-template>
</template>

<script>
export default {
    data() {
        return {
            is: {
                loading: true,
            },
        }
    },
    computed: {
        ...vuex.mapGetters('projects', ['isLoading', 'projects']),
    },
    mounted() {
        this.projectInit()
    },
    methods: {
        ...vuex.mapActions({
            projectInit: 'projects/init',
            showToast: 'ui/showToast',
            changeLanguage: 'ui/changeLanguage',
        }),
    },
}
</script>
<style lang="scss" scoped>
.home {
    &__box {
        @apply flex flex-col justify-center shadow-lg border-2 rounded-lg border-gray-300 pb-4 mx-2 min-h-32;
    }

    &__title {
        @apply text-2xl font-bold text-center my-6 text-secondary-700 mb-10;
    }
}

.tab {
    @apply w-1/2 text-secondary-700 p-4 text-center no-underline bg-gray-200;
}

.tab:hover {
    cursor: pointer;
}

.tab.active {
    @apply bg-white font-bold;
}
</style>
