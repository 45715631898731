import { camelCase, upperFirst, kebabCase } from 'lodash'

// useful to check if given component exists when creating dynamic routes,
// example `molecules/forms/shared/screening` vs `molecules/forms/bro/screening`
window.componentsList = {}

const registerComponents = function(Vue) {
    // Global Component Registry
    const requireComponent = require.context(
        // The relative path of the components folder
        './components',
        // Whether or not to look in subfolders
        true,
        // The regular expression used to match base component filenames
        // --> /[a-z-A-Z]\w+\.(vue|js)$/
        /[a-z-A-Z0-9]+\.(vue|js)$/
    )

    requireComponent.keys().forEach(fileName => {
        // ignore organisms, since they are `pages` for router
        if (fileName.includes('/organisms')) {
            return
        }
        // Get component config
        const componentConfig = requireComponent(fileName)

        // Get PascalCase name of component
        const componentName = upperFirst(
            camelCase(
                // Gets the file name regardless of folder depth
                fileName
                    .split('/')
                    .pop()
                    .replace(/\.\w+$/, '')
            )
        )

        // use defined name or simply filename
        let name
        if (componentConfig.default && componentConfig.default.name) {
            name = componentConfig.default.name
        } else {
            name = componentName
        }

        window.componentsList[kebabCase(name)] = kebabCase(name)
        // Register component globally
        Vue.component(
            name,
            // Look for the component options on `.default`, which will
            // exist if the component was exported with `export default`,
            // otherwise fall back to module's root.
            componentConfig.default || componentConfig
        )
    })
}

export default registerComponents
