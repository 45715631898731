import store from 'store'

const redirectTo = { name: 'CheckIn' }

export default to =>
    new Promise((resolve, reject) => {
        const { slug } = store.getters['projects/activeProject']
        if (!slug) {
            return reject(redirectTo)
        }
        const { params } = to
        if (!params.patientId) {
            return reject({ ...redirectTo, params: { projectSlug: slug } })
        }

        if (store.getters['patient/patient'] !== null) {
            return resolve()
        }

        store
            .dispatch('patient/init', params.patientId)
            .then(() => {
                resolve()
            })
            .catch(() => {
                store.dispatch('ui/showToast', {
                    type: 'error',
                    message: window.VueApp.__('error.noPatientFound', [
                        params.patientId,
                    ]),
                })
                return reject({ ...redirectTo, params: { projectSlug: slug } })
            })
    })
