<template>
    <div :class="className">
        <component
            :is="tag"
            class="headline-style"
            :class="`headline-style__${tag} ${classes}`"
            data-cypress="headline.component"
        >
            <slot />
        </component>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Renders a simple headline
 */
export default {
    inheritAttrs: true,
    props: {
        centered: { type: Boolean, default: false },
        tag: {
            type: String,
            default: 'h2',
            validator: val =>
                ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(val),
        },
        className: {
            type: String,
            default: '',
        },
    },

    computed: {
        classes() {
            let classes = ''

            if (this.centered) {
                classes += 'center'
            }

            return classes
        },
    },
}
</script>

<style lang="scss" scoped>
.headline-style {
    @apply text-base text-primary-700 font-bold py-5;

    &__h1 {
        @apply text-3xl;
    }
    &__h2 {
        @apply text-2xl;
    }
    &__h3 {
        @apply text-xl;
    }
    &__h4 {
        @apply text-base;
    }
    &__h5 {
        @apply text-sm;
    }
    &__h6 {
        @apply text-xs;
    }

    &.center {
        @apply text-center;
    }
}
</style>
