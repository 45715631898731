<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col">
                <div class="flex flex-col pr-8 w-full sm:w-1/2">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.firstRow"
                    />
                </div>
                <div v-if="schema.secondRow" class="flex flex-1">
                    <div class="flex flex-col flex-grow pr-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.secondRow.leftColumn"
                        />
                    </div>
                    <div class="flex flex-col flex-grow pl-8">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.secondRow.rightColumn"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

const smokerTypes = {
    neverSmoker: 'neverSmoker',
    smoker: 'smoker',
    exSmoker: 'exSmoker',
}

export default {
    name: 'SmokingHistoryShared',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        YearsSmoking() {
            const text = this.__('forms.smoking.smokingYears')
            let finalText = `${text} --`
            const started = this.form['smoking.started']
            const ended = this.form['smoking.ended']
            const date = new Date()
            const currentYear = date.getFullYear()
            let parsedStart, parsedEnd

            if (started) {
                parsedStart = parseInt(started, 10)
            }
            if (ended) {
                parsedEnd = parseInt(ended, 10)
            }

            if (started && !ended && started.length === 4) {
                finalText = `${text} ${currentYear - parsedStart}`
            }
            if (
                started &&
                ended &&
                started.length === 4 &&
                ended.length === 4
            ) {
                finalText = `${text} ${parsedEnd - parsedStart}`
            }

            return finalText
        },
        schema() {
            const schema = {
                firstRow: [
                    {
                        name: 'smoking.habits',
                        label: this.__('forms.smoking.habits'),
                        type: 'select',
                        options: {
                            '': '',
                            [smokerTypes.neverSmoker]: this.__(
                                'forms.smoking.neverSmoker'
                            ),
                            [smokerTypes.smoker]: this.__(
                                'forms.smoking.smoker'
                            ),
                            [smokerTypes.exSmoker]: this.__(
                                'forms.smoking.exSmoker'
                            ),
                        },
                    },
                ],
            }

            const habit = this.form['smoking.habits']

            if ([smokerTypes.smoker, smokerTypes.exSmoker].includes(habit)) {
                schema.secondRow = {
                    leftColumn: {},
                    rightcolumn: {},
                }
            }

            const howManyYearsClasses = 'text-gray-600 py-10 text-lg'

            if (habit === smokerTypes.smoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smoking.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-1',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-1',
                                children: this.YearsSmoking,
                            },
                        ],
                    },
                    {
                        name: 'smoking.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]

                schema.secondRow.rightColumn = [
                    {
                        name: 'smoking.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                ]
            }
            if (habit === smokerTypes.exSmoker) {
                schema.secondRow.leftColumn = [
                    {
                        name: 'smoking.cigsPerDay',
                        label: this.__('forms.smoking.cigsPerDay'),
                        type: 'number',
                    },
                    {
                        name: 'smoking.ended',
                        label: this.__('forms.smoking.ended'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        name: 'smoking.packYears',
                        label: this.__('forms.smoking.packYears'),
                        type: 'number',
                    },
                ]
                schema.secondRow.rightColumn = [
                    {
                        name: 'smoking.started',
                        label: this.__('forms.smoking.started'),
                        type: 'number',
                        placeholder: 'yyyy',
                    },
                    {
                        component: 'div',
                        class: howManyYearsClasses,
                        children: [
                            {
                                component: 'form-arrow-right',
                                id: 'form-arrow-right-2',
                                children: 'one',
                            },
                            {
                                component: 'span',
                                id: 'YearsSmoking-2',
                                children: this.YearsSmoking,
                            },
                        ],
                    },
                ]
            }

            return schema
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
