/**
 * Grid displays like so
 * grid.one- ["screening1, screening2, ...screening n"];
 * grid.two- ["visit1", "visit2", ... "visitn"];
 * grid.three- ["visit1-data",  "visit2-data", ...,  "visitn-data"];
 */
const grid = {
    one: [],
    two: [],
    three: [],
}

/**
 * used to perform a numerical sort on strings with numbers
 */
function inNumOrder(a, b) {
    let sortA = a.match(/\d+/)
    let sortB = b.match(/\d+/)
    return sortA && sortB && sortA.join('') - sortB.join('')
}
/**
 * used to sort the forms collection into a 3-grid-column(3GC) 2D array adding multiple entries into their corresponding columns in the array
 * @param {string} forms - forms object
 */
function sortFormsInto2DArray(forms) {
    // clear grid first
    Object.keys(grid).forEach(key => {
        grid[key] = []
    })
    // Loop through form ids
    Object.keys(forms).forEach(key => {
        // Screenings
        if (key.includes('screening') && !grid.one.includes(key)) {
            grid.one.push(key)
            return grid.one.sort(inNumOrder)
        }
        // Visits
        if (
            !key.includes('data') &&
            !key.includes('screening') &&
            !grid.two.includes(key)
        ) {
            grid.two.push(key)
            return grid.two.sort(inNumOrder)
        }
        // Data Uploads
        if (key.includes('data') && !grid.three.includes(key)) {
            grid.three.push(key)
            return grid.three.sort(inNumOrder)
        }
    })
}

/**
 * used to swap rows with columns(transposition) of an array
 * @param {array} array - the array to transpose
 */
function transpose(array) {
    // Calculate the row and column of the Array
    const row = array.length || 0
    const column = array[1] instanceof Array ? array[1].length : 0

    // In case it is a zero matrix, no transpose routine needed.
    if (row === 0 || column === 0) {
        return []
    }
    let i,
        j,
        newCopy = []
    for (i = 0; i < column; i++) {
        newCopy[i] = []
        for (j = 0; j < row; j++) {
            newCopy[i][j] = array[j][i] || null
        }
    }

    return newCopy
}

export { grid, transpose, sortFormsInto2DArray }
