<template>
    <div
        v-if="project.slug"
        v-scroll-reveal.reset
        data-cypress="projectCard"
        class="project-card"
        :class="{ 'project-card--disabled': !project.active }"
    >
        <div class="project-card__body">
            <div
                class="project-card__title md:mx-4"
                data-cypress="projectCard.title"
                :class="{ 'project-card__title--disabled ': !project.active }"
            >
                {{ project.title }}
            </div>
        </div>
        <div class="project-card__footer">
            <basic-button
                :data-cypress="'projectCard.button.' + project.slug"
                :disabled="!project.active"
                class="narrow"
                @click="handleClick"
                >{{ __('select') }}</basic-button
            >
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

/**
 * @group ATOMS
 * Project Card to select a project
 */
export default {
    $inheritAttrs: true,
    props: {
        project: {
            type: Object,
            default: function() {
                return { active: false }
            },
        },
    },
    methods: {
        handleClick: function() {
            this.setActiveProject(this.project).then(() => {
                this.$router.push({
                    name: 'CheckIn',
                    params: { projectSlug: this.project.slug },
                })
            })
        },
        ...mapActions({
            setActiveProject: 'projects/setActiveProject',
            projectsUnsubscribe: 'projects/projectsUnsubscribe',
        }),
    },
}
</script>

<style lang="scss" scoped>
.project-card {
    @apply flex flex-wrap rounded-lg overflow-hidden shadow-md my-4 border-2 transition-all duration-300 bg-secondary-100 border-primary-100;

    &--disabled {
        @apply bg-gray-200 border-transparent cursor-not-allowed;
    }

    &__body {
        @apply h-36 w-full pt-8 px-2 bg-cover flex;
    }

    &__title {
        @apply self-center bg-white w-full rounded-lg px-8 py-4 text-2xl leading-tight tracking-normal font-bold text-center text-primary-700;

        &--disabled {
            @apply text-gray-500;
        }
    }

    &__footer {
        @apply flex justify-center w-full items-center py-4;
    }
}
</style>
