<template>
    <div>
        visit1 shared template
    </div>
</template>

<script>
export default {
    name: 'Visit1Shared',
    computed: {},
}
</script>
