<template>
    <div class="visit" :label="label" data-cypress="addVisitLink">
        <p v-if="loading" class="visit--loading"><loading-beacon /></p>
        <button v-else class="visit--link" @click="clickHandler">
            + {{ label }}
        </button>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * Component to add new visit forms
 */
import { functions } from 'helpers/firebase'
export default {
    props: {
        label: {
            type: String,
            default: '+',
        },
        totalVisits: {
            type: Number,
            default: 1,
        },
        clickHandler: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...vuex.mapGetters({
            activeProject: 'projects/activeProject',
            patient: 'patient/patient',
        }),
    },
    watch: {
        totalVisits: function(newVal) {
            if (typeof parseInt(newVal, 10) === 'number') {
                this.handleAddFollowUpClick(newVal)
            }
        },
    },
    methods: {
        handleAddFollowUpClick(visit) {
            this.loading = true
            var addForms = functions.httpsCallable('addForms')
            addForms({
                numberOfVisit: visit,
                projectSlug: this.activeProject.slug,
                patientId: this.patient.patientId,
            })
                .then(result => {
                    this.loading = false

                    return this.showToast({
                        type: 'success',
                        message: result.data,
                    })
                })

                .catch(err => {
                    this.loading = false
                    this.showToast({
                        type: 'error',
                        message: 'Error adding form- ' + err,
                    })
                })
        },
        ...vuex.mapActions({
            showToast: 'ui/showToast',
        }),
    },
}
</script>

<style lang="scss">
.visit {
    &--link {
        @apply h-5 font-display flex no-underline text-base font-semibold align-middle text-primary-700 hover:text-primary-600 transform;
        &:active {
            @apply scale-105;
        }
        &:focus {
            @apply outline-none;
        }
    }
    &--loading {
        @apply flex self-center bg-purple-200 rounded-2xl p-1 mx-auto;
    }
}
</style>
