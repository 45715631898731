<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="grid grid-cols-2 gap-x-12 md:gap-x-24">
                <formulate-schema v-model="form" :schema="schema" />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'LungFunctionBex',
    mixins: [visitData],
    computed: {
        schema() {
            const schema = [
                {
                    name: 'spirometry.date',
                    label: this.__('forms.spirometry.date'),
                    placeholder: this.__('forms.spirometry.date'),
                    type: 'date',
                },
                { component: 'div', id: 'spacer1' },
                {
                    name: 'spirometry.FEV1-L',
                    label: this.__('forms.spirometry.FEV1-L'),
                    placeholder: this.__('forms.spirometry.FEV1-L'),
                    type: 'text',
                },
                {
                    name: 'spirometry.FEV1-percent',
                    label: this.__('forms.spirometry.FEV1-percent'),
                    placeholder: this.__('forms.spirometry.FEV1-percent'),
                    type: 'text',
                },
                {
                    name: 'spirometry.FVC-L',
                    label: this.__('forms.spirometry.FVC-L'),
                    placeholder: this.__('forms.spirometry.FVC-L'),
                    type: 'text',
                },
                {
                    name: 'spirometry.FVC-percent',
                    label: this.__('forms.spirometry.FVC-percent'),
                    placeholder: this.__('forms.spirometry.FVC-percent'),
                    type: 'text',
                },
                {
                    name: 'spirometry.FEVtoFVC',
                    label: this.__('forms.spirometry.FEVtoFVC'),
                    placeholder: this.__('forms.spirometry.FEVtoFVC'),
                    type: 'text',
                },

                {
                    name: 'spirometry.PEF',
                    label: this.__('forms.spirometry.PEF'),
                    placeholder: this.__('forms.spirometry.PEF'),
                    type: 'text',
                },
                {
                    name: 'spirometry.TLC-L',
                    label: this.__('forms.spirometry.TLC-L'),
                    placeholder: this.__('forms.spirometry.TLC-L'),
                    type: 'text',
                },
                {
                    name: 'spirometry.TLC-percent',
                    label: this.__('forms.spirometry.TLC-percent'),
                    placeholder: this.__('forms.spirometry.TLC-percent'),
                    type: 'text',
                },
                {
                    name: 'spirometry.RV-L',
                    label: this.__('forms.spirometry.RV-L'),
                    placeholder: this.__('forms.spirometry.RV-L'),
                    type: 'text',
                },
                {
                    name: 'spirometry.RV-percent',
                    label: this.__('forms.spirometry.RV-percent'),
                    placeholder: this.__('forms.spirometry.RV-percent'),
                    type: 'text',
                },
                {
                    name: 'spirometry.RVtoTLC',
                    label: this.__('forms.spirometry.RVtoTLC'),
                    placeholder: this.__('forms.spirometry.RVtoTLC'),
                    type: 'text',
                },
                { component: 'div', id: 'spacer2' },
                {
                    name: 'spirometry.DLCO',
                    label: this.__('forms.spirometry.DLCO'),
                    placeholder: this.__('forms.spirometry.DLCO'),
                    type: 'text',
                },
                {
                    name: 'spirometry.DLCO-percent',
                    label: this.__('forms.spirometry.DLCO-percent'),
                    placeholder: this.__('forms.spirometry.DLCO-percent'),
                    type: 'text',
                },
                {
                    name: 'spirometry.FenO',
                    label: this.__('forms.spirometry.FenO'),
                    placeholder: this.__('forms.spirometry.FenO'),
                    type: 'text',
                },
            ]
            return schema
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
    },
}
</script>
