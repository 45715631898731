<template>
    <div class="uploader">
        <progress-bar
            v-show="currentUpload.progress > 0 && !currentUpload.completed"
            :progress="currentUpload.progress ? currentUpload.progress : 0"
            :message="currentUpload.status ? currentUpload.status : ''"
        />

        <formulate-input
            v-if="!currentUpload.completed"
            type="file"
            name="file"
            :label="label"
            data-cypress="dropzone"
            :help="help"
            class="mt-5 max-w-md mx-auto text-xs
            overflow-auto"
            @change="detectFiles($event.target.files)"
        />

        <div
            v-if="currentUpload.completed"
            class="success-box bg-green-200 rounded-lg text-green-800 text-center p-4 mb-8"
        >
            <div>
                <font-awesome-icon :icon="['fas', 'check-circle']" size="2x" />
            </div>
            <h4 class="text-base font-bold px-8 mt-4">
                {{ successText }}
            </h4>
        </div>

        <div
            v-if="currentUpload.error"
            class="error-box bg-red-200 rounded-lg text-red-800 text-center p-4"
        >
            <div>
                <font-awesome-icon :icon="['fas', 'check-circle']" size="2x" />
            </div>
            <h4 class="text-sm font-bold pb-0 mt-4 mb-2 ">
                {{ __('forms.data.error') }}
            </h4>
            <p class="text-sm mb-4">
                {{ __('forms.data.status') }}: {{ currentUpload.status }}
            </p>
            <basic-button color="gray" @click="resetUpload(iontype)">{{
                __('uploader.button.tryagain')
            }}</basic-button>
        </div>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * Renders a dropzone on which files can be uploaded. The files will then be uploaded using the uploadFile Action from store/patient
 */
export default {
    inheritAttrs: true,
    props: {
        // Label Text for the dropzone uploader
        label: {
            type: String,
            default: null,
        },
        help: {
            type: String,
            default: '',
        },
        successText: {
            type: String,
            default: '',
        },
        // Ion Type of the upload (pos / neg)
        iontype: {
            // String with either `pos` or `neg`
            type: String,
            required: true,
            validator: value => {
                // The filetype must a specific type
                return ['pos', 'neg'].indexOf(value) !== -1
            },
        },

        // Filetype of the uploaded file. Either RAW or C02
        filetype: {
            type: String,
            default: 'raw',
            validator: value => {
                // The filetype must a specific type
                return ['raw', 'co2'].indexOf(value) !== -1
            },
        },

        visit: {
            // Number with either '1' or '2'
            type: Number,
            required: true,
        },
    },
    computed: {
        currentUpload() {
            return this.upload[this.iontype][this.filetype]
                ? this.upload[this.iontype][this.filetype]
                : null
        },

        ...vuex.mapState('patient', {
            upload: state => state.upload,
        }),
    },
    methods: {
        detectFiles(fileList) {
            Array.from(Array(fileList.length).keys()).map(x => {
                this.uploadFile({
                    file: fileList[x],
                    filetype: this.filetype,
                    iontype: this.iontype,
                    visit: this.visit,
                })
            })
        },

        ...vuex.mapActions('patient', ['uploadFile', 'resetUpload']),
    },
}
</script>
