<template>
    <default-app-template>
        <div class="my-8 mx-16">
            <headline centered>{{ __('forms.data.title') }}</headline>
            <h3
                class="text-lg text-center text-primary-700 font-bold mb-8 pb-0"
            >
                {{ __('forms.data.subtitle') }}
            </h3>
            <div class="flex justify-between  space-x-16">
                <div class="w-1/2">
                    <data-upload-box :visit="visit" iontype="pos" />
                </div>
                <div class="w-1/2">
                    <data-upload-box :visit="visit" iontype="neg" />
                </div>
            </div>

            <div class="flex justify-end mt-8">
                <basic-button
                    :loading="loadingPatient"
                    data-cypress="data.button.back"
                    :to="{
                        name: 'Dashboard',
                    }"
                    >{{ __('button.back') }}
                </basic-button>
            </div>
        </div>
    </default-app-template>
</template>

<script>
/**
 * @group ORGANISMS
 * A file uploader component that renders two uploaders for POS and NEG files
 */
export default {
    computed: {
        visit() {
            return parseInt(this.$route.params.visitNumber)
        },

        ...vuex.mapState('patient', {
            upload: state => state.upload,
        }),

        ...vuex.mapGetters('patient', ['loadingPatient']),
    },

    created() {
        this.resetUpload()
    },

    methods: {
        ...vuex.mapActions('patient', ['resetUpload']),
    },
}
</script>
