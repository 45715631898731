<template>
    <nav class="flex items-center content-between  bg-gray-200 py-2 sm:py-0">
        <div class="flex items-center md:mr-10">
            <unicolor-logo
                class="navbar__logo sm:w-full sm:px-10"
                alt="Deep Breath Iniative"
                data-cypress="navbar.logo"
                @click="handleLogoClick"
            />
        </div>
        <div class="flex flex-grow content-between items-center">
            <div
                class="navbar__status"
                :class="{ 'navbar__status--active': activeProject !== null }"
            >
                <font-awesome-icon
                    :icon="['fas', 'wind']"
                    size="2x"
                    class="mr-3"
                />
                <span
                    v-if="activeProject !== null"
                    data-cypress="navbar.activeProject"
                    >{{ activeProject.title }}</span
                >
                <span v-else>{{ __('navbar.noActiveProject') }}</span>
            </div>
            <div
                class="navbar__status cursor-pointer"
                :class="{ 'navbar__status--active': patient !== null }"
                @click="handlePatientClick"
            >
                <font-awesome-icon
                    :icon="['fas', 'user']"
                    size="lg"
                    class="mr-3"
                />
                <span v-if="patient !== null" data-cypress="navbar.patientId">{{
                    patient.patientId
                }}</span>
                <span v-else data-cypress="navbar.patientId">{{
                    __('navbar.noActivePatient')
                }}</span>
            </div>
        </div>
        <div class="flex">
            <v-popover offset="10" class="m-8">
                <template slot="popover">
                    <navbar-dropdown :menu="userMenu" title="User" />
                </template>
                <a
                    class="tooltip-target no-underline cursor-pointer text-secondary-700"
                    title="User Menu"
                    data-cypress="navbar.userMenu.button"
                >
                    <font-awesome-icon :icon="['fas', 'user-md']" size="2x" />
                </a>
            </v-popover>
        </div>
    </nav>
</template>

<script>
import UnicolorLogo from 'assets/img/UnicolorLogo.svg'

/**
 * @group MOLECULES
 * Navbar with Logo, Patient, App and Dropdown Menu
 */
export default {
    components: {
        UnicolorLogo,
    },
    data() {
        return {
            userMenu: [
                {
                    title: this.__('exportData'),
                    routeName: 'ExportData',
                    icon: 'file-export',
                    dataCypress: 'navbar.userMenu.settings.exportdata',
                },
                {
                    title: this.__('support.link'),
                    routeName: 'Support',
                    icon: 'life-ring',
                    dataCypress: 'navbar.userMenu.settings.exportdata',
                },
                {
                    title: this.__('logout'),
                    routeName: 'Logout',
                    icon: 'sign-out-alt',
                    dataCypress: 'navbar.userMenu.settings.logout',
                },
            ],
        }
    },
    computed: {
        ...vuex.mapGetters({
            activeProject: 'projects/activeProject',
            patient: 'patient/patient',
        }),
    },
    methods: {
        handleLogoClick() {
            if (this.$router.history.current.name === 'Home') {
                return
            }

            if (this.patient) {
                return this.handlePatientClick()
            }

            this.resetActiveProject().then(() => {
                this.resetPatient()
                this.$router.push({
                    name: 'Home',
                })
            })
        },
        handlePatientClick() {
            if (
                this.$router.history.current.name === 'Dashboard' ||
                this.patient === null
            ) {
                return
            }
            this.$router.push({
                name: 'Dashboard',
                params: {
                    projectSlug: this.activeProject.slug,
                    patientId: this.patient.patientId,
                },
            })
        },
        ...vuex.mapActions({
            resetActiveProject: 'projects/resetActiveProject',
            resetPatient: 'patient/resetPatient',
        }),
    },
}
</script>
<style lang="scss" scoped>
.navbar {
    @apply flex items-center content-between bg-gray-200 py-2;

    &__logo {
        @apply fill-current text-primary-700 px-4 h-auto py-4 cursor-pointer;
    }

    &__status {
        @apply flex flex-grow text-base text-secondary-300 items-center;

        &--active {
            @apply text-primary-500;
        }
    }
}
</style>
