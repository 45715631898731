/*
|-------------------------------------------------------------------------------
| VUEX index.js
|-------------------------------------------------------------------------------
| Builds the data store from all of the modules for app
*/
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import ui from './ui'
import patient from './patient'
import projects from './projects'
import operators from './operators'

Vue.use(Vuex)

const store = new Vuex.Store({
    /*
     * STRICT MODE
     * ------
     * In strict mode, whenever Vuex state is mutated outside of mutation handlers, an error will be thrown.
     * This ensures that all state mutations can be explicitly tracked by debugging tools.
     * Its resource expensive and should only be enabled during dev.
     */
    strict: false,
    modules: {
        auth,
        ui,
        patient,
        projects,
        operators,
    },
})
export default store
