<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col w-full">
                <!-- questionnaires -->
                <div v-for="field in questionnaire" :key="field" class="my-1">
                    <div
                        class="grid grid-cols-2 gap-x-12 mb-1 md:gap-x-24"
                        :class="
                            field === 'mMRCscore' ? 'firstLetterLowercase' : ''
                        "
                    >
                        <formulate-schema
                            v-model="form"
                            :schema="schema.questionnaire[field]"
                        />
                        <formulate-schema
                            v-model="form"
                            :schema="schema.date"
                        />
                    </div>
                </div>
                <!-- SF-36 -->
                <div class="my-2">
                    <headline tag="h3">{{ __('forms.sf36') }}</headline>
                    <div class="grid grid-cols-2 gap-x-12 mb-1 md:gap-x-24">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.date"
                        />
                    </div>
                    <div class="grid grid-cols-2 gap-x-12 mb-1 md:gap-x-24">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.sf36"
                        />
                    </div>
                </div>
            </div>
            <div class="flex"></div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'QuestionnaireBex',
    mixins: [visitData],
    data() {
        return {
            validation: { hasErrors: false },
            questionnaire: [
                'CATScore',
                'hassScore',
                'actScore',
                'mMRCscore',
                'nyhaScore',
                'essScore',
            ],
            sf36Fields: [
                'physicalHealthCS',
                'mentalHealthCS',
                'physicalFunctioning',
                'roleLimitationsPhy',
                'roleLimitationsEmo',
                'energyOrFatigue',
                'emotionalWellBeing',
                'socialFunctioning',
                'pain',
                'genHealth',
            ],
        }
    },
    computed: {
        schema() {
            const schema = {
                date: [
                    {
                        name: 'date',
                        label: this.__('date'),
                        type: 'date',
                    },
                ],
                questionnaire: {
                    CATScore: [
                        {
                            name: 'CATScore',
                            label: this.__('forms.CATScore'),
                            placeholder: this.__('forms.CATScore'),
                            type: 'text',
                        },
                    ],
                    hassScore: [
                        {
                            name: 'hassScore',
                            label: this.__('forms.hassScore'),
                            placeholder: this.__('forms.hassScore'),
                            type: 'text',
                        },
                    ],
                    actScore: [
                        {
                            name: 'actScore',
                            label: this.__('forms.actScore'),
                            placeholder: this.__('forms.actScore'),
                            type: 'text',
                        },
                    ],
                    mMRCscore: [
                        {
                            name: 'mMRCscore',
                            label: this.__('forms.mMRCscore'),
                            placeholder: this.__('forms.mMRCscore'),
                            type: 'text',
                        },
                    ],
                    nyhaScore: [
                        {
                            name: 'nyhaScore',
                            label: this.__('forms.nyhaScore'),
                            placeholder: this.__('forms.nyhaScore'),
                            type: 'text',
                        },
                    ],
                    essScore: [
                        {
                            name: 'essScore',
                            label: this.__('forms.essScore'),
                            placeholder: this.__('forms.essScore'),
                            type: 'text',
                        },
                    ],
                },
                sf36: [
                    {
                        name: 'physicalHealthCS',
                        label: this.__('forms.physicalHealthCS'),
                        placeholder: this.__('forms.physicalHealthCS'),
                        type: 'text',
                    },
                    {
                        name: 'mentalHealthCS',
                        label: this.__('forms.mentalHealthCS'),
                        placeholder: this.__('forms.mentalHealthCS'),
                        type: 'text',
                    },
                    {
                        name: 'physicalFunctioning',
                        label: this.__('forms.physicalFunctioning'),
                        placeholder: this.__('forms.physicalFunctioning'),
                        type: 'text',
                    },
                    {
                        name: 'roleLimitationsPhy',
                        label: this.__('forms.roleLimitationsPhy'),
                        placeholder: this.__('forms.roleLimitationsPhy'),
                        type: 'text',
                    },
                    {
                        name: 'roleLimitationsEmo',
                        label: this.__('forms.roleLimitationsEmo'),
                        placeholder: this.__('forms.roleLimitationsEmo'),
                        type: 'text',
                    },
                    {
                        name: 'energyOrFatigue',
                        label: this.__('forms.energyOrFatigue'),
                        placeholder: this.__('forms.energyOrFatigue'),
                        type: 'text',
                    },
                    {
                        name: 'emotionalWellBeing',
                        label: this.__('forms.emotionalWellBeing'),
                        placeholder: this.__('forms.emotionalWellBeing'),
                        type: 'text',
                    },
                    {
                        name: 'socialFunctioning',
                        label: this.__('forms.socialFunctioning'),
                        placeholder: this.__('forms.socialFunctioning'),
                        type: 'text',
                    },
                    {
                        name: 'pain',
                        label: this.__('forms.pain'),
                        placeholder: this.__('forms.pain'),
                        type: 'text',
                    },
                    {
                        name: 'genHealth',
                        label: this.__('forms.genHealth'),
                        placeholder: this.__('forms.genHealth'),
                        type: 'text',
                    },
                ],
            }
            return schema
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
    },
}
</script>

<style lang="scss">
.firstLetterLowercase {
    .formulate-input {
        .formulate-input-label::first-letter {
            @apply lowercase;
        }
    }
}
</style>
