<template>
    <button
        class="m-button"
        type="button"
        :class="[cssClasses]"
        :disabled="loading || disabled"
        v-on="$listeners"
        @click="clickHandler"
    >
        <template v-if="icon && !iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="mr-2" />
        </template>
        <template v-if="loading">
            <loading-beacon />
        </template>
        <template v-else>
            <slot />
        </template>
        <template v-if="icon && iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="ml-2" />
        </template>
    </button>
</template>

<script>
/**
 * @group ATOMS
 * Component to render a nice button
 */
export default {
    $inheritAttrs: true,
    props: {
        /**
         * Loading state of the button with a spinner (it's disabled while loading).
         * The used spinner is defined in LoadingSpinner.
         */
        loading: {
            type: Boolean,
            default: false,
        },
        // Disables the button so it cannot be clicked.
        disabled: {
            type: Boolean,
            default: false,
        },

        // Adds an icon to the button from FAS. Icon must be loaded manually in the fontAwesome.js
        icon: {
            type: String,
            default: null,
        },
        // Per default, the icon is on the left. Set this to true if you want the icon on the right.
        iconRight: {
            type: Boolean,
            default: false,
        },
        // Vue Router link. Example: `to="{name: 'someRouteName'}"`
        to: {
            type: Object,
            default: null,
        },

        // Shorthand for Vue Router. Simply provide the the route name as String
        route: {
            type: String,
            default: null,
        },

        // Color of the button. Can either be `gray` , 'lightgray, `red` or `primary`
        color: {
            type: String,
            default: 'primary',
            validator: value => {
                // The filetype must a specific type
                return (
                    ['gray', 'lightgray', 'red', 'primary'].indexOf(value) !==
                    -1
                )
            },
        },

        // Size of the button. Can either be `xs`, `sm`, `base` or 'lg'
        size: {
            type: String,
            required: false,
            default: 'base',
            validator: value => {
                // The filetype must a specific type
                return ['xs', 'sm', 'base', 'lg'].indexOf(value) !== -1
            },
        },
    },

    computed: {
        cssClasses() {
            let cssArray = [this.color, this.size]
            if (this.loading) {
                cssArray.push('loading')
            }
            if (this.disabled) {
                cssArray.push('disabled')
            }

            return cssArray
        },
    },
    methods: {
        clickHandler() {
            if (this.route) {
                this.$router.push({ name: this.capitalize(this.route) })
            } else if (this.to) {
                this.$router.push(this.to)
            }
        },
        capitalize: s => {
            if (typeof s !== 'string') return s
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
    },
}
</script>

<style lang="scss">
.m-button {
    @apply shadow leading-none uppercase font-bold rounded-lg transition duration-300 ease-in-out min-w-40  text-center transform;

    /** 
    * Mouse Overs
    */
    &:hover {
        @apply shadow-lg  outline-none;
    }

    &:focus {
        @apply shadow-md bg-primary-600 outline-none;
    }

    &:active {
        @apply bg-primary-600 scale-95  outline-none;
    }

    /** 
    * Size
    */

    &.narrow {
        @apply px-2 min-w-0;
    }

    &.xs {
        @apply px-2 py-1 min-w-0 text-xs;
    }

    &.sm {
        @apply px-3 py-2 min-w-12 text-sm;
    }

    &.base {
        @apply h-12 px-10 py-0 text-lg;
    }

    &.lg {
        @apply px-5 py-0 min-w-0 text-xl;
    }

    /** 
    * Colors
    */

    &.primary {
        @apply bg-primary-500 text-white;

        &:hover {
            @apply bg-primary-700;
        }
    }

    &.red {
        @apply bg-red-600 text-red-100;

        &:hover {
            @apply bg-red-700;
        }
    }

    &.gray {
        @apply bg-gray-600 text-gray-300;

        &:hover {
            @apply bg-gray-700;
        }
    }

    &.lightgray {
        @apply bg-gray-500 text-white;

        &:hover {
            @apply bg-gray-600;
        }
    }

    /** 
    * Loading / Disable States
    */
    &.disabled {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.disabled:hover {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.loading {
        @apply opacity-75;
    }

    &.loading:hover {
        @apply cursor-not-allowed;
    }
}
</style>
