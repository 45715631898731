<template>
    <div class="flex flex-row">
        <div v-if="mode === 'prefix'" class="mr-3 text-gray-700">
            {{ label }}
        </div>
        <div class="switch__wrapper" @click="handleClick">
            <input
                data-cypress="switch"
                name="toggle"
                type="checkbox"
                class="switch__background"
                :class="{ 'switch__background--checked': checked }"
            />
            <label
                for="toggle"
                class="switch__toggle"
                :class="{ 'switch__toggle--checked': checked }"
            />
        </div>
        <div v-if="mode === 'suffix'" class="ml-3">
            {{ label }}
        </div>
    </div>
</template>
<script>
/**
 * @group ATOMS
 * Simple switch
 */
export default {
    $inheritAttrs: true,
    props: {
        label: {
            type: String,
            default: function() {
                return ''
            },
        },
        checked: {
            type: Boolean,
            default: () => false,
        },
        mode: {
            type: String,
            validator: value => ['prefix', 'suffix'].includes(value),
            default: function() {
                return 'prefix'
            },
        },
        handleClick: {
            type: Function,
            default: () => {},
        },
    },
}
</script>
<style scoped lang="scss">
.switch {
    &__wrapper {
        @apply relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in;
    }

    &__background {
        @apply transition-all duration-500 ease-in-out absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer outline-none;
        left: 0;

        &--checked {
            @apply border-primary-500;
            left: 100%;
            transform: translateX(-100%);
        }
    }

    &__toggle {
        @apply transition-all duration-500 ease-in-out block overflow-hidden h-6 rounded-full bg-gray-200 cursor-pointer outline-none;

        &--checked {
            @apply bg-primary-500;
        }
    }
}
</style>
