import flatten from 'flat'
import { toJson } from 'helpers/common'
import { get } from 'lodash'

/**
 * some infos from visit1 should be copied to visit2 (but as separate entities, so you can then edit them)

 * @param {object} val - new Value from watch
 * @param {object} $this - referrence of `this` from given component
 * @param {array}  whatToCopy - names of fields to copy in "object-style", meaning: `i.want.to.copy.thisVariable`
 * @param {string} docId - name of `forms` document id from firestore, since we only have 2 visits for now, default is `visit2`, however, to be able to use it in the future, additional field was added
 */
export default function(val = {}, $this, whatToCopy = [], docId = 'visit2') {
    const newForm = { docId }

    if (val === null) {
        return
    }

    whatToCopy.forEach(node => {
        const splitted = node.split['.']
        const nodeName =
            node.split['.'] === undefined ? node : splitted[splitted.length - 1]

        if (val.visit1[node]) {
            newForm[nodeName] = get(val.visit1, node)
        }
    })

    $this.form = {
        ...flatten(toJson({ ...newForm, ...val.visit2 }), {
            safe: true,
        }),
    }
}
