/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'

const mutations = {
    SHOW_ALERT: 'SHOW_ALERT',
    PREPARE_ALERT: 'PREPARE_ALERT',
    CLOSE_ALERT: 'CLOSE_ALERT',

    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    TOGGLE_MODAL: 'TOGGLE_MODAL',

    PREPARE_MODAL: 'PREPARE_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
}

export const ui = {
    namespaced: true,
    state: {
        message: { type: null, text: null },
        alert: {
            text: null,
            type: null,
            show: false,
        },
        modal: {
            title: null,
            text: null,
            type: null,
            size: null,
            buttonText: null,
            toRoute: null,
            showCloseButton: null,
            show: false,
        },
        language: 'en',
    },
    mutations: {
        [mutations.PREPARE_MODAL](state, modal) {
            state.modal = modal
        },
        [mutations.SHOW_MODAL](state) {
            state.modal.show = true
        },
        [mutations.TOGGLE_MODAL](state) {
            state.modal.show = !state.modal.show
        },
        [mutations.HIDE_MODAL](state) {
            state.modal.show = false
        },
        [mutations.SET_ERROR_MESSAGE](state, errorMessage) {
            state.message = {
                type: 'error',
                message: errorMessage,
            }
        },
        [mutations.CHANGE_LANGUAGE](state, language) {
            state.language = language
        },
    },
    actions: {
        /** SHOW MODAL
         *
         *  Usage in other modules:
         *  dispatch('ui/setModal', {
            title: null,
            text: null,
            type: null,
            size: null,
            buttonText: null,
            toRoute: null,
            showCloseButton: null,
            show: false,
        }, { root: true })
         *  dispatch('ui/showModal',  { root: true })
         */
        showModal: {
            root: true,
            handler(namespacedContext) {
                namespacedContext.commit(mutations.SHOW_MODAL)
            },
        },
        setModal: {
            root: true,
            handler(namespacedContext, payload) {
                namespacedContext.commit(mutations.PREPARE_MODAL, payload)
            },
        },
        toggleModal: {
            root: true,
            handler(namespacedContext) {
                namespacedContext.commit(mutations.TOGGLE_MODAL)
            },
        },
        setErrorMessage({ commit }, errorMessage) {
            commit(mutations.SET_ERROR_MESSAGE, errorMessage)
        },
        clearErrorMessage(commit) {
            commit(mutations.SET_ERROR_MESSAGE, null)
        },
        showToast(store, { type, message }) {
            // https://stackoverflow.com/questions/60491988/how-to-access-vue-instance-in-vuex/63898857#63898857
            this._vm.$toast[type](message)
        },
        changeLanguage({ commit }, lang) {
            commit(mutations.CHANGE_LANGUAGE, lang)
        },
    },
    getters: {
        showModal: state => state.modal.show,
        error: state => state.message.type === 'error',
        lang: state => state.language,
    },
}

listAllActionsOfStore(ui, 'ui')
listAllGettersOfStore(ui, 'ui')

export default ui
