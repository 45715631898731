<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col flex-1">
                <formulate-input
                    type="group"
                    name="medication"
                    :repeatable="true"
                    :add-label="__('forms.medication.add')"
                >
                    <template v-slot:default="groupProps">
                        <div class="grid grid-cols-2 gap-12 mx-auto mb-10">
                            <div>
                                <formulate-input
                                    name="drugName"
                                    type="text"
                                    :label="__('forms.medication.drugName')"
                                    :placeholder="
                                        __('forms.medication.drugName')
                                    "
                                    data-cypress="form.input.drugAutocomplete"
                                />
                                <formulate-input
                                    name="dosagePerIntake"
                                    :label="
                                        __('forms.medication.dosagePerIntake')
                                    "
                                    :placeholder="
                                        __(
                                            'forms.medication.dosagePerIntakePlaceHolder'
                                        )
                                    "
                                    min="0"
                                    step="any"
                                    type="number"
                                />
                                <formulate-input
                                    name="lastIntake"
                                    :label="__('forms.medication.lastIntakte')"
                                    :placeholder="
                                        __('forms.medication.lastIntakte')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="activeSubstance"
                                    :label="
                                        __('forms.medication.activeSubstance')
                                    "
                                    :placeholder="
                                        __('forms.medication.activeSubstance')
                                    "
                                />
                                <div
                                    class="mt-4 flex text-secondary-700 text-center"
                                >
                                    <div class="px-2">
                                        <label
                                            for="intakeMorning"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunrise']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMorning"
                                            type="number"
                                            :help="
                                                __('forms.medication.morning')
                                            "
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeMidday"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sun']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMidday"
                                            :help="
                                                __('forms.medication.midday')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="mx-2">
                                        <label
                                            for="intakeEvening"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunset']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeEvening"
                                            :help="
                                                __('forms.medication.evening')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeNight"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'moon']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeNight"
                                            :help="__('forms.medication.night')"
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                </div>
                                <div class="py-6">
                                    <font-awesome-icon
                                        class="text-gray-600"
                                        :icon="['fa', 'chevron-square-right']"
                                    />
                                    {{
                                        totalDosageDay(
                                            groupProps.index,
                                            'medication'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </template>
                </formulate-input>
            </div>
            <div class="flex flex-col flex-1">
                <headline tag="h3">{{
                    __('forms.medication.change')
                }}</headline>

                <formulate-input
                    name="changeInMedication"
                    type="select"
                    class="w-full md:w-1/2"
                    :label="__('forms.medication.change')"
                    :options="{
                        '': '',
                        yes: __('yes'),
                        no: __('no'),
                    }"
                />
                <div v-if="showMedicationsChangedForms">
                    <formulate-input
                        type="group"
                        name="medicationV2"
                        :repeatable="true"
                        :add-label="__('forms.medication.add')"
                    >
                        <template v-slot:default="groupProps">
                            <div class="grid grid-cols-2 gap-12 mx-auto mb-10">
                                <div>
                                    <formulate-input
                                        name="drugName"
                                        type="drug"
                                        update-target="medicationV2"
                                        :group-index="groupProps.index"
                                        :label="__('forms.medication.drugName')"
                                        :placeholder="
                                            __('forms.medication.drugName')
                                        "
                                        data-cypress="form.input.drugAutocomplete"
                                        @select="addActiveSubstanceToField"
                                    />
                                    <formulate-input
                                        name="dosagePerIntake"
                                        :label="
                                            __(
                                                'forms.medication.dosagePerIntake'
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'forms.medication.dosagePerIntakePlaceHolder'
                                            )
                                        "
                                        min="0"
                                        step="any"
                                        type="number"
                                    />
                                    <formulate-input
                                        name="lastIntake"
                                        :label="
                                            __('forms.medication.lastIntakte')
                                        "
                                        :placeholder="
                                            __('forms.medication.lastIntakte')
                                        "
                                        min="0"
                                        type="datetime-local"
                                    />
                                </div>
                                <div>
                                    <formulate-input
                                        name="activeSubstance"
                                        :label="
                                            __(
                                                'forms.medication.activeSubstance'
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'forms.medication.activeSubstance'
                                            )
                                        "
                                    />
                                    <div
                                        class="mt-4 flex text-secondary-700 text-center"
                                    >
                                        <div class="px-2">
                                            <label
                                                for="intakeMorning"
                                                class="block mb-2"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', 'sunrise']"
                                                />
                                            </label>
                                            <formulate-input
                                                name="intakeMorning"
                                                type="number"
                                                :help="
                                                    __(
                                                        'forms.medication.morning'
                                                    )
                                                "
                                                min="0"
                                                value="0"
                                                class="centered"
                                            />
                                        </div>
                                        <div class="px-2">
                                            <label
                                                for="intakeMidday"
                                                class="block mb-2"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', 'sun']"
                                                />
                                            </label>
                                            <formulate-input
                                                name="intakeMidday"
                                                :help="
                                                    __(
                                                        'forms.medication.midday'
                                                    )
                                                "
                                                type="number"
                                                min="0"
                                                value="0"
                                                class="centered"
                                            />
                                        </div>
                                        <div class="mx-2">
                                            <label
                                                for="intakeEvening"
                                                class="block mb-2"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', 'sunset']"
                                                    class="mr-2"
                                                />
                                            </label>
                                            <formulate-input
                                                name="intakeEvening"
                                                :help="
                                                    __(
                                                        'forms.medication.evening'
                                                    )
                                                "
                                                type="number"
                                                min="0"
                                                value="0"
                                                class="centered"
                                            />
                                        </div>
                                        <div class="px-2">
                                            <label
                                                for="intakeNight"
                                                class="block mb-2"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', 'moon']"
                                                    class="mr-2"
                                                />
                                            </label>
                                            <formulate-input
                                                name="intakeNight"
                                                :help="
                                                    __('forms.medication.night')
                                                "
                                                type="number"
                                                min="0"
                                                value="0"
                                                class="centered"
                                            />
                                        </div>
                                    </div>
                                    <div class="py-6">
                                        <font-awesome-icon
                                            class="text-gray-600"
                                            :icon="[
                                                'fa',
                                                'chevron-square-right',
                                            ]"
                                        />
                                        {{
                                            totalDosageDay(
                                                groupProps.index,
                                                'medicationV2'
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </formulate-input>
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import copySomeFromVisit1 from 'helpers/watchers/copySomeFromVisit1'
import submitFormPartial from 'helpers/forms/submitFormPartial'
import flatten from 'flat'

export default {
    name: 'MedicationSharedV2',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        showMedicationsChangedForms() {
            const field = 'changeInMedication'
            if (this.form[field]) {
                return this.form[field] === 'yes'
            }
            return false
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        forms: {
            handler: function(newVal) {
                return copySomeFromVisit1(newVal, this, ['medication'])
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        submit() {
            const { medicationV2, changeInMedication } = flatten.unflatten(
                this.form
            )

            submitFormPartial(this, {
                docId: this.form.docId,
                changeInMedication,
                medicationV2,
            })
        },

        // Sets the active substance based on the selected search entry
        addActiveSubstanceToField(entry, index) {
            // Sets the value on the medication-item
            this.form.medicationV2[index] = {
                ...this.form.medicationV2[index],
                activeSubstance: entry.activeSubstance,
            }
        },

        totalDosageDay(index, whichVisit) {
            if (!this.form[whichVisit] || index === undefined) {
                return
            }

            const intakes = [
                'intakeMorning',
                'intakeMidday',
                'intakeEvening',
                'intakeNight',
            ]

            const block = this.form[whichVisit][index]

            const dosage = parseFloat(block.dosagePerIntake)

            const { total, intakesAvailable } = intakes.reduce(
                (accu, currentval) => {
                    if (block[currentval] !== '0') {
                        const int = parseInt(block[currentval])

                        accu.total += int
                        accu.intakesAvailable += 1
                    }
                    return accu
                },
                { total: 0, intakesAvailable: 0 }
            )

            if (intakesAvailable !== 0 && !isNaN(dosage)) {
                return this.__('forms.medication.totalDosagePerDay', [
                    _.round(total * dosage, 3),
                ])
            }
            return this.__('forms.medication.totalDosagePerDay', ['--'])
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
