<template>
    <default-app-template>
        <div
            class="flex flex-col-reverse w-max mx-auto mb-12 md:flex-row md:justify-between md:w-auto"
        >
            <div class="hidden self-center text-primary-700 font-bold md:block">
                {{ activeProject.title }}
            </div>
            <basic-button
                color="gray"
                icon="user-slash"
                data-cypress="patient.checkout"
                @click="handlePatientCheckout"
            >
                {{ __('patient.checkout') }}
            </basic-button>
        </div>
        <div
            v-if="isloading || !isFormsAndPatientPresent"
            class="dashboard--loading"
        >
            <loading />
        </div>
        <div v-else-if="isFormsAndPatientPresent">
            <div class="dashboard--link">
                <add-visit-link
                    v-if="numberOfVisit"
                    :click-handler="handleIncrementVisit"
                    :total-visits="visitCount"
                    :label="__('patient.addVisit')"
                    class="py-4 mx-auto md:mx-0"
                />
                <basic-switch
                    :checked="patient.studyCompleted"
                    mode="prefix"
                    :label="__('patient.StudyDataCompleted')"
                    :handle-click="handleSwitchClick"
                    class="py-4 mx-auto md:mx-0"
                />
            </div>
            <div class="dashboard--content">
                <div
                    v-for="(row, index) in rowOrderFormsGrid"
                    :key="index"
                    class="dashboard--row"
                >
                    <patient-form-card
                        v-for="node in row"
                        :key="node"
                        :form="forms[node]"
                        :all-forms-completed="patient.studyCompleted"
                        :handle-click="handleFormClick"
                        class="dashboard--card"
                        :data-cypress="`patientForm.${activeProject.slug}`"
                    />
                </div>
            </div>
        </div>
    </default-app-template>
</template>

<script>
/**
 * @group MOLECULES
 * Checkin form to search for a patient based on PatientID and check-in
 */

import { transpose, sortFormsInto2DArray, grid } from '@/helpers/grid'
export default {
    data() {
        return {
            visitCount: 0,
            // grid: []
        }
    },
    computed: {
        isloading: function() {
            return this.loadingPatient || this.loadingForms
        },
        isFormsAndPatientPresent: function() {
            return (
                this.forms !== null &&
                Object.keys(this.forms).length >= 1 &&
                this.patient !== null
            )
        },
        // New forms grid row by row implementation
        rowOrderFormsGrid: function() {
            const formsGrid = []
            sortFormsInto2DArray(this.forms)
            formsGrid.push(grid.one, grid.two, grid.three)
            // // Swap rows and columns
            const transposed = transpose(formsGrid)
            const sortedDescOrder = transposed.slice().reverse()
            return sortedDescOrder
        },

        numberOfVisit: {
            // retrieve the number of forms present
            get: function() {
                let length = Object.keys(this.forms).length
                return this.forms && length % 3 === 0 && length / 3
            },
            set: function(value) {
                this.visitCount = value
            },
        },
        ...vuex.mapGetters({
            patient: 'patient/patient',
            loadingPatient: 'patient/loadingPatient',
            forms: 'patient/patientForms',
            loadingForms: 'patient/loadingForms',
            activeProject: 'projects/activeProject',
        }),
    },

    methods: {
        handlePatientCheckout() {
            this.resetPatient().then(() => {
                this.$router.push({
                    name: 'Home',
                })
            })
        },

        /**
         * used to extract route parameters from the form grid title
         * @param {string} value - string or ID to parse
         * @param {string} character - separator character to find in the value provided
         */
        handleDynamicRouteParsing(value, separator) {
            let name = ''
            const split = value.split(separator)
            const firstPhrase = split[0]
            const num = firstPhrase.match(/\d+/)
            const visitNumber = num && num.join('')
            if (grid.one.includes(value)) {
                name = 'Screening'
            }
            if (grid.two.includes(value)) {
                name = 'Visit'
            }
            if (grid.three.includes(value)) {
                name = 'DataUpload'
            }
            return {
                name,
                params: {
                    visitNumber: visitNumber || null,
                },
            }
        },

        handleFormClick({ docId }) {
            const visitRoute = this.handleDynamicRouteParsing(docId, '-')
            const routes = { [docId]: visitRoute }

            this.enterForm(docId)
                .then(() => {
                    this.$router.push({
                        ...routes[docId],
                    })
                })
                .catch({})
        },

        handleSwitchClick() {
            this.setStudycomplete(!this.patient.studyCompleted)
        },

        handleIncrementVisit: function() {
            const increment = 1
            // call setter to increment visitCount data property
            this.numberOfVisit = this.numberOfVisit + increment
        },
        ...vuex.mapActions({
            enterForm: 'patient/enterForm',
            resetPatient: 'patient/resetPatient',
            setStudycomplete: 'patient/setStudycomplete',
            showToast: 'ui/showToast',
        }),
    },
}
</script>

<style lang="scss" scoped>
.dashboard {
    &--loading {
        @apply flex flex-grow justify-center mt-8 mb-8;
    }

    &-content {
        @apply flex flex-col flex-grow pb-4 md:flex-row;
    }

    &--row {
        @apply container flex flex-grow flex-col justify-center border-b border-gray-300 pb-2 mb-8 md:flex-row;
        &:last-child {
            @apply border-b-0;
        }
    }
    &--link {
        @apply flex flex-col-reverse p-2 justify-between align-middle md:flex-row md:justify-between;
    }
    &--card {
        @apply mx-6 flex flex-grow md:w-60;
        &:first-child {
            @apply md:ml-0;
        }
        &:last-child {
            @apply md:mr-0;
        }
    }
}
</style>
