<template>
    <div>
        <headline tag="h1" class-name="w-full text-center text-primary-300">{{
            __('forms.visitFinish', ['2'])
        }}</headline>
        <div>
            <div class="flex flex-grow justify-center w-full my-16">
                <formulate-input
                    v-model="inProgress"
                    type="checkbox"
                    :label="__('forms.visitCompletedTick', ['2'])"
                    data-cypress="form.completedTick"
                />
            </div>

            <div
                v-if="form.status"
                class="flex flex-grow justify-center w-full my-16"
            >
                <div>
                    <simple-button
                        type="button"
                        data-cypress="visitFinish.backToOverview"
                        class-names="mr-8"
                        :handle-click="handleBacktoOverview"
                        gray
                    >
                        {{ __('forms.backToOverview', [activeProject.title]) }}
                    </simple-button>
                </div>
                <div>
                    <simple-button
                        type="button"
                        data-cypress="visitFinish.continue"
                        :handle-click="handleContinue"
                        :disabled="form.status === formsStatus.inProgress"
                    >
                        {{ __('forms.uploadMeasurements') }}
                    </simple-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formsStatus } from 'helpers/constants'
import { toJson } from 'helpers/common'
import formObjectWatch from 'helpers/watchers/formObjectWatch'

export default {
    name: 'Visit2FinishShared',
    data() {
        return {
            form: {},
            validation: {},
            formsStatus,
        }
    },
    computed: {
        inProgress: {
            get() {
                return this.form.status === formsStatus.finished
            },
            set(v) {
                this.form.status =
                    v === true ? formsStatus.finished : formsStatus.inProgress
            },
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
            activeProject: 'projects/activeProject',
        }),
    },
    watch: {
        'forms.visit2': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        handleBacktoOverview() {
            if (
                this.$router.history.current.name === 'Dashboard' ||
                this.patient === null
            ) {
                return
            }
            this.$router.push({
                name: 'Dashboard',
            })
        },
        handleContinue() {
            const toast = {
                type: 'success',
                message: this.__('toast.saveSuccessful'),
            }

            this.updateForm(
                toJson({ docId: this.form.docId, status: this.form.status })
            )
                .then(() => {
                    this.$router.push({
                        name: 'DataUpload',
                        params: {
                            visitNumber: 2,
                        },
                    })
                })
                .catch(e => {
                    console.error(`${this.constructor.name}`, e)
                    toast.type = 'error'
                    toast.message = this.__('toast.saveProblem')
                })
                .finally(() => {
                    this.showToast(toast)
                })
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
