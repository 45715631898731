<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex flex-col flex-1">
                <formulate-input
                    name="quitQuestionnaire"
                    type="select"
                    class="w-full md:w-1/2 pr-10"
                    :label="__('forms.medication.QUITQuestionnaire')"
                    :options="{
                        '': '',
                        adherent: __('adherent'),
                        nonAdherent: __('non-adherent'),
                    }"
                />
                <headline tag="h3">{{
                    __('forms.trxspecifics.initiationAntibiotic')
                }}</headline>
                <formulate-input
                    type="group"
                    name="antibiotic"
                    :repeatable="true"
                    :add-label="__('forms.medication.add')"
                >
                    <template v-slot:default="groupProps">
                        <div class="grid grid-cols-2 gap-12 mx-auto mb-10">
                            <div>
                                <formulate-input
                                    name="drugName"
                                    type="drug"
                                    :group-index="groupProps.index"
                                    :label="__('forms.medication.drugName')"
                                    :placeholder="
                                        __('forms.medication.drugName')
                                    "
                                    data-cypress="form.input.drugAutocomplete"
                                    @select="
                                        addActiveSubstanceToAntibioticField
                                    "
                                />
                                <formulate-input
                                    name="dosagePerIntake"
                                    :label="
                                        __('forms.medication.dosagePerIntake')
                                    "
                                    :placeholder="
                                        __(
                                            'forms.medication.dosagePerIntakePlaceHolder'
                                        )
                                    "
                                    min="0"
                                    step="any"
                                    type="number"
                                />
                                <formulate-input
                                    name="lastIntake"
                                    :label="__('forms.medication.lastIntakte')"
                                    :placeholder="
                                        __('forms.medication.lastIntakte')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                                <formulate-input
                                    name="startDate"
                                    :label="__('forms.medication.startDate')"
                                    :placeholder="
                                        __('forms.medication.startDate')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="activeSubstance"
                                    :label="
                                        __('forms.medication.activeSubstance')
                                    "
                                    :placeholder="
                                        __('forms.medication.activeSubstance')
                                    "
                                />
                                <div
                                    class="mt-4 flex text-secondary-700 text-center"
                                >
                                    <div class="px-2">
                                        <label
                                            for="intakeMorning"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunrise']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMorning"
                                            type="number"
                                            :help="
                                                __('forms.medication.morning')
                                            "
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeMidday"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sun']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMidday"
                                            :help="
                                                __('forms.medication.midday')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="mx-2">
                                        <label
                                            for="intakeEvening"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunset']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeEvening"
                                            :help="
                                                __('forms.medication.evening')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeNight"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'moon']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeNight"
                                            :help="__('forms.medication.night')"
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                </div>
                                <div class="py-6">
                                    <font-awesome-icon
                                        class="text-gray-600"
                                        :icon="['fa', 'chevron-square-right']"
                                    />
                                    {{
                                        totalDosageDay(
                                            groupProps.index,
                                            'medication'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </template>
                </formulate-input>
                <headline tag="h3">{{
                    __('forms.trxspecifics.initiationSteroid')
                }}</headline>
                <formulate-input
                    type="group"
                    name="steroids"
                    :repeatable="true"
                    :add-label="__('forms.medication.add')"
                >
                    <template v-slot:default="groupProps">
                        <div class="grid grid-cols-2 gap-12 mx-auto mb-10">
                            <div>
                                <formulate-input
                                    name="drugName"
                                    type="drug"
                                    :group-index="groupProps.index"
                                    :label="__('forms.medication.drugName')"
                                    :placeholder="
                                        __('forms.medication.drugName')
                                    "
                                    data-cypress="form.input.drugAutocomplete"
                                    @select="addActiveSubstanceToSteroidField"
                                />
                                <formulate-input
                                    name="dosagePerIntake"
                                    :label="
                                        __('forms.medication.dosagePerIntake')
                                    "
                                    :placeholder="
                                        __(
                                            'forms.medication.dosagePerIntakePlaceHolder'
                                        )
                                    "
                                    min="0"
                                    step="any"
                                    type="number"
                                />
                                <formulate-input
                                    name="lastIntake"
                                    :label="__('forms.medication.lastIntakte')"
                                    :placeholder="
                                        __('forms.medication.lastIntakte')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                                <formulate-input
                                    name="startDate"
                                    :label="__('forms.medication.startDate')"
                                    :placeholder="
                                        __('forms.medication.startDate')
                                    "
                                    min="0"
                                    type="datetime-local"
                                />
                            </div>
                            <div>
                                <formulate-input
                                    name="activeSubstance"
                                    :label="
                                        __('forms.medication.activeSubstance')
                                    "
                                    :placeholder="
                                        __('forms.medication.activeSubstance')
                                    "
                                />
                                <div
                                    class="mt-4 flex text-secondary-700 text-center"
                                >
                                    <div class="px-2">
                                        <label
                                            for="intakeMorning"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunrise']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMorning"
                                            type="number"
                                            :help="
                                                __('forms.medication.morning')
                                            "
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeMidday"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sun']"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeMidday"
                                            :help="
                                                __('forms.medication.midday')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="mx-2">
                                        <label
                                            for="intakeEvening"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'sunset']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeEvening"
                                            :help="
                                                __('forms.medication.evening')
                                            "
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                    <div class="px-2">
                                        <label
                                            for="intakeNight"
                                            class="block mb-2"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'moon']"
                                                class="mr-2"
                                            />
                                        </label>
                                        <formulate-input
                                            name="intakeNight"
                                            :help="__('forms.medication.night')"
                                            type="number"
                                            min="0"
                                            value="0"
                                            class="centered"
                                        />
                                    </div>
                                </div>
                                <div class="py-6">
                                    <font-awesome-icon
                                        class="text-gray-600"
                                        :icon="['fa', 'chevron-square-right']"
                                    />
                                    {{
                                        totalDosageDay(
                                            groupProps.index,
                                            'steroids'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </template>
                </formulate-input>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'TrxSpecifics',
    data() {
        return {
            form: {},
            validation: {},
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },

        // Sets the active substance based on the selected search entry
        addActiveSubstanceToAntibioticField(entry, index) {
            // Sets the value on the medication-item

            this.form.antibiotic[index] = {
                ...this.form.antibiotic[index],
                activeSubstance: entry.activeSubstance,
            }
        },
        // Sets the active substance based on the selected search entry
        addActiveSubstanceToSteroidField(entry, index) {
            // Sets the value on the medication-item
            this.form.steroids[index] = {
                ...this.form.steroids[index],
                activeSubstance: entry.activeSubstance,
            }
        },

        totalDosageDay(index, type) {
            if (!this.form[type] || index === undefined) {
                return
            }

            const intakes = [
                'intakeMorning',
                'intakeMidday',
                'intakeEvening',
                'intakeNight',
            ]

            const block = this.form[type][index]

            const dosage = parseFloat(block.dosagePerIntake)

            const { total, intakesAvailable } = intakes.reduce(
                (accu, currentval) => {
                    if (block[currentval] !== '0') {
                        const int = parseInt(block[currentval])

                        accu.total += int
                        accu.intakesAvailable += 1
                    }
                    return accu
                },
                { total: 0, intakesAvailable: 0 }
            )

            if (intakesAvailable !== 0 && !isNaN(dosage)) {
                return this.__('forms.medication.totalDosagePerDay', [
                    _.round(total * dosage, 3),
                ])
            }
            return this.__('forms.medication.totalDosagePerDay', ['--'])
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
