<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="flex">
                <div class="flex flex-col flex-1 pr-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.leftColumn"
                    />
                    <headline tag="h3">{{ __('forms.meal.last') }}</headline>
                    <div class="flex flex-wrap justify-start">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.leftColumnBottom.size"
                        />
                    </div>
                    <div class="flex flex-wrap justify-start">
                        <formulate-schema
                            v-model="form"
                            :schema="schema.leftColumnBottom.type"
                        />
                    </div>
                </div>
                <div class="flex flex-col flex-1 pl-8">
                    <formulate-schema
                        v-model="form"
                        :schema="schema.rightColumn"
                    />
                </div>
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import formObjectWatch from 'helpers/watchers/formObjectWatch'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'EatingDrinkingShared',
    data() {
        return {
            form: {},
            validation: {},
            schema: {
                leftColumn: [
                    {
                        name: 'drink.alcohol',
                        label: this.__('forms.drinks.alcohol'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                    },
                    {
                        name: 'drink.coffee',
                        label: this.__('forms.drinks.coffee'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                    },
                ],
                leftColumnBottom: {
                    size: [
                        {
                            name: 'lastMeal.small',
                            label: this.__('forms.meal.small'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'lastMeal.medium',
                            label: this.__('forms.meal.medium'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'lastMeal.big',
                            label: this.__('forms.meal.big'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                    type: [
                        {
                            name: 'lastMeal.fatty',
                            label: this.__('forms.meal.fatty'),
                            type: 'checkbox',
                            class: 'flex-grow-2 mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'lastMeal.carbohydrateBased',
                            label: this.__('forms.meal.carbohydrateBased'),
                            type: 'checkbox',
                            class: ' mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'lastMeal.proteinBased',
                            label: this.__('forms.meal.proteinBased'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                },

                rightColumn: [
                    {
                        name: 'drink.caffeinatedDrink',
                        label: this.__('forms.drinks.caffeinatedDrink'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                    },
                    {
                        name: 'timeLast.coffee',
                        label: this.__('forms.timeOf.lastCoffee'),
                        type: 'datetime-local',
                    },
                    {
                        name: 'timeLast.meal',
                        label: this.__('forms.timeOf.lastMeal'),
                        type: 'datetime-local',
                    },
                ],
            },
        }
    },
    computed: {
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
        }),
    },
    watch: {
        'forms.visit1': {
            handler: formObjectWatch,
            immediate: true,
        },
    },
    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            updateForm: 'patient/updateForm',
            showToast: 'ui/showToast',
        }),
    },
}
</script>
