<template>
    <default-app-template>
        <div
            class="md:mx-auto md:w-3/4 w-full flex flex-col"
            data-cypress="support"
        >
            <div class="flex justify-center">
                <headline tag="h1">{{ __('support.headline') }}</headline>
            </div>
            <div class="text-center my-8 max-w-2xl mx-auto">
                {{ __('support.description') }}
            </div>
            <p class="text-center mb-10">
                <a
                    class="text-2xl no-underline font-bold text-primary-600"
                    :href="
                        `mailto:support@dbi.ch?subject=Support%20Request&body=App-Version:${version}`
                    "
                    target="_blank"
                    >support@dbi.ch</a
                >
            </p>

            <div class="my-2 text-center">
                <p
                    class="bg-secondary-100 text-secondary-700 text-sm inline-block p-2 rounded-full "
                >
                    {{ __('support.appVersion') }}:&nbsp;{{ version }}
                </p>
            </div>
        </div>
    </default-app-template>
</template>

<script>
/**
 * @group MOLECULES
 * A support page that ourputs the current app version from package.json
 */
export default {
    computed: {
        version() {
            return process.env.VUE_APP_VERSION
        },
    },
}
</script>
