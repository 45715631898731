<template>
    <font-awesome-icon
        class="text-gray-600 mr-2"
        :icon="['fas', 'info-circle']"
    />
</template>

<script>
export default {}
</script>
