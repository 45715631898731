/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| System operators
*/
import { db } from 'helpers/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'

const operatorsDocument = db.collection('system').doc('operators')

const mutations = {
    SET_OPERATORS: 'SET_OPERATORS',
}

const operators = {
    namespaced: true,
    state: {
        operators: {},
    },
    mutations: {
        [mutations.SET_OPERATORS](state, list) {
            state.operators = list
        },
    },
    actions: {
        getSystemOperators({ commit }) {
            operatorsDocument
                .get()
                .then(result => {
                    if (result.exists) {
                        const { list } = result.data()
                        commit(mutations.SET_OPERATORS, list)
                    }
                })
                .catch(() => {})
        },
    },
    getters: {
        operatorsList: ({ operators }) => operators,
    },
}

listAllActionsOfStore(operators, 'operators')
listAllGettersOfStore(operators, 'operators')

export default operators
