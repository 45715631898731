<template>
    <div>
        <formulate-form
            v-model="form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <div class="grid grid-cols-2 gap-x-12 md:gap-x-24">
                <formulate-schema v-model="form" :schema="schema.top" />
            </div>
            <div class="grid grid-cols-2 gap-x-12 md:gap-x-24">
                <div class="flex w-full flex-grow flex-col">
                    <headline tag="h4">{{
                        __('forms.breathAnalysis.didYouPastHour')
                    }}</headline>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.selectables.left"
                    />
                </div>
                <div class="flex w-full flex-grow flex-col">
                    <headline tag="h4">{{
                        __('forms.breathAnalysis.didYou')
                    }}</headline>
                    <formulate-schema
                        v-model="form"
                        :schema="schema.selectables.right"
                    />
                </div>
            </div>
            <div class="flex flex-1 flex-wrap">
                <formulate-schema v-model="form" :schema="schema.middle" />
            </div>
            <div class="flex flex-1 flex-wrap">
                <headline tag="h4" class-name="w-full">{{
                    __('forms.breathAnalysis.posMode')
                }}</headline>
                <formulate-schema
                    v-model="form"
                    :schema="schema.positiveMode"
                />
            </div>
            <div class="flex flex-1 flex-wrap" class-name="w-full">
                <headline tag="h4" class-name="w-full">{{
                    __('forms.breathAnalysis.negMode')
                }}</headline>
                <formulate-schema
                    v-model="form"
                    :schema="schema.negativeMode"
                />
            </div>
            <div class="mt-8 flex justify-end">
                <formulate-input
                    type="submit"
                    :label="__('save')"
                    data-cypress="form.next"
                    :disabled="validation.hasErrors"
                />
            </div>
        </formulate-form>
    </div>
</template>

<script>
import visitData from 'helpers/mixins/visitDataMixin'
import submitForm from 'helpers/forms/submitForm'

export default {
    name: 'BreathAnalysisBex',
    mixins: [visitData],
    computed: {
        schema() {
            const schema = {
                top: [
                    {
                        name: 'breathAnalysis.date',
                        label: this.__('forms.breathAnalysis.date'),
                        type: 'datetime-local',
                    },
                    {
                        name: 'breathAnalysis.operatorInCharge',
                        label: this.__('forms.breathAnalysis.operatorInCharge'),
                        type: 'select',
                        options: {
                            '': '',
                            ...this.operatorsList,
                        },
                    },
                ],
                selectables: {
                    left: [
                        {
                            name: 'breathAnalysis.consumeAlcohol',
                            label: this.__(
                                'forms.breathAnalysis.consumeAlcohol'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.hadDrinkNoWater',
                            label: this.__(
                                'forms.breathAnalysis.hadDrinkNoWater'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.smokeConsumeTobacco',
                            label: this.__(
                                'forms.breathAnalysis.smokeConsumeTobacco'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.chewGumCandy',
                            label: this.__('forms.breathAnalysis.chewGumCandy'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.brushTeeth',
                            label: this.__('forms.breathAnalysis.brushTeeth'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                    right: [
                        {
                            name: 'breathAnalysis.consumeCaffeinatedDrink',
                            label: this.__(
                                'forms.breathAnalysis.consumeCaffeinatedDrink'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.useInhalants',
                            label: this.__('forms.breathAnalysis.useInhalants'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.acuteCold6Weeks',
                            label: this.__(
                                'forms.breathAnalysis.acuteCold6Weeks'
                            ),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                        {
                            name: 'breathAnalysis.cosmeticFace',
                            label: this.__('forms.breathAnalysis.cosmeticFace'),
                            type: 'checkbox',
                            class: 'mr-8 mt-4',
                            checked: false,
                        },
                    ],
                },
                middle: [
                    {
                        name: 'breathAnalysis.explanation',
                        label: this.__('forms.breathAnalysis.explanation'),
                        type: 'text',
                        class: 'w-full',
                    },
                    {
                        name: 'breathAnalysis.mouthRinsed',
                        label: this.__('forms.breathAnalysis.mouthRinsed'),
                        type: 'select',
                        options: {
                            '': '',
                            yes: this.__('yes'),
                            no: this.__('no'),
                        },
                        class: 'common-classes--left',
                    },
                    {
                        name: 'breathAnalysis.capilliarySupplier',
                        label: this.__(
                            'forms.breathAnalysis.capilliarySupplier'
                        ),
                        placeholder: this.__(
                            'forms.breathAnalysis.capilliarySupplier'
                        ),
                        type: 'text',
                        class: 'common-classes--right',
                    },
                ],
                positiveMode: [
                    {
                        name: 'breathAnalysis.positiveMode.capPosition',
                        label: this.__('forms.breathAnalysis.capPosition'),
                        type: 'text',
                        class: 'common-classes--left',
                    },
                    {
                        name: 'breathAnalysis.positiveMode.sprayBaseline',
                        label: this.__('forms.breathAnalysis.sprayBaseline'),
                        type: 'text',
                        class: 'common-classes--right',
                    },
                    {
                        name: 'breathAnalysis.positiveMode.sprayMeasurement',
                        label: this.__('forms.breathAnalysis.sprayMeasurement'),
                        type: 'text',
                        class: 'common-classes--left',
                    },
                    {
                        name: 'breathAnalysis.positiveMode.exhalationQuality',
                        label: this.__(
                            'forms.breathAnalysis.exhalationQuality'
                        ),
                        type: 'select',
                        class: 'common-classes--right',
                        options: {
                            '': '',
                            good: this.__('good'),
                            ok: this.__('ok'),
                            bad: this.__('bad'),
                        },
                    },
                    {
                        name:
                            'breathAnalysis.positiveMode.exhalationDescription',
                        label: this.__(
                            'forms.breathAnalysis.exhalationDescription'
                        ),
                        type: 'text',
                        class: 'w-full',
                    },
                ],
                negativeMode: [
                    {
                        name: 'breathAnalysis.negativeMode.capPosition',
                        label: this.__('forms.breathAnalysis.capPosition'),
                        type: 'text',
                        class: 'common-classes--left',
                    },
                    {
                        name: 'breathAnalysis.negativeMode.sprayBaseline',
                        label: this.__('forms.breathAnalysis.sprayBaseline'),
                        type: 'text',
                        class: 'common-classes--right',
                    },
                    {
                        name: 'breathAnalysis.negativeMode.sprayMeasurement',
                        label: this.__('forms.breathAnalysis.sprayMeasurement'),
                        type: 'text',
                        class: 'common-classes--left',
                    },
                    {
                        name: 'breathAnalysis.positiveMode.exhalationQuality',
                        label: this.__(
                            'forms.breathAnalysis.exhalationQuality'
                        ),
                        type: 'select',
                        class: 'common-classes--right',
                        options: {
                            '': '',
                            good: this.__('good'),
                            ok: this.__('ok'),
                            bad: this.__('bad'),
                        },
                    },
                    {
                        name:
                            'breathAnalysis.negativeMode.exhalationDescription',
                        label: this.__(
                            'forms.breathAnalysis.exhalationDescription'
                        ),
                        type: 'text',
                        class: 'w-full',
                    },
                ],
            }
            return schema
        },
        visitDocId() {
            let { visitNumber } = this.$route.params
            let docId = (visitNumber && `visit${visitNumber}`) || 'visit'
            return this.forms && this.forms[docId]
        },
        ...vuex.mapGetters({
            forms: 'patient/patientForms',
            operatorsList: 'operators/operatorsList',
        }),
    },

    mounted() {
        this.getSystemOperators()
    },

    methods: {
        submit() {
            submitForm(this)
        },
        ...vuex.mapActions({
            getSystemOperators: 'operators/getSystemOperators',
        }),
    },
}
</script>

<style lang="scss">
.common-classes {
    &--left {
        @apply w-1/2 pr-12;
    }

    &--right {
        @apply w-1/2 pl-12;
    }
}
</style>
